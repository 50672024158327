import { Box, styled, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cropContainerHelper: {
    width: '100%'
  },
  tabContainerHelper: {
    paddingLeft: 16
  },
  tabHelper: {
    textTransform: 'none !important' as 'none'
  },
  panelBoxHelper: {
    display: 'flex',
    alignItems: 'center',
    width: '-webkit-fill-available',
    margin: '8px 0',
    padding: '0 1.5rem'
  },
  titleAndSearchHelper: {
    marginTop: '24px',
    display: 'flex',
    width: '-webkit-fill-available',
    padding: '0 1.5rem'
  },
  tableHeader: {
    fontWeight: '700 !important',
    backgroundColor: '#fafafa !important',
    fontSize: '14px !important'
  },
  alignTop: {
    verticalAlign: 'top !important'
  },
  linkStyles: {
    color: '#14803C !important',
    fontSize: '14px !important'
  },
  chipBoxHelper: {
    margin: '8px 0',
    display: 'inline-flex',
    width: '-webkit-fill-available',
    padding: '0 1.5rem'
  },
  viewImage: {
    background: '#f2f2f2',
    marginBottom: '1rem !important',
    marginTop: '1rem !important',
    width: '120px',
    height: '120px',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  formModal: {
    '&.MuiModal-root': {
      display: 'flex', alignItems: 'center', justifyContent: 'center'

    }
  },
  formPaper: {
    margin: '30px auto',
    width: '750px',
    height: '500px',
  },
  formPaperReadCount: {
    margin: '30px auto',
    width: '500px',
    height: '225px',
  },
  formContainer: {
    height: '370px',
    overflowY: 'auto',
    position: 'relative',
    scrollbarWidth: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CFD3DB',
      'border-radius': '15px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
  },
  formFieldsHelperOne: {
    maxWidth: '380px',
    margin: 'auto'
  },
  formFieldsHelper: {
    margin: 'auto',
    maxWidth: '430px'
  },
  testWrapControl: {
    wordBreak: 'break-all',
    '&.MuiTypography-root': {
      margin: '1rem 0'
    }
  },
  formSendSMSHelper: {
    display: 'flex',
    margin: '1.5rem 0 0',
    '& .MuiCheckbox-root': {
      padding: 0,
      height: 16,
      width: 16,
      marginLeft: 'auto',
    }
  },
  customTabBtmBorder: {
    borderBottom: '1px solid #CFD3DB', padding: '0 1.5rem'
  }

});

export const OuterBox = styled(Box)(
  {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
);
export const InnerBox = styled(Box)(
  {
    width: '350px',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
);

export const ErroMessage = styled(Typography)({
  fontWeight: 700,
  fontSize: 20,
  color: '#CF3537',
  lineHeight: '28px',
  textAlign: 'center'
});
export const ErroMessageDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: 14,
  color: '#14151C',
  lineHeight: '22px',
  textAlign: 'center'
});