import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Button, Divider, Stack, TableContainer } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../Api/end_points";
import { FilterType } from "../../Cards/PushNotification";
import CustomChip from "../../Common/CustomChip";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import FilterAndSort from "../../Common/FilterAndSort";
import Loader from "../../Common/Loader";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import States, { TYPE_NO_PUSH, TYPE_PUSH_APPROVED } from "../../Common/States";
import { IRequestBody } from "../../Constants/interfaces";
import { RootState } from "../../redux/reducers";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import CreateSuccessPage, {
  NotificationCreationFailed,
} from "../CreationSuccessFailed/index";
import { DeletePublish } from "../Publish/PublishApiCalls/PublishApiCalls";
import { FilterPropertyType } from "../../Common/FilterAndSort/type";
import AddEditNotification, { NotificationType } from "./AddEditNotification";
import {
  CancelNotification,
  DownloadUserSentDataByID,
  frequencyData,
  getNotifications,
  getPublishedData,
  getSentData,
  readCountByID,
  statusList,
} from "./AddEditNotification/PushNotificationAPICalls";
import {
  PublishedPushNotificationColumn,
  pushNotificationsColumn,
  SentPushNotificationTableColumn,
} from "./columnsData";
import { useStyles } from "./style";
import ViewNotification from "./ViewNotification";
import { ActionType } from "../../Common/Constants";
import ReadCount from "./ViewNotification/readCount";

export type PushNotificationsType = {
  data: Array<any>;
  filters: FilterType;
  isLoading: boolean;
  onChangeFilters: Function;
  onChangeRowsPerPage: Function;
  onCreatePush: Function;
  onDeletePush: Function;
  onDeselectAll: Function;
  onPageChange: Function;
  onRefresh: Function;
  onSeach: Function;
  onSelectAll: Function;
  onSelectPush: Function;
  onSort: Function;
  showActionProgress: boolean;
  showCreateProgress: boolean;
  stateType?: typeof TYPE_NO_PUSH | typeof TYPE_PUSH_APPROVED;
  title: string;
};

type NotificationObjProps = {
  id: number;
  language: string;
  message: string;
  scheduleDate: any;
  state: string;
  status: any;
  type: string;
};

const PushNotifications: React.FC<PushNotificationsType> = (props) => {
  /**default data for filter */
  let defaultFilterData: FilterPropertyType = {
    languageIds: [],
    status: [],
    notificationTypes: [],
    moduleNames: [],
    frequencyIds: [],
    crop: [],
    category: [],
  };
  // classes
  const classes = useStyles();

  // states
  const [activeTab, setActiveTab] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [action, setAction] = useState<string>("");
  const [showCreatePushNotification, setShowCreatePushNotification] =
    useState<boolean>(false);
  const [showViewNotification, setShowViewNotification] =
    useState<boolean>(false);
  const [sortId, setSortId] = useState();
  const [secondarySortId, setSecondarySortId] = useState();
  const [notificationInView, setNotificationInView] =
    useState<NotificationType>({
      notificationTypeName: "",
      languageId: undefined,
      stateId: undefined,
      selectedDistrictObj: [],
      distIds: null,
      startDate: null,
      endDate: null,
      frequency: "",
      message: "",
      sendAsSMS: false,
      dayOfWeek: "",
      dayOfMonth: "",
      filteredCrops: [],
      stateNames: [],
      time: null,
      smsCost: undefined,
      title: "",
      hyperlink: "",
      id: undefined,
    });
  const [isSuccessPage, setIsSuccessPage] = useState<boolean>(false);
  const [isListPage, setIsListPage] = useState<boolean>(true);
  const [notificationData, setNotificationData] = React.useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
  const [readCountData, setReadCountData] = useState<any[]>([]);
  const [showReadCountModal, setShowReadCountModal] = useState<boolean>(false);
  const [filterData, setFilterData] =
    useState<FilterPropertyType>(defaultFilterData);
  const [isClearAll, setIsClearAll] = useState<boolean>(false);

  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      notificationTypes: state.generic?.ListOfNotification,
    };
  });
  /**uing inputRef to create a clickable anchor link
   * for download button in sent tab
   */
  /** using this variable to get the latest state variable data: searchValue */
  // handlers

  const handleCloseCreatePushNotification = (
    isCreatSuccess?: boolean,
    isEdit?: boolean
  ) => {
    setIsSuccessPage(isCreatSuccess ?? false);
    setShowCreatePushNotification(false);
    setIsListPage(isCreatSuccess === undefined);
    if (isEdit !== undefined) {
      setIsEdit(isEdit);
    }
  };

  const displayListPage = () => {
    setIsSuccessPage(false);
    setIsListPage(true);
    if (activeTab === 0) {
      fetchDataList();
    }
  };
  const handleCloseViewNotification = () => {
    setShowViewNotification(false);
  };
  /** Search handlers */
  const handleSearchValueChange = useCallback(
    (newSearchString: string) => {
      /** Search will work on these fields
       *   notificationTitle,message,dayOfWeek,stateNames,cropNames,districtNames,languageName,frequency
       */
      // console.log('Search Value Change', newSearchString)
      setSearchValue(newSearchString);
    },
    [searchValue]
  );
  const handleSearch = useCallback(() => {
    resetListPage();
  }, [searchValue]);

  const handleActionClick = (
    clickAction: string,
    details?: NotificationType
  ) => {
    switch (clickAction) {
      case "Create_Push":
        setAction(clickAction);
        return setShowCreatePushNotification(true);
      case "View_Push":
        setShowViewNotification(true);
        if (details) {
          setNotificationInView(details);
        }
        break;
      case "Edit_Push":
        setAction(clickAction);
        if (details) {
          setNotificationInView(details);
        }
        return setShowCreatePushNotification(true);
      case "Delete":
        if (activeTab === 0) {
          setIsActionInProgress(true);
          deleteCreatedNotification();
        } else if (activeTab === 1) {
          setIsActionInProgress(true);
          cancelPublishedNotification();
        }
        // return props.onDeletePush(true);
        break;
      case "Read_Count":
        if (details && details.id) {
          fetchReadCountData(details.id)
        }
        break;
      case "Download": {
        if (details && details.id) {
          handleDownloadData(details.id);
        }
        break;
      }
      case "Back":
        return props.onRefresh();
      default:
        return;
    }
  };

  const checkOrUncheckAllRows = (
    data: Array<NotificationObjProps>,
    status: boolean
  ) => {
    const newArr = _.map(data, (o) => _.extend({ ...o }, { checked: status }));
    setNotificationData([...newArr]);
  };

  /**
   * @description to check the all rows or perticular row
   *
   */
  const selectAllRecords = (status: boolean) => {
    checkOrUncheckAllRows(notificationData, status);
  };


  const processDataListTab = (response: any) => {
    const listData: any = response.data;
    setTotalRecords(listData.totalRecords);
    const updatedNotificationData: any = listData.notificationConfigDtos.map((row: any) => ({
      id: row.id,
      notificationType: row.notificationType.notificationTypeName,
      languageName: row.languageName,
      Geography: `State : ${row.stateNames.split(",")[0]}${row.stateNames.split(",").length > 1 ? `.. +${row.stateNames.split(",").length} others` : ""}`,
      ScheduleDate: `${row.frequency}##${row.fromDate}##${row.toDate}`,
      message: row.message,
      status: row.statusId === 1 ? "Created" : "Not-Created"
    }));
    checkOrUncheckAllRows(updatedNotificationData, false);
  };

  const processPublishedTab = (response: any) => {
    const offset = moment().utcOffset();
    const listData: any = response.data;
    setTotalRecords(listData.totalRecords);
    const updatedNotificationData: any = listData.notificationConfigDtos.map((row: any) => {
      let statusId = "--";
      if (row.statusId === 2) {
        statusId = "Published";
      } else if (row.statusId === 3) {
        statusId = "Cancelled";
      }
      return {
        id: row.id,
        notificationTitle: row.notificationTitle,
        modifiedDate: moment(row.modifiedDate).add(offset, "minutes").format("YYYY-MM-DD hh:mm a"),
        statusId: statusId,
        frequency: row.frequency
      };
    });
    checkOrUncheckAllRows(updatedNotificationData, false);
  };

  const processSentTab = (response: any) => {
    const listData: any = response.data;
    setTotalRecords(listData.totalRecords);
    const updatedNotificationData: any = listData.notificationHistoryDtos.map((row: any) => ({
      id: row.historyId,
      title: row.title,
      triggeredDate: row.triggeredDate,
      receiverCount: row.receiverCount,
      readCount: row.readCount,
      frequency: row.frequencyId
    }));
    checkOrUncheckAllRows(updatedNotificationData, false);
  };

  const processDownloadUsers = (response: any) => {
    if (response.statusCode === 200) {
      const columnsData = [
        { label: "Name", value: "name" },
        { label: "Mobile Number", value: "mobileNumber" },
        { label: "Notification Content", value: "notificationContent" },
        { label: "Published Date", value: "publishedDate" },
        { label: "Published Time", value: "publishedTime" },
        { label: "Read Status", value: "readStatus" },
      ];
      const dataObj = [
        {
          columns: columnsData,
          content: response.data.content
        }
      ];
      const settings = {
        fileName: response.data.fileName,
        extraLength: 3,
        writeOptions: {}
      };
      ConvertJSONToXLS(dataObj, settings);
    }
  };

  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "data_list_tab":
        processDataListTab(response);
        break;
      case "published_tab":
        processPublishedTab(response);
        break;
      case "sent_tab":
        processSentTab(response);
        break;
      case "delete_notification":
      case "cancel_notification":
        setIsActionInProgress(false);
        if (response.statusCode === 200) {
          type === "delete_notification" ? fetchDataList() : fetchPublishedList();
        }
        break;
      case "download_users":
        processDownloadUsers(response);
        break;
      default:
        break;
    }
  };

  /**
   * @description A callback function triggered when an API call fails
   */
  const errorCallback = (error: any, type: string) => {
    setIsActionInProgress(false);
    switch (type) {
      case "delete_notification":
        TriggerToastMessage("Could not delete", "error");
        break;
      case "cancel_notification":
        TriggerToastMessage("Could not cancel", "error");
        break;
      case "download_users":
        TriggerToastMessage(error.message, "error");
        break;
      default:
        TriggerToastMessage("Unable to process your request", "error");
        break;
    }
  };

  /**generating payload as per filter selection */
  const handleDataList = (filterData: any, payload: any) => {
    if (filterData.languageIds?.length) {
      let ids: any = [];
      filterData.languageIds.map((langData: string) => {
        ids.push(Number(langData.split(":::")[1]));
      });
      payload.languageIds = ids;
    } else {
      payload.languageIds = null;
    }

    if (filterData.notificationTypes?.length) {
      payload.notificationTypeIds = filterData.notificationTypes.map(
        (item: any) => Number(item)
      );
    } else {
      payload.notificationTypeIds = null;
    }
    return payload;
  };

  const handlePublished = (filterData: any, payload: any) => {
    if (filterData.frequencyIds?.length) {
      payload.frequencies = filterData.frequencyIds;
    } else {
      payload.frequencies = null;
    }

    if (filterData.status?.length) {
      payload.statusIds = filterData.status.map((item: any) => Number(item));
    } else {
      payload.statusIds = null;
    }
    return payload;
  };

  const handleSentTab = (filterData: any, payload: any) => {
    if (filterData.frequencyIds?.length) {
      payload.frequencies = filterData.frequencyIds;
    } else {
      payload.frequencies = null;
    }
    return payload;
  };

  const prepareTabPayload = (type: string) => {
    let payload: any = {};
    switch (type) {
      case "data_list":
        return handleDataList(filterData, payload);
      case "published":
        return handlePublished(filterData, payload);
      case "sent_tab":
        return handleSentTab(filterData, payload);
    }
  };
  /**
   * Fetching Data list tab data
   */
  const fetchDataList = useCallback((): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.get_notifications_list;
    /**applying sortid to the query params */
    endPoint += `?pageNo=${currentPage}&pageSize=${limit}`;
    if (sortId) {
      endPoint += `&sortId=${sortId}`;
    }
    /**search payload */
    if (searchValue) {
      endPoint += `&searchKey=${searchValue.toLocaleLowerCase()}`;
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    /** prepare selected filter payload */
    apiData.payLoad = prepareTabPayload("data_list");
    getNotifications(apiData, successCallBack, errorCallback);
  }, [limit, currentPage, sortId, searchValue, filterData]);

  /**
   * Fetching Published tab data
   */
  const fetchPublishedList = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.get_published_notifications_list;
    /**applying sortid to the query params */
    endPoint += `?pageNo=${currentPage}&pageSize=${limit}`;
    if (sortId) {
      endPoint += `&sortId=${sortId}`;
    }
    if (searchValue) {
      endPoint += `&searchKey=${searchValue.toLocaleLowerCase()}`;
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    /** prepare selected filter payload */
    apiData.payLoad = prepareTabPayload("published");
    getPublishedData(apiData, successCallBack, errorCallback);
  };
  /**
   * Fetching Sent tab data
   */
  const fetchSentTabList = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = `${api_endpoints.get_sent_notifications_list}?pageNo=${currentPage}&pageSize=${limit}`;
    /**using sortId as sentOnsortId here, and secondarySortId as receiversortId*/
    if (sortId) {
      endPoint += `&sentOnsortId=${sortId}`;
    }
    if (secondarySortId) {
      endPoint += `&receiversortId=${secondarySortId}`;
    }
    /**search payload */
    if (searchValue) {
      endPoint += `&searchKey=${searchValue.toLocaleLowerCase()}`;
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    /** prepare selected filter payload */
    apiData.payLoad = prepareTabPayload("sent_tab");
    getSentData(apiData, successCallBack, errorCallback);
  };
  /**
   * @description this is delete notifiction handler
   */
  const deleteCreatedNotification = () => {
    let ids: Array<number> = notificationData
      .filter((row: any) => row.checked === true)
      .map((ids: any) => ids.id);
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.delete_publish;
    apiData.showLoader = true;
    apiData.payLoad = {
      listOfIds: ids,
    };
    DeletePublish(apiData, successCallBack, errorCallback);
  };
  /**
   * @description this is delete notifiction handler
   */
  const cancelPublishedNotification = () => {
    let ids: Array<number> = notificationData
      .filter((row: any) => row.checked === true)
      .map((ids: any) => ids.id);
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.cancel_published;
    apiData.showLoader = true;
    apiData.payLoad = {
      listOfIds: ids,
    };
    CancelNotification(apiData, successCallBack, errorCallback);
  };
  /**Download users data API call */
  const handleDownloadData = (id: number): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.download_sent_users}${id}`;
    apiData.showLoader = true;
    apiData.customHeaders = {
      "Content-Disposition": "attachment",
    };
    apiData.payLoad = { type: "json" };
    DownloadUserSentDataByID(apiData, successCallBack, errorCallback);
  };

  const fetchReadCountData = (historyId: number): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.get_read_count}${historyId}`;
    apiData.showLoader = true;

    readCountByID(apiData, (response: any) => {
      setReadCountData(response.data);
      setShowReadCountModal(true);
    }, errorCallback);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const getNotificationColumns = () => {
    switch (activeTab) {
      case 0:
        return pushNotificationsColumn;
      case 1:
        return PublishedPushNotificationColumn;
      case 2:
        return SentPushNotificationTableColumn;
      default:
        return pushNotificationsColumn;
    }
  };

  const checkOrUncheckRow = (data: NotificationObjProps, status: boolean) => {
    const filterData: any = notificationData.map((isdata: any) => {
      if (isdata.id === data.id) {
        isdata.checked = status;
        return isdata;
      }

      return isdata;
    });
    setNotificationData([...filterData]);
  };

  const selectEachByRow = (check: boolean, notification: any) => {
    checkOrUncheckRow(notification, check);
  };

  /**
   * @description A function to trigger an api call to fetch notifications
   */
  const onChangeOfRowsPerPage = (value: number) => {
    setCurrentPage(0);
    setLimit(value);
  };

  /**
   * @description A function which will reset current page
   * if currentPage === 0, it will trigger a method to load notifications
   */

  /**
   * @description Delete button wehenever checkbox will be selected
   */
  let showDelete = notificationData.filter(
    (rows: any) => rows.checked === true
  ).length;

  /**common code for: list page APIs */
  const resetListPage = () => {
    switch (activeTab) {
      case 0: {
        fetchDataList();
        break;
      }
      case 1: {
        fetchPublishedList();
        break;
      }
      case 2: {
        fetchSentTabList();
        break;
      }
    }
  };
  /**clear all filter */
  const clearAllFilter = () => {
    setFilterData(defaultFilterData);
  };
  /** clear filter one by one by chips */
  const handleChipDelete = (id: number, type: string) => {
    switch (type) {
      case "languageIds": {
        let data = filterData.languageIds!;
        let indexToDelete = data.indexOf(`${id}`);
        data.splice(indexToDelete, 1);
        setFilterData((prevState) => ({
          ...prevState,
          languageIds: data,
        }));
        break;
      }
      case "notificationTypes": {
        let data = filterData.notificationTypes!;
        let indexToDelete = data.indexOf(`${id}`);
        data.splice(indexToDelete, 1);
        setFilterData((prevState) => ({
          ...prevState,
          notificationTypes: data,
        }));
        break;
      }
      case "frequencyIds": {
        let data = filterData.frequencyIds!;
        let indexToDelete = data.indexOf(`${id}`);
        data.splice(indexToDelete, 1);
        setFilterData((prevState) => ({
          ...prevState,
          frequencyIds: data,
        }));
        break;
      }
      case "status": {
        let data = filterData.status!;
        let indexToDelete = data.indexOf(`${id}`);
        data.splice(indexToDelete, 1);
        setFilterData((prevState) => ({
          ...prevState,
          status: data,
        }));
        break;
      }
    }
  };

  useEffect(() => {
    setNotificationData([]);
    resetListPage();
  }, [limit, currentPage]);

  useEffect(() => {
    setFilterData(props.filters);
  }, [props.filters]);

  /**useEffect to handle API call on tab change */
  useEffect(() => {
    /**common state reset on tab change*/
    setCurrentPage(0);
    setTotalRecords(0);
    setSearchValue(undefined);
    setFilterData(defaultFilterData);
    setSortId(undefined);
    setSecondarySortId(undefined);
    switch (activeTab) {
      case 0: {
        setNotificationData([]);
        fetchDataList();
        break;
      }
      case 1: {
        setNotificationData([]);
        fetchPublishedList();
        break;
      }
      case 2: {
        setNotificationData([]);
        fetchSentTabList();
        break;
      }
    }
  }, [activeTab]);

  /**this useEffect to get total number of pages
   * after every change in limit and total pages
   */
  useEffect(() => {
    let pageCount: number = Math.ceil(totalRecords / limit);
    setPageCount(pageCount);
  }, [limit, totalRecords]);

  /**for search */
  useEffect(() => {
    /** Search will work on these fields
     *   notificationTitle,message,dayOfWeek,stateNames,cropNames,districtNames,languageName,frequency
     */

    if (!searchValue) {
      resetListPage();
    }
  }, [searchValue]);

  /** filter handler useEffect */
  useEffect(() => {
    /**Display and hide clear all chip */
    if (
      filterData.frequencyIds?.length ||
      filterData.languageIds?.length ||
      filterData.moduleNames?.length ||
      filterData.notificationTypes?.length ||
      filterData.status?.length
    ) {
      setIsClearAll(true);
    } else {
      setIsClearAll(false);
    }
    resetListPage();
  }, [filterData]);

  /** sort change useEffect */
  useEffect(() => {
    resetListPage();
  }, [sortId, secondarySortId]);

  let placeholderText;
  switch (activeTab) {
    case 0:
      placeholderText = "Language, Geography, Message";
      break;
    case 1:
      placeholderText = "Notification Title, Frequency";
      break;
    case 2:
      placeholderText = "Notification Title, Receiver Count";
      break;
    default:
      placeholderText = null;
  }
  return (
    <TableContainer>
      {props.isLoading && <Loader />}
      {props.stateType && (
        <States type={props.stateType} onActionClick={handleActionClick} />
      )}
      {isListPage &&
        !isSuccessPage &&
        !props.isLoading &&
        props.data?.length >= 0 &&
        !props.stateType && (
          <div className={classes.cropContainerHelper}>
            <div className={classes.titleAndSearchHelper}>
              <PageTitle title={props.title} />
              <SearchBar
                placeholderText={`Search by ${placeholderText}`}
                searchValue={searchValue ?? ""}
                onSearchValueChange={handleSearchValueChange}
                durationDebounce={100}
                onEnterClick={handleSearch}
              />
            </div>
            <Box className={classes.customTabBtmBorder}>
              <Stack>
                <CustomTabs
                  tabsList={["Data list", "Published", "Sent"]}
                  value={activeTab}
                  onChange={handleTabChange}
                />
              </Stack>
            </Box>
            <Box className={classes.panelBoxHelper}>
              <RowsPerPage
                pagSize={limit}
                onChangeRowsPerPage={(value: any) => {
                  onChangeOfRowsPerPage(value);
                }}
              />
              <FilterAndSort
                filters={filterData}
                sortBy={sortId}
                secondarySortBy={secondarySortId}
                onChangeFilters={(properties: any) => setFilterData(properties)}
                onSort={(sortType: any) => setSortId(sortType?.toString())}
                onSecondarySort={(sortType: any) =>
                  setSecondarySortId(sortType?.toString())
                }
                showLanguageSelection={activeTab === 0}
                showNotificationType={activeTab === 0}
                showFrequencyList={activeTab === 1 || activeTab === 2}
                showStatusList={activeTab === 1}
                showPublishOnSort={activeTab === 1}
                showSentOnSort={activeTab === 2}
                showSecondarySort={activeTab === 2}
                statusList={statusList}
                showSheduledDateSort={activeTab === 0}
                isResetFilter={`${activeTab}`}
              />
            </Box>
            <Stack
              className={classes.chipBoxHelper}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" spacing={1}>
                {/* displaying selected filtered in chips format */}
                {_.map(filterData?.languageIds, (languageID: string) => {
                  let id = Number(languageID.split(":::")[1]);
                  return (
                    <CustomChip
                      label={
                        _.find(
                          storeData.languages,
                          (item: any) => item.languageId === id
                        )?.languageName
                      }
                      id={id}
                      type={"languageIds"}
                      handleChipDelete={handleChipDelete}
                    />
                  );
                })}
                {_.map(
                  filterData?.notificationTypes,
                  (notificationId: string) => {
                    return (
                      <CustomChip
                        label={
                          _.find(
                            storeData.notificationTypes,
                            (item: any) => `${item.id}` === notificationId
                          )?.notificationTypeName
                        }
                        id={notificationId}
                        type={"notificationTypes"}
                        handleChipDelete={handleChipDelete}
                      />
                    );
                  }
                )}
                {_.map(filterData?.frequencyIds, (freqId: string) => {
                  return (
                    <CustomChip
                      label={
                        _.find(
                          frequencyData,
                          (item: any) => `${item.value}` === freqId
                        )?.displayName
                      }
                      id={freqId}
                      type={"frequencyIds"}
                      handleChipDelete={handleChipDelete}
                    />
                  );
                })}
                {_.map(filterData?.status, (statusId: string) => {
                  return (
                    <CustomChip
                      label={
                        _.find(
                          statusList,
                          (item: any) => `${item.value}` === statusId
                        )?.displayName
                      }
                      id={statusId}
                      type={"status"}
                      handleChipDelete={handleChipDelete}
                    />
                  );
                })}
              </Stack>
              {isClearAll && (
                <Stack>
                  <Button
                    variant="text"
                    color="error"
                    data-testid="clearAll"
                    endIcon={<CloseOutlinedIcon />}
                    onClick={() => clearAllFilter()}
                  >
                    <Divider
                      sx={{ mr: "1rem" }}
                      orientation="vertical"
                      flexItem
                    />
                    {"Clear all"}
                  </Button>
                </Stack>
              )}
            </Stack>

            <div>
              {/* Table view */}
              <DataTable
                enableSelection={activeTab !== 2}
                columns={getNotificationColumns()}
                data={notificationData}
                pageNumber={1}
                onPageChange={() => { }}
                onSelectAll={selectAllRecords}
                onDeselectAll={() => { }}
                onActionClick={handleActionClick}
                onRowSelect={selectEachByRow}
                isEditEnabled={activeTab === 0}
                //isStatusBadge={activeTab === 0 ? true : false}
                isViewButton={activeTab !== 2}
                isDownLoadButton={activeTab === 2}
              />
              {/* Bottom of list page (delete, pagination, plus icon) */}
              <DataTableActions
                actiontype2={ACTION_TYPE.TYPE_PUSH}
                buttonVisible={Boolean(showDelete)}
                pageNumber={currentPage + 1}
                onPageChange={(selectedPageNo: number) =>
                  setCurrentPage(selectedPageNo - 1)
                }
                onActionClick={handleActionClick}
                showActionProgress={isActionInProgress}
                totalpages={pageCount}
                canShowAvatar={activeTab === 0}
                activeTab={activeTab}
              />
            </div>
          </div>
        )}
      {showCreatePushNotification && (
        <AddEditNotification
          action={action}
          notificationDetails={notificationInView}
          showCreateProgress={props.showCreateProgress}
          onCreatePush={props.onCreatePush}
          onCloseCreatePushNotification={handleCloseCreatePushNotification}
        />
      )}
      {/* After create success page */}
      {isSuccessPage && !isListPage && (
        <CreateSuccessPage
          handleGoBack={displayListPage}
          message={
            !isEdit
              ? "Push notification created!"
              : "Push notification updated!"
          }
          messagedescription={
            "The notifications will be pushed to the mobile application on the scheduled date"
          }
        />
      )}
      {/* After create failure page */}
      {!isSuccessPage && !isListPage && (
        <NotificationCreationFailed
          handleGoBack={displayListPage}
          message={
            !isEdit
              ? "Push notification creation failed!"
              : "Push notification updation failed!"
          }
          messagedescription={
            "An error occurred while creating your push notification, please try again later."
          }
        />
      )}
      {showViewNotification && (
        <ViewNotification
          notificationDetails={notificationInView}
          onCloseViewNotification={handleCloseViewNotification}
        />
      )}
      {showReadCountModal && (
        <ReadCount
          open={showReadCountModal}
          onClose={() => setShowReadCountModal(false)}
          data={readCountData}
        />
      )}
    </TableContainer>
  );
};

export default PushNotifications;
