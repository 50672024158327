import { Box, Stack, TableContainer } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api_endpoints, { base_url } from "../../Api/end_points";
import {
  ActionType,
  DOWNLOAD_SAMPLE_TEMPLATE_TYPE,
  ErrorTableHeaders,
  PublishModuleDD,
} from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FiltersAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import AddNewContentModal from "../../Common/Popup";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import { SORT_OPTIONS } from "../../Constants/enums";
import { IRequestBody } from "../../Constants/interfaces";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { GetErrorList, GetMyUploadList } from "../Crop/CropsAPICalls";
import KnowMorePopup from "./../../Common/KnowMoreErrorModal/knowMoreDetails";
import { RetailerColumn, RetailerMyUploadsTableColumn } from "./columnsData";
import BulkUploadModal from "./Modal/BulkUploadModal";
import CreateNewRetailerModal from "./Modal/CreateNewRetailerModal";
import ViewRetailerModal from "./Modal/ViewRetailerModal";
import {
  GetRetailerDataList,
  getRetailersViewDraftInfo,
  retailerToggleStatusAPI,
} from "./RetailerApiCalls";
import { useStyles } from "./style";
import { TABS_DATA } from "./utils";
import { formatDate } from "../../Utility/DateFormat";

interface ModalProps {
  isCreateModal: boolean;
  isNewRetailerModale: boolean;
  isbulkUploadModal: boolean;
  isViewRetailerModal: boolean;
}

const RetailersAndNurseries = (props: any) => {
  const classes = useStyles();
  const INITIAL_RETAILERS_FILTER_STATE = {
    languageIds: [],
    status: [],
    crop: [],
    category: [],
    retailerType: [],
    retailerCategory: [],
  };
  const [searchedValue, setSearchedValue] = useState<string | undefined>("");
  const [activeTab, setActiveTab] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filterObj, setFilterObj] = useState({
    ...INITIAL_RETAILERS_FILTER_STATE,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [actionType, setActionType] = React.useState<string>("");
  //total pages
  const [totalPages, setTotalPages] = React.useState<number>(0);
  //sort
  const [sortId, setSortId] = React.useState("1");
  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<Array<any> | undefined>(undefined);
  const [myUploadData, setMyUploadData] = useState<any | undefined>(undefined);

  //modal state
  const [modalState, setModalState] = useState<ModalProps>({
    isCreateModal: false,
    isNewRetailerModale: false,
    isbulkUploadModal: false,
    isViewRetailerModal: false,
  });
  //selected row for view or edit
  const [selectedRetailerInfo, setSelectedRetailerInfo] = useState("");
  // retailerData
  const [retailerData, setRetailerData] = useState<any>([]);
  const [uploadInfo, setMyUploadInfo] = useState<any>([]);

  const successCallBack = (response: any, type: string, optionalData?: any) => {
    switch (type) {
      case "retailer_data_list": {
        const listData: any = response.data;
        setTotalPages(listData?.totalPages);
        const requiredData: any = listData?.stores?.map((item: any) => ({
          id: item.storeId,
          name: item.name,
          phonenumber: item.phonenumber,
          type: item.type,
          category: item.category?.join(","),
          shortaddress: item.shortaddress,
          createdOn: formatDate(item.lastupdateddate),
          isActive: item?.isactive, // for the switch button we adding the statuscolumn like this,
          statusId: item?.statusId,
        }));
        AssignCheckedFlag(requiredData, false);
        setRetailerData(requiredData);
        break;
      }
      case "retailer_toggle_Status": {
        fetchDataList();
        break;
      }
      case "crops_upload_list": {
        const listData: any = response.data;
        setTotalPages(Math.ceil(listData.totalElements / limit));
        const myUploadListToDisplay: any = listData.uploadDocumentList.map(
          (row: any) => ({
            id: row.id,
            fileName: row.fileName,
            cropsUploadDate: moment(row?.lastUpdatedDate)
              .add(moment().utcOffset(), "m")
              .format("YYYY/MM/DD hh:mm a"),
            comnMyUploadStatus: row.status,
          })
        );
        setMyUploadInfo(myUploadListToDisplay);
        break;
      }
      case "error_list": {
        if (response.statusCode === 200) {
          setErrorList(response.data);
        }
        break;
      }
    }
  };

  const errorCallback = (error: any, type: string) => {
    switch (type) {
      default: {
        TriggerToastMessage("Unable to process your request", "error");
      }
    }
  };

  const fetchDataList = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.stores;
    apiData.payLoad = {
      pageNo: currentPage,
      pageSize: limit,
    };
    if (searchedValue) {
      apiData.payLoad.term = searchedValue.toLocaleLowerCase();
    }
    if (sortId) {
      apiData.payLoad.sort =
        sortId === "1" ? SORT_OPTIONS.dsc : SORT_OPTIONS.asc;
    }
    if (filterObj.retailerCategory.length > 0) {
      apiData.payLoad.categories = filterObj.retailerCategory
        .map((categoryLabel: string) => {
          return categoryLabel;
        })
        .join(",");
    }

    if (filterObj.retailerType.length > 0) {
      apiData.payLoad.types = filterObj.retailerType
        .map((retailerType: string) => {
          return retailerType;
        })
        .join(",");
    }
    apiData.endPoint = endPoint;
    GetRetailerDataList(apiData, successCallBack, errorCallback);
  };

  const fetchMyUploads = () => {
    const apiData = {} as IRequestBody;
    let endPoint: string = api_endpoints.myupload_list;
    /**applying sortid to the query params */
    sortId
      ? (endPoint += `?sortId=${sortId}&pageNo=${currentPage}&pageSize=${limit}`)
      : (endPoint += `?pageNo=${currentPage}&pageSize=${limit}`);
    apiData.payLoad = {
      dataType: PublishModuleDD.RETAILERS_NURSUREY,
    };
    /**search payload */
    if (searchedValue) {
      apiData.payLoad = { ...apiData.payLoad, searchKey: searchedValue.trim() };
    }
    apiData.endPoint = endPoint;
    GetMyUploadList(apiData, successCallBack, errorCallback);
  };

  const fetchData = () => {
    switch (activeTab) {
      case 0: {
        fetchDataList();
        break;
      }
      case 1: {
        fetchMyUploads();
        break;
      }
    }
  };

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setSortId("1");
    setActiveTab(newValue);
  };

  const showViewRetailer = (rowInfo: any) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewRetailerModal: true,
    }));
    setSelectedRetailerInfo(rowInfo);
  };

  const editViewRetailer = (rowInfo: any, clickAction: string) => {
    setModalState((prevState) => ({
      ...prevState,
      isNewRetailerModale: true,
    }));
    setSelectedRetailerInfo(rowInfo);
    setActionType("Edit_Retailer");
  };

  const handleActionClick = (clickAction: string, details?: any) => {
    /** storing action type to send the information
     * to create/edit crop popup
     */
    setActionType(clickAction);
    switch (clickAction) {
      case ActionType.CREATE:
        setModalState((prevState) => ({
          ...prevState,
          isCreateModal: true,
        }));
        break;
      case ActionType.VIEW:
        setActionType("View_Retailer");
        showViewRetailer(details);
        break;
      case ActionType.UPDATE:
        editViewRetailer(details, clickAction);
        break;
      default:
        handleExportToExcel();
        return;
    }
  };

  //check or uncheck row
  const checkOrUncheckRow = (data: any, status: boolean) => {
    const filterData: any = retailerData.map((isdata: any) => {
      if (isdata.id === data.id) {
        isdata.checked = status;
      }
      return isdata;
    });
    setRetailerData(filterData);
  };

  const AssignCheckedFlag = (data: any, status: boolean) => {
    const filterData: any = data.map((isdata: any) => {
      isdata.checked = status;
      return isdata;
    });
    setRetailerData(filterData);
  };

  const selectEachByRow = (check: boolean, rowData: any) => {
    checkOrUncheckRow(rowData, check);
  };

  const selectAllRecords = (status: boolean) => {
    AssignCheckedFlag(retailerData, status);
  };

  const changeStatusOfRetailer = (checked: boolean, data: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.stores;
    apiData.payLoad = {
      active: checked,
      storeId: data.id,
    };
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    retailerToggleStatusAPI(apiData, successCallBack, errorCallback);
  };

  const renderKnowMoreModal = () => {
    return (
      <>
        <KnowMorePopup
          handleKnowMoreClose={(flag: boolean) => {
            setOpenKnowMore(flag);
            setMyUploadData(undefined);
          }}
          title={myUploadData && myUploadData.fileName}
          tableHeaders={ErrorTableHeaders}
          tableData={errorList}
          openBulkUploadPopup={() => {
            setModalState((prevState) => ({
              ...prevState,
              isbulkUploadModal: true,
            }));
            setOpenKnowMore(false);
          }}
        />
      </>
    );
  };

  const getErrorListData = (id: number) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.bulkupload_error_list;
    apiData.endPoint = endPoint.replace("__ID__", `${id}`);
    apiData.showLoader = true;
    /** payload */
    GetErrorList(apiData, successCallBack, errorCallback);
  };

  const handleKnowMorePopup = (flag: boolean, rowData: any) => {
    setOpenKnowMore(flag);
    /** API call to get Error list */
    getErrorListData(rowData.id);
    /** using this state variable to store file name and pass it
     * to the ErrorListPopup
     */
    setMyUploadData(rowData);
  };

  const getDetailData = async (data: any) => {
    const storeId: string | number = data.id;
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.retailer_view_info_draft.replace(
      "__storeId__",
      `${storeId.toString()}`
    );
    apiData.showLoader = true;

    return new Promise((resolve, reject) =>
      getRetailersViewDraftInfo(
        apiData,
        (response: any) => {
          resolve(response.data);
        },
        (error: any) => reject(new Error("Error", { cause: error }))
      )
    );
  };

  const handleExportToExcel = async () => {
    const selectedRetailerInfo = _.filter(retailerData, { checked: true });

    if (selectedRetailerInfo.some((item: any) => item.statusId === 5)) {
      const confirmationExport = confirm(
        "Exporting draft record for 'Publish Pending' item"
      );
      if (!confirmationExport) return;
    }

    if (selectedRetailerInfo.length === 0) {
      TriggerToastMessage("Please select atleast one line to export", "warn");
      return;
    }

    const allData = await Promise.all(selectedRetailerInfo.map(getDetailData));

    const columnsData = [
      {
        label: "name",
        value: "payload.name",
      },
      {
        label: "shortaddress",
        value: "payload.shortaddress",
      },
      {
        label: "fulladdress",
        value: "payload.fulladdress",
      },
      {
        label: "phonenumber",
        value: "payload.phonenumber",
      },
      {
        label: "pincode",
        value: "payload.pincode",
      },
      {
        label: "talukaid",
        value: "payload.talukaId",
      },
      {
        label: "districtid",
        value: "payload.districtId",
      },
      {
        label: "stateid",
        value: "payload.stateId",
      },
      {
        label: "imageurl",
        value: "payload.imageurl",
      },
      {
        label: "active",
        value: (row: any) => (row.payload.isactive ? 1 : 0),
      },
      {
        label: "fieldseed",
        value: (row: any) =>
          row.payload.category.find((item: any) => item === "FIELD CROPS")
            ? 1
            : 0,
      },
      {
        label: "vegseed",
        value: (row: any) =>
          row.payload.category.find((item: any) => item === "VEG SEEDS")
            ? 1
            : 0,
      },
      {
        label: "cp",
        value: (row: any) =>
          row.payload.category.find((item: any) => item === "CROP PROTECTION")
            ? 1
            : 0,
      },
      {
        label: "type",
        value: "payload.type",
      },
      {
        label: "Latitude",
        value: "payload.latitude",
      },
      {
        label: "Longitude",
        value: "payload.longitude",
      },
      {
        label: "operatinghoursfields",
        value: "payload.operatinghoursfields",
      },
      {
        label: "Village",
        value: "payload.village",
      },
    ];
    const dataObj = [
      {
        sheet: "Retailer Data Mapping",
        columns: columnsData,
        content: allData,
      },
    ];
    let settings = {
      fileName: "retailerInfo_" + new Date().getTime(), // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    ConvertJSONToXLS(dataObj, settings);
  };

  useEffect(() => {
    fetchData();
  }, [activeTab, searchedValue, sortId, filterObj, currentPage]);

  useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    } else {
      fetchData();
    }
  }, [limit]);

  return (
    <>
      <TableContainer>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            className={classes.pageTitleNSearchStylesHelper}
          >
            <PageTitle title={props.title} />
            <SearchBar
              placeholderText="Search by Name"
              searchValue={searchedValue ?? ""}
              onSearchValueChange={(newSearchValue: string) => {
                setSearchedValue(newSearchValue);
                setCurrentPage(0);
              }}
              onEnterClick={() => {}}
            />
          </Stack>
          <Box>
            <Box className={classes.customTabBtmBorder}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomTabs
                  value={activeTab}
                  tabsList={TABS_DATA}
                  onChange={handleTabsChange}
                />
                {activeTab === 1 && (
                  <DownLoadSampleTemplate
                    literal={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.RETAIL}
                    displayAsButton={false}
                  />
                )}
              </Stack>
            </Box>
            <Stack direction="row" className={classes.rowsPerPageStylesHelper}>
              <RowsPerPage
                pagSize={limit}
                onChangeRowsPerPage={(data: number) => setLimit(data)}
              />
              <FiltersAndSort
                sortBy={sortId.toString()}
                onSort={(sortType: any) => setSortId(sortType || "0")}
                onChangeFilters={(data: any) => {
                  setFilterObj(data);
                }}
                filters={{ ...filterObj }}
                showLanguageSelection={false}
                showCropStatus={false}
                showLastUpdatedOn={true}
                hasFilter={activeTab === 0}
                isResetFilter={`${activeTab}`}
                showRetailerCategoryType={true}
                showRetailerType={true}
              />
            </Stack>
            <DataTable
              enableSelection={activeTab !== 1}
              columns={
                activeTab === 1 ? RetailerMyUploadsTableColumn : RetailerColumn
              }
              data={activeTab === 1 ? uploadInfo : retailerData}
              pageNumber={currentPage}
              onPageChange={props.onPageChange}
              onSelectAll={selectAllRecords}
              onDeselectAll={() => {}}
              onActionClick={(type, rowInfo) => {
                handleActionClick(type, rowInfo);
              }}
              onRowSelect={selectEachByRow}
              onStatusChanged={(checked: boolean, data: any) => {
                changeStatusOfRetailer(checked, data);
              }}
              isEditEnabled={activeTab === 0}
              isViewButton={activeTab === 0}
              handleKnowMorePopup={(flag: boolean, rowData: any) =>
                handleKnowMorePopup(flag, rowData)
              }
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <DataTableActions
                actiontype2={ACTION_TYPE.TYPE_RETAILER}
                pageNumber={currentPage + 1}
                onPageChange={(selectedPageNo: number) =>
                  setCurrentPage(selectedPageNo - 1)
                }
                onActionClick={(data: string) => handleActionClick(data)}
                canShowAvatar={activeTab === 0}
                activeTab={activeTab}
                totalpages={totalPages}
                buttonVisible={activeTab === 0}
              />
            </Stack>
          </Box>
        </div>
      </TableContainer>
      {modalState.isCreateModal && (
        <AddNewContentModal
          title={"Add New Data"}
          isOpen={modalState.isCreateModal}
          onModalClose={() => {
            setModalState((prevState) => ({
              ...prevState,
              isCreateModal: false,
              isNewRetailerModale: false,
              isbulkUploadModal: false,
            }));
          }}
          onAddNew={() =>
            setModalState((prevState) => ({
              ...prevState,
              isCreateModal: false,
              isNewRetailerModale: true,
            }))
          }
          onBulkUpload={() =>
            setModalState((prevState) => ({
              ...prevState,
              isCreateModal: false,
              isbulkUploadModal: true,
            }))
          }
        />
      )}
      {modalState.isNewRetailerModale && (
        <CreateNewRetailerModal
          onRequestClose={(refetch: boolean) => {
            setActionType("");
            setModalState((prevState) => ({
              ...prevState,
              isNewRetailerModale: false,
            }));
            if (refetch) fetchDataList();
          }}
          onRenderUpdate={fetchDataList}
          selectedRetailerInfo={selectedRetailerInfo}
          actionType={actionType}
        />
      )}
      {modalState.isbulkUploadModal && (
        <BulkUploadModal
          allowedTypes={["xlsx", "xls"]}
          maxFileSize={5}
          templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.RETAIL}
          validationLiteral={PublishModuleDD.RETAILERS_NURSUREY}
          onClose={(currentTab: number) => {
            setModalState((prevState) => ({
              ...prevState,
              isbulkUploadModal: false,
            }));
            if (currentTab) {
              setActiveTab(currentTab);
              fetchMyUploads();
            }
          }}
          canByPassValidation={true}
        />
      )}
      {modalState.isViewRetailerModal && (
        <ViewRetailerModal
          onRequestClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewRetailerModal: false,
            }))
          }
          selectedRetailerInfo={selectedRetailerInfo}
        />
      )}
      {openKnowMore && renderKnowMoreModal()}
    </>
  );
};

export default RetailersAndNurseries;
