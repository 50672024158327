import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  DatabaseControllerContainer: {
    overflowY: "auto",
    padding: "1em",
    flex: 1,
  },
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },
  FieldLabel: {
    fontSize: "0.75em",
    fontWeight: "bold",
    fontFamily: "Noto Sans",
    marginBlockEnd: "0.5em",
  },
  FieldInputContainer: {
    width: "80ch",
  },
  SubmitButton: {
    width: "fit-content",
    paddingInline: "2em",
  },
  WrappedPre: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
});
