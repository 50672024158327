import { ActionType } from "../../Common/Constants";
import {
  IGeneralDetails,
  IStageInfo,
  IStageThreatInfo,
  IThreatDraftCreationPayload,
  IThreatFaqInfo,
  IThreatInfo,
  IThreatStages,
} from "../../Common/Constants/types";
import { RootState } from "../../redux/reducers";
import { store } from "../../redux/store";
import _ from "../../Utils/lodash";

export const preparePayloadForThreat = (
  GeneralDetails: any,
  ThreatStages: any,
  draftId: number | null | undefined
) => {
  const threatDeaftPayload = {} as IThreatDraftCreationPayload;
  // get language info
  const languageId = GeneralDetails[0].languageId;
  const languageName = GeneralDetails[0].languageName;
  const languageCode = GeneralDetails[0].languageCode;
  // get crop information from general details
  const cropID = GeneralDetails[0].filteredCrops.id;
  const cropSystemName = GeneralDetails[0].filteredCrops.name;
  threatDeaftPayload.id = draftId || null;
  threatDeaftPayload.cropSystemName = cropSystemName;
  threatDeaftPayload.languageId = languageId;
  threatDeaftPayload.languageName = languageName;
  threatDeaftPayload.languageCode = languageCode;

  const threatDetialsList: IThreatInfo[] = [];
  GeneralDetails.forEach((element: any, index: number) => {
    let threatStageObj: IThreatInfo = {
      threatId: undefined,
      threatSystemName: "",
      threatDisplayLabel: "",
      threatImagePath: "",
      displayOrder: 0,
      threatFaq: [],
      languageName: "",
    };
    threatStageObj.displayOrder = index + 1;
    threatStageObj.languageName = languageName;
    threatStageObj.threatDisplayLabel = element.threatDisplayLabel;
    threatStageObj.threatSystemName = element.threatSystemName;
    threatStageObj.threatImagePath = element.imagePath;
    threatStageObj.threatId = element.threatId;
    let threatFaqArray: IThreatFaqInfo[] = [];
    if (element.threatFAQ.length > 0) {
      element.threatFAQ.forEach((draftFaqObj: any, faqIndex: number) => {
        const faqObj: IThreatFaqInfo = {
          id: null,
          displayOrder: 0,
          header: "",
          text: "",
        };
        const { header, text } = draftFaqObj;
        faqObj.displayOrder = faqIndex + 1;
        faqObj.header = header;
        faqObj.text = text;
        threatFaqArray.push(faqObj);
      });
      threatStageObj.threatFaq = threatFaqArray;
      threatDetialsList.push(threatStageObj);
    }
  });

  const stagesInfo: IStageInfo[] = [];

  /**
   * Group by stageSystemName
   */
  let totalStages = _.groupBy(ThreatStages, "stageSystemName");
  let i = 0;
  let j = 0;
  for (let key in totalStages) {
    let stageInfo = totalStages[key];
    const threatInfo: IStageThreatInfo[] = [];

    stageInfo.map((stageObj: any, index: number) => {
      const threstStageObj: IStageThreatInfo = {
        id: stageObj.threatId || "",
        systemName: stageObj.threatSystemName,
        displayOrder: stageObj.displayOrder ? stageObj.displayOrder : index + 1,
      };
      threatInfo.push(threstStageObj);
    });
    const threstStageInfo: IStageInfo = {
      stageId: stageInfo[0]?.stageId ?? "",
      stageSystemName: key,
      displayOrder: totalStages[key]?.displayOrder ?? i + 1,
      threats: threatInfo,
    };
    j++;
    i++;
    stagesInfo.push(threstStageInfo);
  }

  // set values for key 'payload'
  threatDeaftPayload.payload = {
    cropSystemName: cropSystemName,
    languageId: languageId,
    languageName: languageName,
    cropId: cropID,
    stages: stagesInfo,
    threatDetailsList: threatDetialsList,
  };
  // set values for threatDetailsList & threat stages list
  threatDeaftPayload.statusId = draftId ? 5 : null;
  return threatDeaftPayload;
};

export const prepareThreatInfoFromEdit = (
  data: IThreatDraftCreationPayload,
  actionType: string
) => {
  let generalDetails: IGeneralDetails[];
  let threatStages: IThreatStages[];

  let storeData: RootState = store.getState();
  let primaryLanguageCode = storeData.auth.languageCode;

  const cropSystemName = data.cropSystemName;
  const cropID = data.payload.cropId;
  const languageID = data.languageId;
  const languageCode = data.languageCode;
  const languageName = data.languageName;
  const threatsArray: IGeneralDetails[] = [];
  const isFirstTranslationRecord =
    languageCode === primaryLanguageCode &&
    actionType === ActionType.ADD_TRANSLATION;

  /**
   * prepare generall details object
   */
  data.payload.threatDetailsList.forEach(
    (threatInfo: IThreatInfo, index: number) => {
      const threatInfoObj: IGeneralDetails = {
        id: isFirstTranslationRecord ? null : threatInfo.threatId,
        filteredCrops: {
          name: cropSystemName,
          label: cropSystemName,
          id: cropID,
        },
        languageName: languageName,
        languageId: languageID,
        languageCode: languageCode,
        threatSystemName: threatInfo.threatSystemName,
        threatDisplayLabel: threatInfo.threatDisplayLabel,
        imagePath: threatInfo.threatImagePath,
        threatFAQ: [...threatInfo.threatFaq],
        displayOrder: threatInfo.displayOrder || index + 1,
      };
      threatsArray.push(threatInfoObj);
    }
  );
  generalDetails = [...threatsArray];

  /**
   * prepate Threat Stages Object
   */

  const threatStagesArray: IThreatStages[] = [];
  data.payload.stages.forEach((threatStageInfo: IStageInfo) => {
    threatStageInfo.threats.forEach((element: IStageThreatInfo) => {
      const obj: any = {
        threatSystemName: element.systemName,
        stageSystemName: threatStageInfo.stageSystemName,
        stageId: isFirstTranslationRecord ? null : threatStageInfo.stageId,
        threatId: isFirstTranslationRecord ? null : element.id,
        stageDisplayOrder: threatStageInfo.displayOrder,
        threatDisplayOrder: element.displayOrder,
      };
      threatStagesArray.push(obj);
    });
  });
  threatStages = [...threatStagesArray];

  return {
    threatGeneralDetails: generalDetails,
    threatStages: threatStages,
    isDraftRecord: data.statusId === 5,
    adminDraftId: isFirstTranslationRecord ? null : data.id,
  };
};
