import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { initialFilter, FilterKeys } from ".";
import ButtonWithIcon, {
  CANCEL_TYPE,
  CREATE_TYPE,
} from "../../../Common/Buttons";
import SortableComponent from "../../../Common/SortableRecords";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { FilterKeySchemaValidation } from "../CropValidationSchema";
import { useStyles } from "../useStyle";
import { ActionType, STEPS, ValidationType } from "../../../Common/Constants";
import { genericFormFieldValidation } from "../../../Utils/fileHelper";
import RenderField from "../../../Common/RenderField";
import { DeleteOutlined } from "@mui/icons-material";

type FormProps = {
  listOfFilters: Array<FilterKeys>;
  selectedFilter: FilterKeys;
  onSave: Function;
  onRemove: Function;
  onSelect: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  updateListOrder: Function;
  isEdit: boolean;
  isPrimaryCrop: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
  actionType: string;
};

export const FilterKeyForm: React.FC<FormProps> = (props: FormProps) => {
  const classes = useStyles();
  let displayOrderArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  const [filterValuesArray] = useState<any>([
    {
      valueSystemName: "",
      displayLabel: "",
      displayOrder: 0,
    },
  ]);

  const renderFilterKeys = () => {
    return (
      <>
        <SortableComponent
          name={"Filter"}
          items={props.listOfFilters}
          updateCropStageOrder={(newList: Array<FilterKeys>) => {
            props.updateListOrder(newList);
          }}
          key={props.listOfFilters.length}
          listProps={props}
          displayKey={"systemName"}
          initialState={initialFilter}
          selectedRecord={props.selectedFilter}
          isDisabled={
            !props.isPrimaryCrop && props.actionType !== ActionType.CREATE
          }
        />
      </>
    );
  };

  const handleSubmit = (values: any, _onSubmitProps: any) => {
    if (
      checkForDuplicates(
        props.listOfFilters,
        { systemName: values!.systemName },
        values!.systemName,
        props.selectedFilter.systemName
      )
    ) {
      _onSubmitProps.setFieldError("systemName", "Duplicate system name");
      _onSubmitProps.setSubmitting(false);
      return;
    } else {
      props.onSave(values);
      _onSubmitProps.resetForm();
      props.updateUniqueKey();
      setIsRuleErrorFlag(false);
    }
  };

  const renderFieldArray = (values: any, setFieldValue: Function) => (
    <FieldArray
      name="filterValues"
      render={({ push, remove }) => (
        <div>
          {values.filterValues.map((filterValueObj: any, index: number) => (
            <div key={index}>
              <FormControl className={classes.formControlMrtHelper} fullWidth>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    Filter Value System Name *
                  </Typography>
                  {index >= 1 && (
                    <Button
                      color="info"
                      variant="text"
                      endIcon={<DeleteOutlined />}
                      onClick={() => remove(index)}
                    ></Button>
                  )}
                </Stack>
                <TextField
                  fullWidth
                  name={`filterValues.${index}.valueSystemName`}
                  defaultValue={filterValueObj.valueSystemName}
                  value={filterValueObj.valueSystemName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      `filterValues.${index}.valueSystemName`,
                      event.target.value.trim().toUpperCase()
                    )
                  }
                  disabled={props.canAddNewRecord ? !!filterValueObj.id : true}
                />
              </FormControl>
              <FormHelperText error={true}>
                <ErrorMessage name={`filterValues.${index}.valueSystemName`} />
              </FormHelperText>
              <FormControl className={classes.formControlMrtHelper} fullWidth>
                <Typography variant="subtitle2">
                  Filter Value Display label *
                </Typography>
                <TextField
                  fullWidth
                  name={`filterValues.${index}.displayLabel`}
                  defaultValue={filterValueObj.displayLabel}
                  value={filterValueObj.displayLabel}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      `filterValues.${index}.displayLabel`,
                      event?.target.value
                    )
                  }
                />
              </FormControl>
              <FormHelperText error={true}>
                <ErrorMessage name={`filterValues.${index}.displayLabel`} />
              </FormHelperText>
              <FormControl className={classes.formControlMrtHelper} fullWidth>
                <RenderField
                  name="id"
                  label="Display Order *"
                  labelKey="displayOrderName"
                  value={filterValueObj.displayOrder}
                  options={displayOrderArray.filter((obj) => {
                    return !values.filterValues.find((element: any) => {
                      return element.displayOrder === obj;
                    });
                  })}
                  onChange={(name: string, selectedType: number) => {
                    setFieldValue(
                      `filterValues.${index}.displayOrder`,
                      selectedType
                    );
                  }}
                />
              </FormControl>
            </div>
          ))}
          {props.actionType !== ActionType.ADD_TRANSLATION &&
            props.isPrimaryCrop &&
            values.filterValues.length < 12 && (
              <Button
                type="button"
                onClick={() =>
                  push({
                    valueSystemName: "",
                    displayLabel: "",
                    displayOrder: 0,
                  })
                }
                variant="outlined"
                color="success"
                className={classes.newFilterValueBtnHelper}
              >
                Add New Filter Value
              </Button>
            )}
        </div>
      )}
    />
  );

  const renderForm = ({ values, setFieldValue }: any) => (
    <>
      <Form id="filter-form">
        <Stack flex={1} justifyContent="flex-end" minWidth={350}>
          <Field
            name="systemName"
            id="systemName"
            validate={(value: string) =>
              genericFormFieldValidation(
                value,
                ValidationType.SYS_NM,
                Boolean(props.selectedFilter.systemName)
              )
            }
          >
            {() => (
              <FormControl className={classes.formControlMrtHelper} fullWidth>
                <Typography variant="subtitle2">
                  Filter key system name *
                </Typography>
                <TextField
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(
                      "systemName",
                      event.target.value.trim().toUpperCase()
                    );
                  }}
                  name="systemName"
                  defaultValue={values?.systemName}
                  value={values?.systemName}
                  key={props.uniqueKey}
                  disabled={
                    props.canAddNewRecord
                      ? !!props.selectedFilter.systemName
                      : true
                  }
                />
              </FormControl>
            )}
          </Field>
          <FormHelperText error={true}>
            <ErrorMessage name="systemName" />
          </FormHelperText>
          <Field name="filterName" id="filterName">
            {() => (
              <FormControl className={classes.formControlMrtHelper} fullWidth>
                <Typography variant="subtitle2">Display label *</Typography>
                <TextField
                  fullWidth
                  name="filterName"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("filterName", event.target.value);
                  }}
                  defaultValue={values?.filterName}
                  key={props.uniqueKey}
                />
              </FormControl>
            )}
          </Field>
          <FormHelperText error={true}>
            <ErrorMessage name="filterName" />
          </FormHelperText>
          {renderFieldArray(values, setFieldValue)}
          <Stack alignSelf="self-end" direction="row" spacing={2} my={2}>
            <Button
              disabled={
                !(props.selectedFilter.systemName
                  ? props.canUpdateExistingRecord
                  : props.canAddNewRecord)
              }
              type="submit"
              variant="contained"
              color="success"
              data-testid="add-filter"
            >
              {(props.selectedFilter?.systemName && "Update Filter") ||
                "Add Filter"}
            </Button>
          </Stack>
        </Stack>
      </Form>
      <Divider orientation="vertical" flexItem />
    </>
  );

  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedFilter.systemName]);

  return (
    <>
      <Box>
        <Box className={classes.formContainer}>
          <div className={classes.formContainerChildDivHelper}>
            <Stack direction="row" spacing={2}>
              <Formik
                validationSchema={FilterKeySchemaValidation}
                enableReinitialize={true}
                initialValues={{
                  systemName: props.selectedFilter.systemName,
                  filterName: props.selectedFilter.filterName,
                  filterValues:
                    props.selectedFilter.filterValues &&
                    props.selectedFilter.filterValues.length > 0
                      ? [...props.selectedFilter.filterValues]
                      : [...filterValuesArray],
                }}
                onSubmit={handleSubmit}
              >
                {renderForm}
              </Formik>
              <Stack flex={1}>
                <Box>
                  <Typography variant="subtitle2">
                    Added Filter Key(s):
                  </Typography>
                  <Typography variant="caption" component="span">
                    You may reorder the stages by dragging each panel up or down
                  </Typography>
                  <FormHelperText error={true}>
                    {isRuleErrorFlag && (
                      <>Create at least one record to proceed</>
                    )}
                  </FormHelperText>
                  {renderFilterKeys()}
                </Box>
              </Stack>
            </Stack>
          </div>
        </Box>
        <>
          <Divider />
          <div className={classes.modalFooterActionBtnsHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CANCEL_TYPE}
                label="Previous"
                onClick={() => {
                  props.onPrevious(STEPS.STEP2);
                }}
              />
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                label="Next"
                onClick={() => {
                  props.onNext(STEPS.STEP4);
                  // props.listOfFilters.length > 0
                  //   ? props.onNext(STEPS.STEP4)
                  //   : setIsRuleErrorFlag(true);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
