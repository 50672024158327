import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../../Api/end_points";
import ButtonWithIcon, { CANCEL_TYPE } from "../../../Common/Buttons";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import MultiSelect from "../../../Common/MultiSelect";
import { IRequestBody } from "../../../Constants/interfaces";
import { genericValidateImage } from "../../../Utils/fileHelper";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { RootState } from "../../../redux/reducers";
import {
  fileRemoveAPI,
  fileUploadAPI,
  getRetailersViewDraftInfo,
  getRetailerDistrictTalukaAPI,
  getRetailerStates,
  getRetailerStatesDistricts,
  retailerSubmitFormAPI,
} from "../RetailerApiCalls";
import {
  IMAGE_REMOVE_PAYLOAD,
  IMAGE_UPLOAD_PAYLOAD,
  RETAILER_CATEGORY_MULTISELECT_DATA,
  RETAILER_SUBMIT_FORM_PAYLOAD,
} from "../utils";
import { ValidationSchema } from "./ValidationSchema";
import { useStyles } from "./styles";

interface IInitialValuesObj {
  category: Array<string>;
  closeDays: string;
  closingHour: string;
  district: string;
  districtId: number | undefined;
  fullAddress: string;
  image: any;
  latitude: number;
  longitude: number;
  openDays: string;
  openingHour: string;
  phoneNumber: string;
  pincode: string;
  retailerName: string;
  shortAddress: string;
  state: string;
  stateId: number | undefined;
  taluka: string;
  talukaId: number | undefined;
  type: string;
  village: string;
  storeId: number | null;
}

type StateObj = {
  stateId: string | number;
  stateName: string;
};

type DistrictObj = {
  districtId: string | number;
  districtName: string;
};

type TalukObj = {
  talukaId: string | number;
  talukaName: string;
};

type LocObj = {
  districtId: string;
  stateId: string;
  talukaId: string;
};

const initialValues: IInitialValuesObj = {
  retailerName: "",
  image: "",
  shortAddress: "",
  fullAddress: "",
  phoneNumber: "",
  pincode: "",
  state: "",
  district: "",
  taluka: "",
  village: "",
  stateId: undefined,
  districtId: undefined,
  talukaId: undefined,
  type: "",
  latitude: 0,
  longitude: 0,
  openDays: "",
  closeDays: "",
  openingHour: "",
  closingHour: "",
  category: [],
  storeId: null,
};

const CreateNewRetailerModal = (props: any) => {
  const classes = useStyles();
  const isEdit =
    props.actionType === "Edit_Retailer" || props?.isPublishRetailer;
  const Input = styled("input")({
    display: "none",
  });

  const [initialFormObj, setInitialFormObj] =
    useState<IInitialValuesObj>(initialValues);

  //state list;
  const [stateList, setStateList] = useState<StateObj[]>([]);
  const [districtList, setDistrictList] = useState<DistrictObj[]>([]);
  const [talukaList, setTalukaList] = useState<TalukObj[]>([]);
  const [isDraftRecord] = useState<boolean>(!!props.isPublishRetailer);

  const storeData = useSelector((state: RootState) => {
    return {
      mobileLEngth: state.generic.RetailerMobileLength,
    };
  });

  const [locationObj, setLocationObj] = useState<LocObj>({
    stateId: "",
    districtId: "",
    talukaId: "",
  });

  //category list
  const [categoryList, setCategoryList] = useState<Array<any>>(
    RETAILER_CATEGORY_MULTISELECT_DATA
  );
  const [selectedCategory, setSelectedCategory] = useState<Array<any>>([]);
  const [selectedCategoryObj, setSelectedCategoryObj] = useState<any>([]);

  //clearimage
  const [isImageRemoved, setImageRemoved] = useState<boolean>(false);
  const [removeImageUrl, setRemovedImageUrl] = useState<any>("");
  const header: string =
    (isEdit && `Edit Retailer : ${initialFormObj.retailerName}`) ||
    "New Retailer/Nursery";

  useEffect(() => {
    setImageRemoved(false);
    categorySet(initialFormObj.category);
    if (isEdit) {
      fetchRetailerInfo();
    } else {
      setInitialFormObj(initialValues);
    }
    fetchRetailerStates();
  }, []);

  const categorySet = (tempCategory?: any) => {
    let categoryData = [...categoryList];
    let allCategoryList = {
      categoryName: "Select all",
      checked: false,
    };
    let selectedCategoryIDs: Array<number> = [];
    tempCategory?.forEach((eachCrop: any) => {
      selectedCategoryIDs.push(eachCrop.id);
    });

    categoryData.forEach((eachCategory: any) => {
      eachCategory.label = eachCategory.nameTranslation;
      /** if item already selected, show as selected */
      selectedCategoryIDs?.indexOf(eachCategory.id) !== -1
        ? (eachCategory.checked = true)
        : (eachCategory.checked = false);
    });
    categoryData.unshift(allCategoryList);
    setCategoryList(categoryData);
    if (isEdit) {
      // const sampleCategory = ["Crop Protection"];
      const editCategory: string[] = tempCategory?.map((item: any) =>
        item?.categoryName?.toLowerCase()
      );

      let filteredCategory: any = categoryData.map((item) => {
        item.checked = editCategory.includes(item.categoryName?.toLowerCase());
        return {
          ...item,
        };
      });
      if (
        filteredCategory.filter((item: any) => item.checked).length ===
        RETAILER_CATEGORY_MULTISELECT_DATA.length
      ) {
        filteredCategory = filteredCategory.map((item: any) => {
          if (item.categoryName === "Select all") {
            item.checked = true;
          }
          return {
            ...item,
          };
        });
      }
      const tempFilteredCategory = filteredCategory.filter(
        (state: any) => state.checked && state.categoryName !== "Select all"
      );

      setCategoryList(filteredCategory);
      setSelectedCategory(
        filteredCategory.filter(
          (item: any) => item.checked && item.categoryName !== "Select all"
        )
      );

      setSelectedCategoryObj(
        tempFilteredCategory.length > 0 ? tempFilteredCategory : null
      );
    }
  };

  /** Clear selected image */
  const clearImageObject = (setFieldValue: any) => {
    /**remove img code to upload the same img again */
    setRemovedImageUrl(initialFormObj.image);
    setImageRemoved(true);
    let parent: any = document.getElementById("upload-img");
    let children = parent.children[0];
    children.value = "";
    setFieldValue("image", "");
  };

  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "view_retailers_info": {
        const responceData = response?.data?.payload;
        const requiredData: Array<IInitialValuesObj> = [responceData].map(
          (item: any) => ({
            retailerName: item.name,
            image: item.imageurl,
            shortAddress: item.shortaddress,
            fullAddress: item.fulladdress,
            phoneNumber: item.phonenumber,
            pincode: item.pincode,
            state: item.state,
            district: item.district,
            taluka: item.taluka,
            stateId: item.stateId,
            districtId: item.districtId,
            talukaId: item.talukaId,
            village: item.village,
            type: item.type,
            latitude: item.latitude,
            longitude: item.longitude,
            storeId: item.storeId,
            openDays:
              typeof item.operatinghoursfields === "string" &&
              item.operatinghoursfields.length > 0
                ? JSON.parse(item.operatinghoursfields).openDays
                : "",
            closeDays:
              typeof item.operatinghoursfields === "string" &&
              item.operatinghoursfields.length > 0
                ? JSON.parse(item.operatinghoursfields).closedDays
                : "",
            openingHour:
              typeof item.operatinghoursfields === "string" &&
              item.operatinghoursfields.length > 0
                ? JSON.parse(item.operatinghoursfields).openingHour
                : "",
            closingHour:
              typeof item.operatinghoursfields === "string" &&
              item.operatinghoursfields.length > 0
                ? JSON.parse(item.operatinghoursfields).closingHour
                : "",
            category: item.category?.map((catval: string) => ({
              categoryName: catval,
            })),
          })
        );
        setLocationObj({
          stateId: responceData.stateId,
          districtId: responceData.districtId,
          talukaId: responceData.talukaId,
        });
        setInitialFormObj(requiredData?.[0]);
        return categorySet(requiredData?.[0]?.category);
      }
      case "retailer_states": {
        const stateData = response?.data.map((item: any) => ({
          stateId: item.stateId,
          stateName: item?.stateName,
        }));
        return setStateList(stateData);
      }
      case "retailer_districts": {
        const districtData = response?.data.map((item: any) => ({
          districtId: item.districtId,
          districtName: item?.districtName,
        }));
        return setDistrictList(districtData);
      }
      case "retailer_Taluka": {
        const talukaData = response?.data.map((item: any) => ({
          talukaId: item.talukaId,
          talukaName: item?.talukaName,
        }));
        return setTalukaList(talukaData);
      }
      case "retailer_submit_form": {
        // console.log("retailer_submit_form SUCCESS CALLBACK", response);
        props.onRequestClose(true);
        break;
      }
      default:
        break;
    }
  };

  const errorCallback = (_error: any, type: string) => {
    switch (type) {
      case "save_update": {
        /** Closing the popup, passing false to display failed creation */
        // handleClose(false);
        break;
      }
      default: {
        /**Generic alert to display API fail */
        TriggerToastMessage(
          "Unable to process your request!!!" + type,
          "error"
        );
      }
    }
  };

  const fetchRetailerInfo = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = (
      props.isPublishRetailer
        ? api_endpoints.retailer_publish_info_draft
        : api_endpoints.retailer_view_info_draft
    ).replace("__storeId__", `${props?.selectedRetailerInfo?.id?.toString()}`);
    apiData.showLoader = true;
    return getRetailersViewDraftInfo(apiData, successCallBack, errorCallback);
  };

  const fetchRetailerStates = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.country_wise_states;
    apiData.showLoader = true;
    return getRetailerStates(apiData, successCallBack, errorCallback);
  };

  const fetchStateDistricts = (selectedStateIds: Array<number | undefined>) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.state_districts;
    apiData.showLoader = true;
    apiData.payLoad = { ids: selectedStateIds };
    return getRetailerStatesDistricts(apiData, successCallBack, errorCallback);
  };

  const fetchDistrictTaluka = (
    selectedDistrictId: Array<number | undefined>
  ) => {
    const apiData = {} as IRequestBody;
    let end_point = api_endpoints.retailer_taluka.replace(
      "__stateId__",
      locationObj.stateId
    );
    end_point = end_point.replace("__districtId__", `${selectedDistrictId}`);
    apiData.domain = base_url;
    apiData.endPoint = end_point;
    apiData.showLoader = true;
    // apiData.payLoad = { ids: selectedDistrictId };
    return getRetailerDistrictTalukaAPI(
      apiData,
      successCallBack,
      errorCallback
    );
  };

  const handleState = (event: any, setFieldValue: any) => {
    setFieldValue("district", "");
    setFieldValue("taluka", "");
    setDistrictList([]);
    setTalukaList([]);
    const numberPattern = /\d+/g;
    const stateId = event?.target?.value.match(numberPattern)[0];
    setLocationObj((prevState) => ({
      ...prevState,
      stateId: stateId,
    }));
    return fetchStateDistricts([parseInt(stateId)]);
  };

  const handleDistrict = (event: any, setFieldValue: any) => {
    setTalukaList([]);
    setFieldValue("taluka", "");
    const numberPattern = /\d+/g;
    const districtId = event?.target?.value.match(numberPattern)[0];
    setLocationObj((prevState) => ({
      ...prevState,
      districtId: districtId,
    }));
    return fetchDistrictTaluka([parseInt(districtId)]);
  };

  const handleTaluka = (event: any) => {
    const numberPattern = /\d+/g;
    const talukaId = event?.target?.value.match(numberPattern)[0];
    setLocationObj((prevState) => ({
      ...prevState,
      talukaId: talukaId,
    }));
  };
  const removeImageFromS3 = (image: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.remove_img_list;
    apiData.showLoader = true;
    let payloadData = IMAGE_REMOVE_PAYLOAD;
    payloadData.fileUrlsList = [`${image ?? ""}`];
    apiData.payLoad = payloadData;
    return fileRemoveAPI(apiData, successCallBack, errorCallback);
  };

  const imageUploadToS3 = async (values: any) => {
    const oldImage = `${initialFormObj.image}`.split("/").pop();
    // console.log("REMOVE IMAGE", values?.image?.name, oldImage);
    if (isEdit && isImageRemoved && removeImageUrl) {
      removeImageFromS3(removeImageUrl);
    } else if (
      isEdit &&
      values?.image?.name &&
      values?.image?.name !== oldImage
    ) {
      removeImageFromS3(initialFormObj.image);
    }
    // if (isEdit && values?.image?.name !== oldImage) {
    //   removeImageFromS3(initialFormObj.image);
    // }
    if (typeof values.image === "object" && values.image !== "") {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      apiData.endPoint = api_endpoints.fileupload_S3bucket;
      apiData.showLoader = true;
      let formData: any = new FormData();
      let data = IMAGE_UPLOAD_PAYLOAD;
      data.fileNameWithExtension = values.image && values.image.name;
      data.storeId = isEdit ? props.selectedRetailerInfo?.id : null;
      formData.append("file", values.image);
      formData.append("data", JSON.stringify(data));
      apiData.payLoad = formData;
      const resp = await fileUploadAPI(apiData, successCallBack, errorCallback);
      return resp?.data?.imageUrl;
    }
    return values.image;
  };

  const onHandleSubmit = async (values: IInitialValuesObj) => {
    /**image is optional */
    let uploadImageResp: string = values.image
      ? await imageUploadToS3(values)
      : "";

    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.retailer_info_draft;
    apiData.showLoader = true;
    const payloadData = RETAILER_SUBMIT_FORM_PAYLOAD;
    if (isEdit && props.selectedRetailerInfo && props.selectedRetailerInfo.id) {
      payloadData.id = props.selectedRetailerInfo.id;
    }
    payloadData.smartGrowerRetailerId = isEdit ? values.storeId : null;
    payloadData.payload.storeId = isEdit ? values.storeId : null;
    if (isDraftRecord) {
      payloadData.id = props.selectedRetailerInfo.id;
    } else {
      payloadData.id = null;
    }
    payloadData.payload.name = values.retailerName;
    payloadData.payload.imageurl = uploadImageResp ?? null;
    payloadData.payload.shortaddress = values.shortAddress;
    payloadData.payload.fulladdress = values.fullAddress;
    payloadData.payload.phonenumber = values.phoneNumber;
    payloadData.payload.pincode = values.pincode;
    payloadData.payload.state = values.state;
    payloadData.payload.district = values.district;
    payloadData.payload.taluka = values.taluka;

    payloadData.payload.stateId = locationObj.stateId
      ? Number(locationObj.stateId)
      : undefined;
    payloadData.payload.districtId = locationObj.districtId
      ? Number(locationObj.districtId)
      : undefined;
    payloadData.payload.talukaId = locationObj.talukaId
      ? Number(locationObj.talukaId)
      : undefined;

    payloadData.payload.category = values.category.map((item: any) =>
      item?.categoryName?.toUpperCase()
    );
    payloadData.payload.type = values.type;
    payloadData.payload.latitude = values.latitude;
    payloadData.payload.longitude = values.longitude;
    payloadData.payload.operatinghoursfields = JSON.stringify({
      openDays: values.openDays,
      closedDays: values.closeDays,
      openingHour: values.openingHour,
      closingHour: values.closingHour,
    });
    payloadData.payload.village = values.village;
    apiData.payLoad = payloadData;
    // console.log('ONSUBMIT PAYLOAD', JSON.stringify(payloadData));
    return retailerSubmitFormAPI(apiData, successCallBack, errorCallback);
  };

  const handleAllSatesSelection = (checked: boolean, setFieldValue?: any) => {
    let allCategoryList: any = categoryList;
    allCategoryList.map((state: any) => {
      state.checked = checked;
    });
    setCategoryList(allCategoryList);
    let filteredCategory = allCategoryList.filter(
      (state: any) => state.checked && state.categoryName !== "Select all"
    );
    const finalCategoryData = filteredCategory.length ? filteredCategory : null;
    setSelectedCategory(finalCategoryData);
    setSelectedCategoryObj(finalCategoryData);
    setFieldValue("category", finalCategoryData);
  };

  const validateImage = (value: any) => {
    let errMsg: String = genericValidateImage(value);
    if (errMsg !== "Image required") {
      return errMsg;
    }
    return "";
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    const { checked, value } = event.target;
    if (value === "Select all") {
      handleAllSatesSelection(checked, setFieldValue);
    } else {
      /**automatic uncheck 'Select All' */
      if (categoryList && categoryList[0].checked && !checked) {
        categoryList[0].checked = false;
      }
      /**automatic check 'Select All' */
      if (
        checked &&
        _.filter(categoryList, (state) => state.checked).length + 1 ===
          categoryList.length - 1
      ) {
        categoryList[0].checked = true;
      }
      /**one by one check and uncheck */
      const index: number = categoryList.findIndex(
        (state: any) => state.categoryName === value
      );
      categoryList[index].checked = checked;
      setCategoryList([...categoryList]);
      /**filter out selected states */
      let filteredCategory: any = categoryList.filter(
        (state: any) => state.checked && state.categoryName !== "Select all"
      );
      const finalCategoryData = filteredCategory.length
        ? filteredCategory
        : null;
      setSelectedCategory(finalCategoryData);
      setSelectedCategoryObj(finalCategoryData);
      setFieldValue("category", finalCategoryData);
    }
  };

  const handleClearStateSelection = (checked: boolean) => {
    handleAllSatesSelection(checked);
  };

  return (
    <>
      <Formik
        initialValues={initialFormObj}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values: any, _onSubmitProps: any) => {
          // console.log("ONSUBMIIITTTT", values);
          // if (typeof values.image === "object") {
          //   imageUploadToS3(values);
          // }
          onHandleSubmit(values);
          props.onRenderUpdate();
        }}
      >
        {({ values }) => (
          <Modal open={true} disableEscapeKeyDown={true} onClose={() => {}}>
            <Form className={classes.formOutline}>
              <Paper className={classes.formPaper}>
                <Paper elevation={0} square>
                  <ModalHeader
                    header={header}
                    showDelete={false}
                    onClose={() => {
                      if (confirm("You may lose your data.")) {
                        props.onRequestClose();
                      }
                    }}
                    showEdit={false}
                  />
                  <Box className={classes.formContainer}>
                    <div className={classes.formFieldsHelper}>
                      <Stack flex={1}>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Retailer Name *
                          </Typography>
                          <Field name="retailerName">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  value={values.retailerName}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "retailerName",
                                      event.target.value
                                    );
                                  }}
                                  name="retailerName"
                                  inputProps={{ maxLength: 50 }}
                                  defaultValue={initialFormObj.retailerName}
                                  key={initialFormObj.retailerName}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="retailerName">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>

                        <Field name="image" validate={validateImage}>
                          {(imageProps: any) => {
                            const { form } = imageProps;
                            const { setFieldValue } = form;
                            return (
                              // UploadButtons('Notification Image')
                              <Stack>
                                <Stack
                                  direction="row"
                                  alignItems="flex-start"
                                  justifyContent="space-between"
                                  spacing={1}
                                  marginTop={3}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    fontSize={14}
                                    component="span"
                                    width="104px"
                                  >
                                    Retailer Image
                                  </Typography>
                                  <Stack>
                                    <label
                                      htmlFor="contained-button-file"
                                      id="upload-img"
                                    >
                                      <Input
                                        accept=".jpg, .png, .jpeg"
                                        id="contained-button-file"
                                        type="file"
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                          setFieldValue(
                                            "image",
                                            event.target?.files &&
                                              event.target.files[0]
                                          );
                                        }}
                                      />
                                      {/* Displaying image either by Raw data or by using URL */}
                                      {values.image && (
                                        <img
                                          alt={"retailer-image-preview"}
                                          src={
                                            typeof values.image !== "string"
                                              ? `${URL.createObjectURL(
                                                  values.image
                                                )}`
                                              : `${values.image}`
                                          }
                                          width="128px"
                                          height="128px"
                                        />
                                      )}
                                      {!values.image && (
                                        <Button
                                          variant="contained"
                                          size="small"
                                          component="span"
                                          disableRipple
                                          disableElevation
                                          disableTouchRipple
                                          className={
                                            classes.formUploadImageHelper
                                          }
                                        >
                                          <AddIcon
                                            className={
                                              classes.uploadImgBtnIconColorHelper
                                            }
                                          />{" "}
                                          Upload an image
                                        </Button>
                                      )}
                                    </label>
                                  </Stack>

                                  <Stack spacing={2}>
                                    <FormHelperText
                                      className={
                                        classes.formHelperTextFontSizeHelper
                                      }
                                    >
                                      JPG, JPEG or PNG. Max size of 5 MB
                                    </FormHelperText>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <PublishOutlinedIcon fontSize="inherit" />
                                      <Typography variant="body2">
                                        <label
                                          className={classes.rpPicture}
                                          htmlFor="contained-button-file2"
                                        >
                                          {values.image && (
                                            <Input
                                              accept=".jpg, .png, .jpeg"
                                              id="contained-button-file2"
                                              // multiple
                                              type="file"
                                              onChange={(
                                                event: ChangeEvent<HTMLInputElement>
                                              ) => {
                                                setFieldValue(
                                                  "image",
                                                  event.target?.files &&
                                                    event.target.files[0]
                                                );
                                              }}
                                            />
                                          )}
                                          Replace picture
                                        </label>
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      className={classes.rmPicture}
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="center"
                                      onClick={() => {
                                        clearImageObject(setFieldValue);
                                        // setFieldValue("image", "");
                                      }}
                                    >
                                      <DeleteOutlinedIcon fontSize="inherit" />
                                      <Typography variant="body2">
                                        Remove picture
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </Stack>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="image" />
                        </FormHelperText>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Short Address *
                          </Typography>
                          <Field name="shortAddress">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  onChange={(event) => {
                                    setFieldValue(
                                      "shortAddress",
                                      event.target.value
                                    );
                                  }}
                                  name="shortAddress"
                                  value={values.shortAddress}
                                  inputProps={{ maxLength: 50 }}
                                  defaultValue={initialFormObj.shortAddress}
                                  key={initialFormObj.shortAddress}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="shortAddress">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Full Address *
                          </Typography>
                          <Field name="fullAddress">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  onChange={(event) => {
                                    setFieldValue(
                                      "fullAddress",
                                      event.target.value
                                    );
                                  }}
                                  name="fullAddress"
                                  value={values.fullAddress}
                                  inputProps={{ maxLength: 50 }}
                                  defaultValue={initialFormObj?.fullAddress}
                                  key={initialFormObj?.fullAddress}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="fullAddress">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Phone Number *
                          </Typography>
                          <Field name="phoneNumber">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  onChange={(event) => {
                                    setFieldValue(
                                      "phoneNumber",
                                      event.target.value
                                    );
                                  }}
                                  name="phoneNumber"
                                  value={values.phoneNumber}
                                  inputProps={{
                                    maxLength: storeData.mobileLEngth,
                                  }}
                                  defaultValue={initialFormObj?.phoneNumber}
                                  key={initialFormObj?.phoneNumber}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="phoneNumber">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Pin Code
                          </Typography>
                          <Field name="pincode">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  onChange={(event) => {
                                    setFieldValue(
                                      "pincode",
                                      event.target.value
                                    );
                                  }}
                                  name="pincode"
                                  value={values.pincode}
                                  inputProps={{ maxLength: 50 }}
                                  defaultValue={initialFormObj?.pincode}
                                  key={initialFormObj?.pincode}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="pincode">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                        <Stack
                          direction={"row"}
                          flex={1}
                          // spacing={1}
                          // maxWidth={"400px"}
                          justifyContent={"space-between"}
                          // className={classes.formLocationRow}
                        >
                          <Stack direction={"column"}>
                            <Field name="state">
                              {(stateNameProperties: any) => {
                                const { form } = stateNameProperties;
                                const { setFieldValue } = form;
                                return (
                                  <FormControl
                                    className={classes.formLocationHelper}
                                  >
                                    <Typography variant="subtitle2">
                                      State *
                                    </Typography>

                                    <Select
                                      name="state"
                                      // sx={{ minWidth: "300px", maxWidth: "350px" }}
                                      value={values.state}
                                      onChange={(event: any) => {
                                        setFieldValue(
                                          "state",
                                          event.target.value?.replace(
                                            /[^a-zA-Z]+/g,
                                            ""
                                          )
                                        );
                                        handleState(event, setFieldValue);
                                      }}
                                      IconComponent={() =>
                                        !props.isDisabled ? (
                                          <ExpandMoreIcon
                                            className={classes.selectExpandMore}
                                          />
                                        ) : (
                                          <></>
                                        )
                                      }
                                      renderValue={(value) => value}
                                      readOnly={!!props.isDisabled}
                                    >
                                      {stateList?.map(
                                        (value: any, index: number) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={`${value.stateId}${value.stateName}`}
                                            >
                                              <Typography
                                                className={
                                                  classes.formLabelCreateRetailerHelper
                                                }
                                                fontSize={14}
                                              >
                                                {value.stateName}
                                              </Typography>
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                );
                              }}
                            </Field>
                            <ErrorMessage name={"state"}>
                              {(
                                errorMsg:
                                  | boolean
                                  | React.ReactChild
                                  | React.ReactFragment
                                  | React.ReactPortal
                                  | null
                                  | undefined
                              ) => (
                                <FormHelperText error={true}>
                                  {errorMsg}
                                </FormHelperText>
                              )}
                            </ErrorMessage>
                          </Stack>
                          <Stack direction={"column"}>
                            <Field name="district">
                              {(stateNameProperties: any) => {
                                const { form } = stateNameProperties;
                                const { setFieldValue } = form;
                                return (
                                  <FormControl
                                    className={classes.formLocationHelper}
                                  >
                                    <Typography variant="subtitle2">
                                      District *
                                    </Typography>
                                    <Select
                                      name="district"
                                      value={values.district}
                                      onChange={(event: any) => {
                                        setFieldValue(
                                          "district",
                                          event.target.value?.replace(
                                            /[^a-zA-Z]+/g,
                                            ""
                                          )
                                        );
                                        handleDistrict(event, setFieldValue);
                                      }}
                                      IconComponent={() =>
                                        !props.isDisabled ? (
                                          <ExpandMoreIcon
                                            className={classes.selectExpandMore}
                                          />
                                        ) : (
                                          <></>
                                        )
                                      }
                                      renderValue={(value) => value}
                                      readOnly={!!props.isDisabled}
                                    >
                                      {districtList?.map(
                                        (value: any, index: number) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={`${value.districtId}${value.districtName}`}
                                            >
                                              <Typography
                                                className={
                                                  classes.formLabelCreateRetailerHelper
                                                }
                                                fontSize={14}
                                              >
                                                {value.districtName}
                                              </Typography>
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                );
                              }}
                            </Field>
                            <ErrorMessage name={"district"}>
                              {(
                                errorMsg:
                                  | boolean
                                  | React.ReactChild
                                  | React.ReactFragment
                                  | React.ReactPortal
                                  | null
                                  | undefined
                              ) => (
                                <FormHelperText error={true}>
                                  {errorMsg}
                                </FormHelperText>
                              )}
                            </ErrorMessage>
                          </Stack>

                          <Stack direction={"column"}>
                            <Field name="taluka">
                              {(stateNameProperties: any) => {
                                const { form } = stateNameProperties;
                                const { setFieldValue } = form;
                                return (
                                  <FormControl
                                    className={classes.formLocationHelper}
                                  >
                                    <Typography variant="subtitle2">
                                      Taluka *
                                    </Typography>
                                    <Select
                                      name="taluka"
                                      // sx={{ minWidth: "300px", maxWidth: "350px" }}
                                      value={values.taluka}
                                      onChange={(event: any) => {
                                        setFieldValue(
                                          "taluka",
                                          event.target.value?.replace(
                                            /[^a-zA-Z]+/g,
                                            ""
                                          )
                                        );
                                        handleTaluka(event);
                                      }}
                                      IconComponent={() =>
                                        !props.isDisabled ? (
                                          <ExpandMoreIcon
                                            className={classes.selectExpandMore}
                                          />
                                        ) : (
                                          <></>
                                        )
                                      }
                                      renderValue={(value) => value}
                                      readOnly={!!props.isDisabled}
                                    >
                                      {talukaList?.map(
                                        (value: any, index: number) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={`${value.talukaId}${value.talukaName}`}
                                            >
                                              <Typography
                                                className={
                                                  classes.formLabelCreateRetailerHelper
                                                }
                                                fontSize={14}
                                              >
                                                {value.talukaName}
                                              </Typography>
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                );
                              }}
                            </Field>
                            <ErrorMessage name={"taluka"}>
                              {(
                                errorMsg:
                                  | boolean
                                  | React.ReactChild
                                  | React.ReactFragment
                                  | React.ReactPortal
                                  | null
                                  | undefined
                              ) => (
                                <FormHelperText error={true}>
                                  {errorMsg}
                                </FormHelperText>
                              )}
                            </ErrorMessage>
                          </Stack>
                        </Stack>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Village Name
                          </Typography>
                          <Field name="village">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  onChange={(event) => {
                                    setFieldValue(
                                      "village",
                                      event.target.value
                                    );
                                  }}
                                  name="village"
                                  value={values.village}
                                  inputProps={{ maxLength: 50 }}
                                  defaultValue={initialFormObj?.village}
                                  key={initialFormObj?.village}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="village">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                        <Stack className={classes.formControlMrtHelper}>
                          <Typography variant="subtitle2">Type *</Typography>
                          <div className={classes.formTypeRow}>
                            <Field name="retailer">
                              {(smsProps: any) => {
                                const { form } = smsProps;
                                const { setFieldValue } = form;
                                return (
                                  // <div className={classes.formSendSMSHelper}>
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                  >
                                    <Checkbox
                                      checked={
                                        values.type.toLocaleLowerCase() ===
                                        "retailer"
                                      }
                                      size="small"
                                      name="Retailer"
                                      value={"Retailer"}
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setFieldValue(
                                          "type",
                                          event?.target?.checked
                                            ? event?.target?.value
                                            : ""
                                        );
                                      }}
                                      key={`Retailer`}
                                    />

                                    <Typography variant="body1" fontSize={14}>
                                      Retailer
                                    </Typography>
                                  </Stack>
                                  // </div>
                                );
                              }}
                            </Field>

                            <Field name="nursery">
                              {(smsProps: any) => {
                                const { form } = smsProps;
                                const { setFieldValue } = form;
                                return (
                                  // <div className={classes.formSendSMSHelper}>
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <Checkbox
                                      checked={
                                        values.type.toLocaleLowerCase() ===
                                        "nursery"
                                      }
                                      size="small"
                                      value={"Nursery"}
                                      name="Nursery"
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setFieldValue(
                                          "type",
                                          event?.target?.checked
                                            ? event?.target?.value
                                            : ""
                                        );
                                      }}
                                      key={`Nursery`}
                                    />

                                    <Typography variant="body1" fontSize={14}>
                                      Nursery
                                    </Typography>
                                  </Stack>
                                  // </div>
                                );
                              }}
                            </Field>

                            <Field name="franchise">
                              {(smsProps: any) => {
                                const { form } = smsProps;
                                const { setFieldValue } = form;
                                return (
                                  // <div className={classes.formSendSMSHelper}>
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <Checkbox
                                      checked={
                                        values.type.toLocaleLowerCase() ===
                                        "franchise"
                                      }
                                      size="small"
                                      value={"Franchise"}
                                      name="Franchise"
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setFieldValue(
                                          "type",
                                          event?.target?.checked
                                            ? event?.target?.value
                                            : ""
                                        );
                                      }}
                                      key={`Franchise`}
                                    />

                                    <Typography variant="body1" fontSize={14}>
                                      Franchise
                                    </Typography>
                                  </Stack>
                                  // </div>
                                );
                              }}
                            </Field>
                          </div>
                        </Stack>
                        <ErrorMessage name={"type"}>
                          {(
                            errorMsg:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined
                          ) => (
                            <FormHelperText error={true}>
                              {errorMsg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Latitude
                          </Typography>
                          <Field name="latitude">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const filteredValue = inputValue.replace(
                                      /[^0-9-]/g,
                                      ""
                                    );

                                    setFieldValue("latitude", filteredValue);
                                  }}
                                  name="latitude"
                                  value={values.latitude}
                                  inputProps={{ maxLength: 50 }}
                                  defaultValue={initialFormObj?.latitude}
                                  key={initialFormObj?.latitude}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="latitude">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant="subtitle2">
                            Longitude
                          </Typography>
                          <Field name="longitude">
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextField
                                  fullWidth
                                  onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const filteredValue = inputValue.replace(
                                      /[^0-9-]/g,
                                      ""
                                    );
                                    setFieldValue("longitude", filteredValue);
                                  }}
                                  name="longitude"
                                  value={values.longitude}
                                  inputProps={{ maxLength: 50 }}
                                  defaultValue={initialFormObj?.longitude}
                                  key={initialFormObj?.longitude}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="longitude">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                        <FormControl className={classes.formControlMrtHelper}>
                          <Stack className={classes.formControlMrtHelper}>
                            <Typography variant="subtitle2">
                              Operating Hours:
                            </Typography>
                            <div className={classes.formTypeRow}>
                              <FormControl
                                className={classes.formControlMrtHelper}
                              >
                                <Typography fontSize={14} variant="subtitle2">
                                  Open Days
                                </Typography>
                                <Field name="openDays">
                                  {(titleProps: any) => {
                                    const { form } = titleProps;
                                    const { setFieldValue } = form;
                                    return (
                                      <TextField
                                        fullWidth
                                        onChange={(event) => {
                                          setFieldValue(
                                            "openDays",
                                            event.target.value
                                          );
                                        }}
                                        name="openDays"
                                        value={values.openDays}
                                        inputProps={{ maxLength: 50 }}
                                        defaultValue={initialFormObj?.openDays}
                                        key={initialFormObj?.openDays}
                                      />
                                    );
                                  }}
                                </Field>
                              </FormControl>

                              <FormControl
                                className={classes.formControlMrtHelper}
                              >
                                <Typography fontSize={14} variant="subtitle2">
                                  Closed Days
                                </Typography>
                                <Field name="closeDays">
                                  {(titleProps: any) => {
                                    const { form } = titleProps;
                                    const { setFieldValue } = form;
                                    return (
                                      <TextField
                                        fullWidth
                                        onChange={(event) => {
                                          setFieldValue(
                                            "closeDays",
                                            event.target.value
                                          );
                                        }}
                                        name="closeDays"
                                        value={values.closeDays}
                                        inputProps={{ maxLength: 50 }}
                                        defaultValue={initialFormObj?.closeDays}
                                        key={initialFormObj?.closeDays}
                                      />
                                    );
                                  }}
                                </Field>
                              </FormControl>
                            </div>

                            <div className={classes.formTypeRow}>
                              <FormControl
                                className={classes.formControlMrtHelper}
                              >
                                <Typography fontSize={14} variant="subtitle2">
                                  Opening Hour
                                </Typography>
                                <Field name="openingHour">
                                  {(titleProps: any) => {
                                    const { form } = titleProps;
                                    const { setFieldValue } = form;
                                    return (
                                      <TextField
                                        fullWidth
                                        onChange={(event) => {
                                          setFieldValue(
                                            "openingHour",
                                            event.target.value
                                          );
                                        }}
                                        name="openingHour"
                                        value={values.openingHour}
                                        inputProps={{ maxLength: 50 }}
                                        defaultValue={
                                          initialFormObj?.openingHour
                                        }
                                        key={initialFormObj?.openingHour}
                                      />
                                    );
                                  }}
                                </Field>
                              </FormControl>

                              <FormControl
                                className={classes.formControlMrtHelper}
                              >
                                <Typography fontSize={14} variant="subtitle2">
                                  Closing Hour
                                </Typography>
                                <Field name="closingHour">
                                  {(titleProps: any) => {
                                    const { form } = titleProps;
                                    const { setFieldValue } = form;
                                    return (
                                      <TextField
                                        fullWidth
                                        onChange={(event) => {
                                          setFieldValue(
                                            "closingHour",
                                            event.target.value
                                          );
                                        }}
                                        name="closingHour"
                                        value={values.closingHour}
                                        inputProps={{ maxLength: 50 }}
                                        defaultValue={
                                          initialFormObj?.closingHour
                                        }
                                        key={initialFormObj?.closingHour}
                                      />
                                    );
                                  }}
                                </Field>
                              </FormControl>
                            </div>
                          </Stack>
                        </FormControl>
                        {/* <FormControl className={classes.formControlMrtHelper}>
                          <Typography fontSize={14} variant='subtitle2'>
                            Operating Hours
                          </Typography>
                          <Field name='operatinghoursfields'>
                            {(titleProps: any) => {
                              const { form } = titleProps;
                              const { setFieldValue } = form;
                              return (
                                <TextareaAutosize onBlur={(event) => {setFieldValue('operatinghoursfields', event.target.value)}}
                                  className={classes.textAreaStyles}
                                  maxLength={500}
                                  defaultValue={values.operatinghoursfields}
                                  key={values?.operatinghoursfields}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name='operatinghoursfields'>
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl> */}
                        <FormControl className={classes.formControlMrtHelper}>
                          <Field name="category">
                            {(stateNameProperties: any) => {
                              const { form } = stateNameProperties;
                              const { setFieldValue } = form;
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <Typography variant="subtitle2">
                                    Category
                                  </Typography>
                                  <MultiSelect
                                    key={"category"}
                                    name="categoryName"
                                    showClearAll={true}
                                    labelKey="categoryName"
                                    options={categoryList}
                                    selectedOptions={
                                      (selectedCategory?.length &&
                                        selectedCategory.map(
                                          (state: any) => state.categoryName
                                        )) ||
                                      []
                                    }
                                    onCheckboxChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      handleCategoryChange(
                                        event,
                                        setFieldValue
                                      );
                                    }}
                                    onClearSelection={() => {
                                      setFieldValue("category", null);
                                      handleClearStateSelection(false);
                                    }}
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <ErrorMessage name="category">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      </Stack>
                    </div>
                  </Box>
                  <Divider />
                  <div className={classes.formButtonHelper}>
                    <Stack direction="row" spacing={2}>
                      <ButtonWithIcon
                        showCreateProgress={props.showCreateProgress}
                        type={CANCEL_TYPE}
                        label="Cancel"
                        onClick={() => {
                          if (confirm("You may lose your data.")) {
                            //   handleClose();
                            props.onRequestClose();
                          }
                        }}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        disableElevation
                      >
                        {(isEdit && "Update") || "Create"}
                      </Button>
                    </Stack>
                  </div>
                </Paper>
              </Paper>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CreateNewRetailerModal;
