import _ from "lodash";
import { RootState } from "../redux/reducers";
import { store } from "./../redux/store";
import { Media } from "../Common/UploadFile";

export const checkForDuplicates = (
  listOfData: any,
  condition: any,
  newValue?: string,
  oldValue?: string | undefined
) => {
  if (newValue && !oldValue) {
    /**create record case */
    if (listOfData.length === 0) {
      return false;
    } else if (_.find(listOfData, condition)) {
      return true;
    } else {
      return false;
    }
  } else {
    /**  edit record case */
    if (newValue === oldValue) {
      return false;
    } else if (_.find(listOfData, condition)) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkIfSystemNameIsEditable = (
  response: any,
  defaultPrimaryLanguageCode: string | undefined,
  smartGrowUniqueKeyNm: string
) => {
  // for draft record, we will not allow any editing of systemName
  let storeData: RootState = store.getState();

  const primaryLanguageRecord = _.filter(
    storeData.generic.ListOfLanguages.languageList,
    { languageCode: defaultPrimaryLanguageCode }
  );

  if (response.id !== null || response[smartGrowUniqueKeyNm] !== null) {
    if (
      primaryLanguageRecord[0].languageName.toLocaleLowerCase() ===
      response.languageName.toLocaleLowerCase()
    ) {
      return false;
    }
  }

  return true;
};

export const canAddRecord = (
  response: any,
  defaultPrimaryLanguageCode: string | undefined,
  smartGrowUniqueKeyNm: string
) => {
  if (response.id !== null || response[smartGrowUniqueKeyNm] !== null) {
    let storeData: RootState = store.getState();
    const primaryLanguageRecord = _.filter(
      storeData.generic.ListOfLanguages.languageList,
      { languageCode: defaultPrimaryLanguageCode }
    );
    if (response.id !== null || response[smartGrowUniqueKeyNm] !== null) {
      if (
        primaryLanguageRecord[0].languageName.toLocaleLowerCase() ===
        response.languageName.toLocaleLowerCase()
      ) {
        return true;
      }
    }
  }
  return false;
};

export const canUpdateRecord = (
  response: any,
  smartGrowUniqueKeyNm: string
) => {
  if (response.id !== null || response[smartGrowUniqueKeyNm] !== null) {
    return true;
  }

  return false;
};

export function clearImageObject(elementId: string): void {
  const element = document.getElementById(elementId);
  if (element && element.children.length > 0) {
    (element.children[0] as HTMLInputElement).value = "";
  }
}

export const createColumn = (fieldLabel: string, fieldName: string) => {
  return { fieldLabel, fieldName };
};

export const removeImageField = (
  index: number | undefined,
  currentMedia: Partial<Media>[] | undefined[] | undefined
) => {
  if (index !== undefined) {
    const newMedia = [...(currentMedia ?? [])];
    if (!newMedia[index]?.id) {
      newMedia.splice(index, 1);
    } else {
      newMedia[index] = {
        ...newMedia[index],
        path: "",
      };
    }
    return newMedia;
  }
  return currentMedia;
};
