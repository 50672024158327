import Axios, { AxiosResponse } from "axios";
import { BASE_URL } from "../Constants/contants";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import { SilentRequest } from "@azure/msal-browser";

// import { msalInstance as instance } from "../index";

Axios.defaults.baseURL = BASE_URL;

// Axios.interceptors.response.use(
//   function (res: any) {
//     console.log(res);
//     return res;
//   },
//   async (error) => {
//     //if(error.code === "401"){
//     console.log(error.config);
//     // const { instance } = useMsal();
//     let username: string = "sachin_fce.jondhale@syngenta.com";
//     let currentAccount: any = instance.getAccountByUsername(username);
//     let silentRequest: SilentRequest = {
//       scopes: ["Mail.Read"],
//       account: currentAccount,
//       forceRefresh: false,
//     };

//     let request: any = {
//       scopes: ["Mail.Read"],
//       loginHint: currentAccount?.username, // For v1 endpoints, use upn from idToken claims
//     };

//     const tokenResponse = await instance
//       .acquireTokenSilent(silentRequest)
//       .catch(async (error) => {
//         if (error instanceof InteractionRequiredAuthError) {
//           // fallback to interaction when silent call fails
//           return await instance.acquireTokenPopup(request).catch((error) => {
//             handleError(error);
//           });
//         }
//       });
//     console.log("tokenResponse", tokenResponse);
//     setBearerToken(tokenResponse?.idToken || "");
//     if (Number(Axios.defaults.headers.common["count"]) < 3)
//       return Axios.request(error.config);
//     //}
//   }
// );

export enum METHODS {
  METHOD_GET = "GET",
  METHOD_POST = "POST",
  METHOD_PUT = "PUT",
  METHOD_DELETE = "DELETE",
}

type METHOD_TYPE =
  | typeof METHODS.METHOD_GET
  | typeof METHODS.METHOD_POST
  | typeof METHODS.METHOD_PUT
  | typeof METHODS.METHOD_DELETE;

export const setBearerToken = (token: string) => {
  // Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  Axios.defaults.headers.common["count"] = Axios.defaults.headers.common[
    "count"
  ]
    ? String(Number(Axios.defaults.headers.common["count"]) + 1)
    : "1";
};

export const apiCall = (method: METHOD_TYPE, url: string, data?: any) => {
  // Axios.defaults.headers.common["Authorization"] = `Bearer ${testToken}`;
  return new Promise((resolve, reject) => {
    Axios({
      method,
      url,
      data,
    })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => reject(new Error("Error", { cause: err })));
  });
};
// function handleError(error: any) {
//   console.log("error");
//   throw new Error("Function not implemented.");
// }
