import { Box, Modal, Paper, Stack, Typography } from "@mui/material";
import { useStyles } from "../style";
import ModalHeader from "../../../Common/Modal/ModalHeader";

type ReadCount = {
    open: any,
    onClose: any,
    data: any
}

const ReadCount: React.FC<ReadCount> = ({ open, onClose, data }) => {
    const classes = useStyles();
    return (
        <Modal open={open} onClose={onClose}>
            <Paper className={classes.formPaperReadCount}>
                <Box>
                    <ModalHeader
                        header={`Read Count`}
                        onClose={onClose}
                    />
                    <Stack paddingX={2}>
                        <Typography variant="body1" marginTop={2}>
                            Read Count: {data.readCount || 0}
                        </Typography>
                        <Typography variant="body1" marginTop={2}>
                            Unread Count: {data.unreadCount || 0}
                        </Typography>
                        <Typography variant="body1" marginTop={2}>
                            Total Receiver Count: {data.totalCount || 0}
                        </Typography>
                    </Stack>
                </Box>
            </Paper>
        </Modal>
    );
};

export default ReadCount;
