import axios from "axios";
import api_endpoints, { base_url } from "../Api/end_points";
import { RootState } from "../redux/reducers";
import { store } from "../redux/store";
import showToast from "./showToast";
import { TextDecoder } from "util";

export type DownloadZipParamsType = {
  systemNames: string[];
  path: string;
  folder: string;
  onDownloadProgress?: (progressEvent: any) => void;
  onPreparingDownload?: () => void;
  onErrorDownload?: (message: string) => void;
};

const downloadZipFile = async ({
  systemNames,
  path,
  folder,
  onDownloadProgress,
  onPreparingDownload,
  onErrorDownload,
}: DownloadZipParamsType) => {
  const storeState: RootState = store.getState();

  const axiosInstance = axios.create({
    baseURL: base_url,
    headers: {
      ["Authorization"]: `Bearer ${storeState.auth.authToken}`,
      ["country-code"]: storeState.auth.countryCode ?? "IN",
      ["userType"]: "SR",
      ["language-code"]: storeState.auth.languageCode ?? "en",
    },
    timeout: 0,
  });

  const name = systemNames.join(",");

  const params = {
    path,
    name,
    folder,
  };

  try {
    if (onPreparingDownload) onPreparingDownload();

    const data = await axiosInstance.post(
      api_endpoints.download_zip,
      undefined,
      {
        params: params,
        responseType: "arraybuffer",
        onDownloadProgress: onDownloadProgress,
      }
    );

    if (data.status === 200) {
      const blob = new Blob([data.data], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${folder}.zip`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      const errorMessage = data.data?.errorMessage || "Unknown error occurred";
      if (onErrorDownload) onErrorDownload(errorMessage);
      showToast(`Failed to download zip file: ${errorMessage}`, "error");
    }
  } catch (error: any) {
    if (error.response?.data) {
      try {
        const uint8Array = new Uint8Array(error.response.data);
        const decodedError = uint8Array.reduce((str, byte) => str + String.fromCharCode(byte), '');
        const parsedError = JSON.parse(decodedError);

        const errorMessage = parsedError.errorMessage;
        if (onErrorDownload) onErrorDownload(errorMessage);
        showToast(`Failed to download zip file: ${errorMessage}`, "error");
      } catch (parseError) {
        console.error('Error parsing:', parseError);
        if (onErrorDownload) onErrorDownload("An unknown error occurred");
        showToast(`Failed to download zip file: An unknown error occurred`, "error");
      }
    } else {
      if (onErrorDownload) onErrorDownload("An unknown error occurred");
      showToast(`Failed to download zip file: An unknown error occurred`, "error");
    }
  }
};

export default downloadZipFile;
