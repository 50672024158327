import { api_service } from "../../../Api/api_service";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { IRequestBody } from "../../../Constants/interfaces";

/**
 * @description function triggers an api call to fetch list of Hybrid seeds
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */

export const GetHybridsDataList = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      return successCallBack(response.data, "hybrids_data_list");
    })
    .catch((err) => {
      return errorCallBack(err, "hybrids_data_list");
    });
};

export const SaveUpdateHybrid = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "save_update_hybrid");
    })
    .catch((err) => {
      errorCallBack(err, "save_update_hybrid");
    });
};

export const GetFilters = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "filters");
    })
    .catch((err) => {
      errorCallBack(err, "filters");
    });
};

export const ValidateBulkUploadData = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any,
  file: Blob | any
) => {
  api_service
    .post(data)
    ?.then((res) => successCallBack(res.data, "validate_bulk", file))
    .catch((err) => errorCallBack(err, "validate_bulk"));
};

export const GetMyUploadList = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "crops_upload_list");
    })
    .catch((err) => {
      errorCallBack(err, "crops_upload_list");
    });
};

export const GetErrorList = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((res) => successCallBack(res.data, "error_list"))
    .catch((err) => errorCallBack(err, "error_list"));
};

/**Will be removed when Hybrid API's are in live */
/**
 * @todo WHY THIS GetHybridDetails
 * @param data
 * @returns
 */
export const GetHybridDetails = (data: IRequestBody) => {
  return new Promise((resolve, reject) => {
    api_service
      .get(data)
      ?.then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(new Error("Error", { cause: err }));
      });
  });
};

export const DeleteHybridCrop = (
  apiData: IRequestBody,
  successCallBack: Function,
  errorCallBack: Function
) => {
  api_service
    .delete(apiData)
    ?.then((response) => {
      successCallBack(response.data, "delete_crop");
    })
    .catch((err) => {
      errorCallBack(err, "delete_crop");
    });
};

/** get states country wise */
export const GetStates = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "states");
    })
    .catch((err) => {
      errorCallBack(err, "states");
    });
};

/**
 * @todo WHY THIS
 * @param data
 * @returns
 *
/**Hybrid API's */

export const ViewHybridCrop = (data: IRequestBody) => {
  return new Promise((resolve, reject) => {
    api_service
      .get(data)
      ?.then((Response: any) => {
        return resolve(Response.data);
      })
      .catch((err: any) => {
        return reject(new Error("Error", { cause: err }));
      });
  });
};

/** Unique Hybrid System Name Validation */
export const CheckUniqueName = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        return successCallBack(response.data, "verify_hybrid_nm");
      }
      throw new Error(response.message);
    })
    .catch((err) => {
      errorCallBack(err, "verify_hybrid_nm");
    });
};
export const UniqueHybridSystemNameValidation = (
  systemName: string,
  languageCode: string,
  cropSysNm: string,
  doSysNmCheck: boolean,
  successCallBack: Function,
  errorCallBack: Function
) => {
  /** For Edit product scenerio,
   * the system name verification api call will not happen
   */
  if (doSysNmCheck) {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.verify_hybrid_sysnm
      .replace("__CRP_SYS_NM__", cropSysNm)
      .replace("__HYBRD_SYS_NM", systemName);
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": languageCode,
    };
    CheckUniqueName(apiData, successCallBack, errorCallBack);
  } else {
    return successCallBack();
  }
};
