const api_endpoints = {
  master_api_language: "/languages",
  add_language: "/language",
  update_language: "/language",
  crops: "/crops",
  crops_states: "/crops/cropstates",
  state_districts: "/address/geohierarchy/districts",
  notification_types: "/notification-types",
  app_config: "/api-config/urls",
  app_config_modules: "/api-config/modules",
  /** Push Notifications api end points */
  create_edit_notification: "/notification-config",
  sms_cost: "smscost",
  userCountController: "/user-notification/tokens",
  get_notifications_list: "/notification-configs",
  get_published_notifications_list: "/published-notification-configs",
  get_sent_notifications_list: "notification-history",
  publishable_data: "/publishable-data/__DATATYPE__",
  publish_selected_data: "/publish-data/__DATATYPE__",
  delete_publish: "/notification-configs",
  cancel_published: "/cancel-notification-configs",
  published_history: "/published-data/__DATATYPE__",
  download_sent_users: "/notification-log/export/",
  get_read_count: "/notification-log/read-count/",
  /** Crops api end points */
  crop_details: "/crops/__SYSTEM__NAME__/details",
  attribute_api: "/admin-portal-attribute/__ATTRIBUTE__KEY__",
  update_crop_status: "/crops/__SYSTEM__NAME__",
  save_crop: "/crops/crop-drafts",
  fileupload_S3bucket: "/file-upload",
  country_wise_states: "/address/geohierarchy/states",
  remove_img_list: "/files",
  download_zip: "/files/download-zip",
  myupload_list: "/bulk-upload",
  validate_bulk_upload: "/bulk-upload/validate",
  bulk_upload: "/bulk-upload",
  bulkupload_error_list: "/bulk-upload/__ID__/error-list",
  get_crop_stages: "/crops/__CROP_SYSTEM_NAME__/stages-calendars-threats",
  verify_crop_sysnm: "/crops/presence/__CROP_NAME__",
  crops_filters_list: "/crops/filters",
  crop_enrollments_options: "/crops/enrollments/options",
  /** query module end points */
  query_list: "/queries",
  media_upload: "/queries/media/upload",
  categories: "/queries/categories",
  query_reply: "/queries/__QUERY_ID__/reply",
  multiple_query_reply: "/queries/reply",
  query_details: "/queries/__QUERY_ID__",
  export: "/export",
  /** CP Products end points */
  get_cp_list: "/crops/cp-products",
  get_cp_upload_list: "/bulk-upload",
  get_cp_by_id: "",
  get_cp_by_sys_nm: "/crops/cp-products/__SYSTEM_NAME__/details",
  verify_cp_sysnm: "/crops/cp-products/presence/__CP_NAME__",
  save_update_cp: "/crops/cp-product-drafts",
  published_cp_details: "/crops/cp-products/__SYSTEM__NAME__/details",
  delete_published_cp: "/crops/cp-products/__PROD__SYS__NM__",
  /** Hybrid module endpoints */
  list_hybrids: "/crops/hybrids/seeds",
  save_update_hybrid: "/crops/hybrid-drafts",
  delete_hybrid: "/crops/hybrids/__HYBRID_SYSTEM_NAME__",
  view_hybrid_details:
    "/crops/hybrids/__CROP_SYSTEM_NAME__/seeds/__HYBRID_SYS_NM__/details",
  hybrid_filters: "/crops/filters",
  verify_hybrid_sysnm: "/crops/hybrids/__CRP_SYS_NM__/seeds/__HYBRD_SYS_NM",
  hybrid_by_draft_id: "/crops/hybrid-drafts/__HYBRID__DRAFT__ID__",
  /** Threats API endpoints */
  get_threat_list: "/crops/threats",
  get_published_threat_data: "/crops/threats/__CROP_SYSTEM_NAME__",
  get_draft_threat_data: "/crops/threat-drafts/__THREATDRAFTID__",
  save_draft_threats: "/crops/threat-drafts",
  // delete_threat:'',

  /** DashboardMedia API endpoints */
  get_dashboard_content: "/content-feed",
  create_dashboard_media: "/content-feed-draft",
  delete_dashboard_media: "/content-feed?feedId=__FEEDID__",
  view_dashboard_media: "/content-feed/__FEEDID__",
  get_draft_media_content: "/content-feed-draft/__MEDIA_ID__",

  myUploads_dashboard_list: "/bulk-upload",
  threats_presence_threat: "/crops/threats/presence/__CROP_SYSTEM_NAME__",
  /** Retailer & Nuseries API endpoints */
  stores: "/stores",
  retailer_info_draft: "/retailer-info-draft",
  retailer_taluka: "/address/geohierarchy/__stateId__/__districtId__/talukas",
  retailer_view_info_draft: "/stores/__storeId__",
  retailer_publish_info_draft: "retailer-info-draft/__storeId__",

  plantix_cp_product: "/plantix/cp-products",
  plantix_cp_product_detail: "/plantix/cp-products/__systemName__/details",
  plantix_cp_product_drafts: "/plantix/cp-product-drafts",
  plantix_cp_product_prensence: "/plantix/cp-products/presence/__systemName__",
  plantix_cp_product_delete: "/plantix/cp-products/__systemName__",

  plantix_cp_mapping: "/plantix/peatid/cp-products-mapping",
  plantix_cp_mapping_delete:
    "/plantix/peatid/cp-products-mapping/__systemName__",
  plantix_cp_mapping_detail:
    "/plantix/peatid/cp-products-mapping/__systemName__/details",
  plantix_cp_mapping_files_list: "/files/list",

  database_controller: "/databases",
};

export const base_url = process.env.REACT_APP_BASEURL!;
export const base_url_v2 = process.env.REACT_APP_BASEURL_V2!;
export default api_endpoints;
