import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormHelperText
} from "@mui/material";
import ButtonWithIcon, { CANCEL_TYPE, CREATE_TYPE } from "../../Buttons";
import { genericFormFieldValidation } from "../../../Utils/fileHelper";
import { ValidationType } from "../../Constants";

interface AddModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: ModalFormData) => void;
    type: string;
}

export interface ModalFormData {
    displayOrder: number;
    systemName: string;
    translationText: string;
}

const validateDisplayOrder = (value: string): string => {
    if (!value.trim()) {
        return "Display order is required";
    }

    const numValue = parseInt(value);

    if (isNaN(numValue)) {
        return "Display order must be a number";
    }

    if (numValue < 0) {
        return "Display order cannot be negative";
    }

    return "";
};

const validateTranslationText = (value: string): string => {
    if (!value.trim()) {
        return "Translation text is required";
    }

    return "";
};

const AddModal: React.FC<AddModalProps> = ({ isOpen, onClose, onSubmit, type }) => {
    const [displayOrder, setDisplayOrder] = useState<string>('');
    const [displayOrderError, setDisplayOrderError] = useState<string>('');

    const [systemName, setSystemName] = useState<string>('');
    const [systemNameError, setSystemNameError] = useState<string>('');

    const [translationText, setTranslationText] = useState<string>('');
    const [translationTextError, setTranslationTextError] = useState<string>('');

    const validateSystemName = (value: string) => {
        const error = genericFormFieldValidation(value, ValidationType.SYS_NM);
        setSystemNameError(error || '');
        return !error;
    };

    const handleSubmit = () => {
        const isSystemNameValid = validateSystemName(systemName);
        const displayOrderValidation = validateDisplayOrder(displayOrder);
        const translationTextValidation = validateTranslationText(translationText);

        setDisplayOrderError(displayOrderValidation);
        setTranslationTextError(translationTextValidation);

        if (
            isSystemNameValid &&
            !displayOrderValidation &&
            !translationTextValidation
        ) {
            onSubmit({
                systemName: systemName.trim().toLocaleUpperCase(),
                translationText: translationText.trim(),
                displayOrder: parseInt(displayOrder),
            });

            setDisplayOrder('');
            setDisplayOrderError('');
            setSystemName('');
            setSystemNameError('');
            setTranslationText('');
            setTranslationTextError('');
            onClose();
        }
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleSubmit();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{`Add ${type}`}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleFormSubmit}>
                    <TextField
                        label="Display Order"
                        type="number"
                        value={displayOrder}
                        onChange={(e) => {
                            setDisplayOrder(e.target.value);
                            setDisplayOrderError(validateDisplayOrder(e.target.value));
                        }}
                        fullWidth
                        margin="normal"
                        error={!!displayOrderError}
                        inputProps={{ min: 0 }}
                    />
                    {displayOrderError && (
                        <FormHelperText error>
                            {displayOrderError}
                        </FormHelperText>
                    )}

                    <TextField
                        label="System Name"
                        value={systemName}
                        onChange={(e) => {
                            const trimmedUppercase = e.target.value.trim().toLocaleUpperCase();
                            setSystemName(trimmedUppercase);
                            validateSystemName(trimmedUppercase);
                        }}
                        fullWidth
                        margin="normal"
                        error={!!systemNameError}
                    />
                    {systemNameError && (
                        <FormHelperText error>
                            {systemNameError}
                        </FormHelperText>
                    )}

                    <TextField
                        label="Translation Text"
                        value={translationText}
                        onChange={(e) => {
                            const text = e.target.value;
                            setTranslationText(text);
                            setTranslationTextError(validateTranslationText(text));
                        }}
                        fullWidth
                        margin="normal"
                        error={!!translationTextError}
                    />
                    {translationTextError && (
                        <FormHelperText error>
                            {translationTextError}
                        </FormHelperText>
                    )}

                    <DialogActions>
                        <ButtonWithIcon
                            type={CANCEL_TYPE}
                            label="Cancel"
                            onClick={onClose}
                        />
                        <ButtonWithIcon
                            type={CREATE_TYPE}
                            label="Submit"
                            onClick={handleSubmit}
                        />
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddModal;