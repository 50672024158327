import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useStyles } from "./styles";
import { useState } from "react";
import { IRequestBody } from "../../Constants/interfaces";
import api_endpoints, { base_url } from "../../Api/end_points";
import { api_service } from "../../Api/api_service";

type DatabaseControllerFields = {
  query: string;
  parameters: string;
  queryType: "READ" | "UPDATE_DELETE";
};

const INITIAL_VALUES: DatabaseControllerFields = {
  query: "",
  parameters: "",
  queryType: "READ",
};

const DatabaseController: React.FC = () => {
  const [result, setResult] = useState("");

  const classes = useStyles();

  const handleSubmit = (values: DatabaseControllerFields) => {
    const apiData = {} as IRequestBody;

    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.database_controller}/dml`;
    apiData.payLoad = [
      {
        query: values.query,
        parameters: values.parameters !== "" ? values.parameters : {},
        queryType: values.queryType,
      },
    ];

    api_service
      .post(apiData)
      ?.then((res) => {
        if (res.status === 500) {
          setResult(res.data.errorMessage);
        } else {
          const message = res.data[0]?.message;
          const result = message.replace(/result list:/i, "");
          const parsed = JSON.parse(result);
          const stringfy = JSON.stringify(parsed, null, 2);
          setResult(stringfy);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box className={classes.DatabaseControllerContainer}>
      <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form className={classes.FormContainer}>
            <Field
              name="query"
              validate={(value: string) => (!value ? "Query is required" : "")}
            >
              {() => (
                <FormControl className={classes.FieldInputContainer} required>
                  <Typography variant="subtitle2">Query *</Typography>
                  <TextareaAutosize
                    name="query"
                    onChange={(event) => {
                      setFieldValue("query", event.target.value);
                    }}
                    value={values.query}
                  />
                  <FormHelperText error={true} sx={{ margin: 0 }}>
                    <ErrorMessage name="query" />
                  </FormHelperText>
                </FormControl>
              )}
            </Field>

            <Field name="parameters">
              {() => (
                <FormControl className={classes.FieldInputContainer} required>
                  <Typography variant="subtitle2">Parameters</Typography>
                  <TextareaAutosize
                    name="parameters"
                    onChange={(event) => {
                      setFieldValue("parameters", event.target.value);
                    }}
                    value={values.parameters}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="queryType">
              {() => {
                return (
                  <FormControl className={classes.FieldInputContainer} required>
                    <Typography variant="subtitle2">Query Type</Typography>
                    <RadioGroup
                      row
                      name="queryType"
                      onChange={(event) => {
                        setFieldValue("queryType", event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="READ"
                        control={
                          <Radio checked={values.queryType === "READ"} />
                        }
                        label="READ"
                      />
                      <FormControlLabel
                        value="UPDATE_DELETE"
                        control={
                          <Radio
                            checked={values.queryType === "UPDATE_DELETE"}
                          />
                        }
                        label="UPDATE_DELETE"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            </Field>
            <Button
              type="submit"
              value="Submit"
              variant="contained"
              color="success"
              className={classes.SubmitButton}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <Typography variant="subtitle2" sx={{ paddingBlockStart: "1em" }}>
        Result
      </Typography>
      <pre className={classes.WrappedPre}>{result}</pre>
    </Box>
  );
};

export default DatabaseController;
