import { DOWNLOAD_SAMPLE_TEMPLATE_TYPE, PublishModuleDD } from "../../../Common/Constants";
import BaseModalCreatePlantix, { BaseModalProps } from "../../PlantixCPProducts/ModalCreatePopUp/BaseComponentCreate";
interface ModalCreatePopUpProps extends Omit<BaseModalProps, 'header' | 'templateLiteral' | 'validationLiteral' | 'dataType'> {
    typePlantix: 'map' | 'cp';
}

const ModalCreatePopUp2: React.FC<ModalCreatePopUpProps> = (props) => {
    const { typePlantix, ...restProps } = props;
    
    return (
        <BaseModalCreatePlantix
            {...restProps}
            header="Add New Data"
            templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.PLANTIX_MAP}
            validationLiteral={PublishModuleDD.PLANTIX_MAP_PRODUCTS}
            dataType={typePlantix === 'cp' ? "Plantix CP Products" : "Plantix Peatid Mapping"}
        />
    );
};

export default ModalCreatePopUp2;