import React, { createContext, useContext, useEffect, useMemo } from "react";
import PlantixCPProductsView from "./PlantixCPProducts.View";
import {
  ActionType,
  ErrorTableHeaders,
  PublishModuleDD,
} from "../../Common/Constants";
import { api_service } from "../../Api/api_service";
import api_endpoints, { base_url } from "../../Api/end_points";
import { IRequestBody } from "../../Constants/interfaces";
import { PlantixCPProductResponseObject } from "./types";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import KnowMore from "../../Common/KnowMoreErrorModal/knowMoreDetails";
import appActions from "../../redux/action";
import _ from "../../Utils/lodash";
import { formatDate } from "../../Utility/DateFormat";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import { checkExportToExcelAllowed } from "../../Api/generic_apicalls";
import downloadZipFile, {
  DownloadZipParamsType,
} from "../../Utils/downloadZip";
import { useDownloadProgressContext } from "../../Common/ProgressProvider";
import cropImageMedia from "../../Utils/cropMedia";
import useSharedState from "../../hooks/useStateShared";
import { useManageCheckbox } from "../../hooks/useManageCheckbox";

const { plantix_cp_product } = api_endpoints;

export const INITIAL_FILTER_STATE: filterObjType = {
  languageIds: [],
  status: [],
  crop: [],
  category: [],
};

export type filterObjType = {
  languageIds: any[];
  status: any[];
  crop: any[];
  category: any[];
};

type PlantixCPProductContextType = {
  selectedLangId: number;
  selectedSystemName: string;
  isEdit: boolean;
  isTranslate: boolean;
  fetchData: () => void;
  changeTabs: (tabs: number) => void;
};

const PlantixCPProductContext = createContext<PlantixCPProductContextType>({
  selectedLangId: 1,
  selectedSystemName: "",
  isEdit: false,
  isTranslate: false,
  fetchData: () => {},
  changeTabs: () => {},
});

export const usePlantixCPProduct = () => useContext(PlantixCPProductContext);

const PlantixCPProducts: React.FC = () => {
  const { addDownloadProgress } = useDownloadProgressContext();

  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      countryCode: state.auth.countryCode,
    };
  });
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = React.useState("");
  const [activeTab, setActiveTab] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [sortId, setSortId] = React.useState<"" | "0" | "1">("1");
  const [filterObj, setFilterObj] = React.useState(
    _.cloneDeep(INITIAL_FILTER_STATE)
  );
  const {
    currentPage,
    setCurrentPage,
    totalPages,
    setTotalPages,
    open,
    setOpen,
    actionType,
    setActionType,
    showCreateOrEditModal,
    setShowCreateOrEditModal,
    showBulkUpload,
    setShowBulkUpload,
    dataToDisplay,
    setDataToDisplay,
    dataToDisplayIsChecked,
    setDataToDisplayIsChecked,
  } = useSharedState();
  const [showViewModal, setShowViewModal] = React.useState(false);
  const [selectedSystemName, setSelectedSystemName] = React.useState("");
  const [selectedLangId, setSelectedLangId] = React.useState(1);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isTranslate, setIsTranslate] = React.useState(false);

  const [myUploadData, setMyUploadData] = React.useState<any | undefined>(
    undefined
  );
  const [openKnowMore, setOpenKnowMore] = React.useState<boolean>(false);
  const [errorList, setErrorList] = React.useState<Array<any> | undefined>(
    undefined
  );
  const {
    AssignCheckedFlag,
    filterDataBasedOnPageChecked,
    replaceDataAlreadyExistWithChecked,
    convertMatrictToArray,
  } = useManageCheckbox({
    currentPage,
    dataToDisplayIsChecked,
    setDataToDisplayIsChecked,
    setDataToDisplay,
  });

  const [buttonExportVisible, setButtonExportVisible] =
    React.useState<boolean>(false);

  const handleSearchValueChange = (newValue: string) => {
    setSearchValue(newValue);
  };

  const handleSelectAll = (status: boolean) => {
    AssignCheckedFlag(dataToDisplay, status);
  };

  const selectEachByRow = (check: boolean, rowData: any) => {
    checkOrUncheckRow(rowData, check);
  };

  //check or uncheck row
  const checkOrUncheckRow = (data: any, status: boolean) => {
    const filterData: any = dataToDisplay.map((isdata: any) => {
      if (
        isdata.productId === data.productId &&
        isdata.languageName === data.languageName
      ) {
        isdata.checked = status;
      }

      return isdata;
    });

    setDataToDisplay(filterData);
    filterDataBasedOnPageChecked(currentPage, filterData);
  };

  const getDetailsCpProduct = (data: any) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.plantix_cp_product_detail;
    apiData.endPoint = endPoint.replace("__systemName__", `${data.systemName}`);
    apiData.showLoader = true;
    /** payload */

    return new Promise((resolve, reject) => {
      api_service
        .get(apiData)
        ?.then((response: any) => {
          if (response.status === 500) {
            TriggerToastMessage("Unable to process your request", "error");
          }
          resolve(response.data.data);
        })
        .catch((error) => reject(new Error("Error", { cause: error })));
    });
  };

  // Export to excel
  const handleExportToExcel = async () => {
    const { data, dataFromOtherPage } = convertMatrictToArray();

    const selectedCpProductInfo = _.filter(data, { checked: true });
    const publishPendingStatus = selectedCpProductInfo.some(
      (value: any) => value.statusId === 5
    );

    if (publishPendingStatus) {
      const confirmationExport = confirm(
        "Exporting draft record for 'Publish Pending' item"
      );
      if (!confirmationExport) return;
    }

    if (dataFromOtherPage) {
      const confirmExport = confirm(
        "CP Product from different page are selected"
      );
      if (!confirmExport) return;
    }

    const allData = await Promise.all(
      selectedCpProductInfo.map(getDetailsCpProduct)
    );

    if (selectedCpProductInfo.length === 0) {
      return TriggerToastMessage(
        "Please select atleast one line to export",
        "warn"
      );
    }

    if (selectedCpProductInfo.length > 0) {
      const path = `admin-portal/${storeData.countryCode}/plantixcp/`;
      const name = selectedCpProductInfo
        .map((data: any) => data.systemName)
        .filter(
          (systemName: string, index: number, self: string[]) =>
            index === self.indexOf(systemName)
        );
      const folder = "plantixcp";

      const MAX_RETRY_COUNT = 3;

      const downloadWithRetry = (
        params: DownloadZipParamsType,
        retryCount: number = 0
      ) => {
        downloadZipFile({
          ...params,
          onErrorDownload: (message) => {
            addDownloadProgress({
              module: "Plantix CP Products",
              downloadName: selectedCpProductInfo
                .map((data: any) => data.systemName)
                .filter(
                  (systemName: string, index: number, self: string[]) =>
                    index === self.indexOf(systemName)
                )
                .join(", "),
              status: "Error",
              message: message ?? "Something went wrong",
              canRefetch: true,
              onRefetchCallback: () => {
                if (retryCount < MAX_RETRY_COUNT) {
                  downloadWithRetry(params, retryCount + 1);
                } else {
                  addDownloadProgress({
                    module: "Plantix CP Products",
                    downloadName: selectedCpProductInfo
                      .map((data: any) => data.systemName)
                      .filter(
                        (systemName: string, index: number, self: string[]) =>
                          index === self.indexOf(systemName)
                      )
                      .join(", "),
                    status: "Error",
                    message: `Maximum numbers of ${MAX_RETRY_COUNT} retries has been reached`,
                    canRefetch: false,
                  });
                }
              },
            });
          },
        });
      };

      downloadWithRetry({
        systemNames: name,
        path,
        folder,
        onDownloadProgress: (ProgressEvent: any) => {
          const totalDownloaded = ProgressEvent.loaded;
          const totalSize = ProgressEvent.total;
          const progress = Math.round((totalDownloaded / totalSize) * 100);

          if (ProgressEvent.target?.status === 200) {
            addDownloadProgress({
              module: "Plantix CP Products",
              downloadName: selectedCpProductInfo
                .map((data: any) => data.systemName)
                .filter(
                  (systemName: string, index: number, self: string[]) =>
                    index === self.indexOf(systemName)
                )
                .join(", "),
              totalDownloaded,
              totalSize,
              progress,
              status: "Downloading",
            });
          }
        },
        onPreparingDownload: () => {
          addDownloadProgress({
            module: "Plantix CP Products",
            downloadName: selectedCpProductInfo
              .map((data: any) => data.systemName)
              .filter(
                (systemName: string, index: number, self: string[]) =>
                  index === self.indexOf(systemName)
              )
              .join(", "),
            status: "Preparing",
            message: "Preparing to Download Zip File",
          });
        },
      });
    }

    setTimeout(() => {
      const columnsData = [
        {
          label: "CP SystemName",
          value: "productSystemName",
        },
        {
          label: "Crop",
          value: "payload.applicableCrop",
        },
        {
          label: "Threat",
          value: "payload.threat",
        },
        {
          label: "Category",
          value: "payload.category",
        },
        {
          label: "Language",
          value: "languageName",
        },
        {
          label: "ProductName",
          value: "payload.productTitle",
        },
        {
          label: "Image Path",
          value: (row: any) => cropImageMedia(row.payload.imagePath, storeData),
        },
        {
          label: "dosage",
          value: "payload.dosage",
        },
        {
          label: "HowToUse",
          value: "payload.howToUse",
        },
        {
          label: "WhereToUse",
          value: "payload.whenToUse",
        },
        {
          label: "Key Benefit 1",
          value: "payload.benefit1",
        },
        {
          label: "Key Benefit 2",
          value: "payload.benefit2",
        },
        {
          label: "Key Benefit 3",
          value: "payload.benefit3",
        },
      ];
      const dataObj = [
        {
          columns: columnsData,
          content: allData,
          sheet: "General Info",
        },
      ];
      let settings = {
        fileName: "Plantix CP Product_" + new Date().getTime(), // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };
      ConvertJSONToXLS(dataObj, settings);
    }, 500);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSortId("1");
    setActiveTab(newValue);
    setFilterObj(INITIAL_FILTER_STATE);
    setSearchValue("");
    setCurrentPage(0);
    setDataToDisplayIsChecked([]);
  };

  const handleClickAction = (clickAction: string, details?: any) => {
    setActionType(clickAction);
    switch (clickAction) {
      case ActionType.CREATE:
        handleOpenModal();
        break;
      case ActionType.VIEW:
        setSelectedLangId(details?.languageId);
        setSelectedSystemName(details?.systemName);
        setShowViewModal(true);
        break;
      case ActionType.UPDATE:
        setShowCreateOrEditModal(true);
        setSelectedLangId(details?.languageId);
        setSelectedSystemName(details?.systemName);
        setIsTranslate(false);
        setIsEdit(!!details?.systemName);
        break;
      case ActionType.DELETE:
        if (
          confirm(
            "Are you sure want to delete? \n Plantix Mapping will also get deleted"
          )
        )
          deletePublishedData(details?.languageId, details?.systemName);
        break;
      case ActionType.ADD_TRANSLATION:
        setShowCreateOrEditModal(true);
        setSelectedLangId(details?.languageId);
        setSelectedSystemName(details?.systemName);
        setIsEdit(false);
        setIsTranslate(!!details?.systemName);
        break;
      default:
        handleExportToExcel();
        return;
    }
  };

  const handleKnowMorePopUp = (flag: boolean, rowData: any) => {
    getErrorListData(rowData.id);

    setMyUploadData(rowData);

    setOpenKnowMore(flag);
  };

  const getErrorListData = async (id: number) => {
    try {
      const apiData: IRequestBody = {
        domain: base_url,
        endPoint: api_endpoints.bulkupload_error_list.replace(
          "__ID__",
          `${id}`
        ),
        showLoader: true,
      };

      const res = await api_service.get(apiData);
      if (res.status === 200) {
        setErrorList(res.data.data);
      }
    } catch (error) {
      TriggerToastMessage("Unable to process your request", "error");
    }
  };

  const handleOpenModal = () => {
    setIsEdit(false);
    setIsTranslate(false);
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);

  const handleShowCreateOrEditModal = (toggle: boolean) => {
    if (!toggle) {
      // TODO
    }
    setActionType(ActionType.CREATE);
    setOpen(false);
    setShowCreateOrEditModal(toggle);
  };

  const handleShowBulkUpload = (toggle: boolean) => {
    setOpen(false);
    setShowBulkUpload(toggle);
  };

  const fetchDataList = async () => {
    dispatch(appActions.generic_actions.showLoader(true));

    try {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      apiData.endPoint = plantix_cp_product;
      apiData.payLoad = {
        pageNo: currentPage,
        pageSize: limit,
      };

      if (filterObj.languageIds?.length > 0) {
        apiData.payLoad.languageIds = filterObj.languageIds
          .map((filterLngObj: any) => {
            return filterLngObj.split(":::")[1];
          })
          .join(",");
      }

      if (searchValue) {
        apiData.payLoad.term = searchValue.toLocaleLowerCase();
      }

      if (sortId) {
        apiData.payLoad.sort = sortId === "1" ? "desc" : "asc";
      }

      apiData.showLoader = true;

      const res = await api_service.get(apiData);
      const listData: PlantixCPProductResponseObject = res.data.data;
      const data: any[] = listData.productsDtoList.map((item) => ({
        languageId: item.languageId,
        languageName: item.languageName,
        lastUpdatedOn: item.lastUpdatedOn
          ? formatDate(item.lastUpdatedOn)
          : null,
        productCategory: item.productCategory,
        productId: item.productId,
        productMediaPath: item.productMediaPath,
        systemName: item.productSystemName,
        crop: item.productTitle,
        statusId: item.statusId,
      }));
      setTotalPages(listData.totalPages);
      setDataToDisplay(data);
    } catch (error) {
      TriggerToastMessage("unable to process your request", "error");
    } finally {
      dispatch(appActions.generic_actions.showLoader(false));
    }
  };

  const fetchMyUploads = () => {
    dispatch(appActions.generic_actions.showLoader(true));

    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.bulk_upload;
    apiData.payLoad = {
      dataType: PublishModuleDD.PLANTIX_CP_PRODUCTS,
      pageNo: currentPage,
      pageSize: limit,
      sortId: parseInt(sortId),
    };

    if (searchValue) {
      apiData.payLoad.searchKey = searchValue.toLocaleLowerCase();
    }

    api_service
      .get(apiData)
      ?.then((res) => {
        const listData = res.data.data;
        const data = listData.uploadDocumentList?.map((item: any) => {
          return {
            id: item.id,
            fileName: item.fileName,
            cropsUploadDate: formatDate(item.createdDate, "YYYY/MM/DD hh:mm a"),
            comnMyUploadStatus: item.status,
          };
        });

        setTotalPages(Math.ceil(listData.totalElements / listData.pageSize));
        setDataToDisplay(data);
      })
      .catch(() => {
        TriggerToastMessage("unable to process your request", "error");
      })
      .finally(() => dispatch(appActions.generic_actions.showLoader(false)));
  };

  const deletePublishedData = (langId: number, systemName: string) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.plantix_cp_product_delete.replace(
      "__systemName__",
      systemName
    );

    const langCode =
      storeData.languages.find((lang) => lang.languageId === langId)
        ?.languageCode ?? "en";

    apiData.customHeaders = {
      "language-code": langCode,
    };

    api_service
      .delete(apiData)
      .then(() => {
        TriggerToastMessage("Deleted Succesfully", "success");
        fetchData();
      })
      .catch(() => {
        TriggerToastMessage("Unable to process your request", "error");
      });
  };

  useEffect(() => {
    if (currentPage === 0) {
      fetchData();
    } else {
      setCurrentPage(0);
    }
  }, [limit, searchValue, filterObj, sortId, activeTab]);

  useEffect(() => {
    setDataToDisplay([]);
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (dataToDisplay.length < 1) {
      replaceDataAlreadyExistWithChecked();
    }
  }, [currentPage, dataToDisplay]);

  useEffect(() => {
    checkExportToExcelAllowed("Plantix CP Products")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, [activeTab]);

  const fetchData = () => {
    setDataToDisplay([]);

    switch (activeTab) {
      case 0:
        // datalist
        fetchDataList();
        break;
      case 1:
        // myupload
        fetchMyUploads();
        break;
      default:
        break;
    }
  };

  const valuePlantixContext = useMemo(
    () => ({
      selectedLangId,
      selectedSystemName,
      isEdit,
      isTranslate,
      fetchData,
      changeTabs: (tabs: any) => handleChange(null as any, tabs),
    }),
    [
      selectedLangId,
      selectedSystemName,
      isEdit,
      isTranslate,
      fetchData,
      handleChange,
    ]
  );

  return (
    <PlantixCPProductContext.Provider value={valuePlantixContext}>
      <PlantixCPProductsView
        searchValue={searchValue}
        activeTab={activeTab}
        limit={limit}
        sortId={sortId}
        filterObj={filterObj}
        currentPage={currentPage}
        totalPages={totalPages}
        open={open}
        showCreateOrEditModal={showCreateOrEditModal}
        actionType={actionType}
        showBulkUpload={showBulkUpload}
        onSelectAll={handleSelectAll}
        onRowSelect={selectEachByRow}
        dataToDisplay={dataToDisplay}
        showViewModal={showViewModal}
        onSearchValueChange={handleSearchValueChange}
        onChange={handleChange}
        onClickAction={handleClickAction}
        onKnowMorePopUp={handleKnowMorePopUp}
        onCloseModal={handleCloseModal}
        onShowCreateOrEditModal={handleShowCreateOrEditModal}
        onShowBulkUpload={handleShowBulkUpload}
        setLimit={setLimit}
        setSortId={setSortId}
        setFilterObj={setFilterObj}
        setCurrentPage={setCurrentPage}
        setShowViewModal={setShowViewModal}
        buttonExportVisible={buttonExportVisible}
      />
      {openKnowMore && (
        <KnowMore
          handleKnowMoreClose={(flag: boolean) => {
            setOpenKnowMore(flag);
            setMyUploadData(undefined);
          }}
          title={myUploadData && myUploadData?.fileName}
          tableHeaders={ErrorTableHeaders}
          tableData={errorList}
          openBulkUploadPopup={() => {
            setShowBulkUpload(true);
            setOpenKnowMore(false);
          }}
        />
      )}
    </PlantixCPProductContext.Provider>
  );
};

export default PlantixCPProducts;
