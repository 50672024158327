import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  CropCalendarTypes,
  EnrollmentOptionPayload,
  PracticeAndDurationList,
  initialCropCalendar,
} from ".";
import ButtonWithIcon, {
  CANCEL_TYPE,
  CREATE_TYPE,
} from "../../../Common/Buttons";
import SortableComponent from "../../../Common/SortableRecords";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { CropCalendarSchemaValidation } from "../CropValidationSchema";
import { useStyles } from "../useStyle";
import { ActionType, STEPS, ValidationType } from "../../../Common/Constants";
import { genericFormFieldValidation } from "../../../Utils/fileHelper";
import getRandomizeValue from "../../../Utils/randomizer";
import RenderField from "../../../Common/RenderField";
import AddModal, {
  ModalFormData,
} from "../../../Common/Modal/PracticeDurationModal";
import { IRequestBody } from "../../../Constants/interfaces";
import { getPracticeDurationEnabled } from "../CropsAPICalls";

type CropCalendarFormProps = {
  ListOfCropCalendar: Array<CropCalendarTypes>;
  selectedCalendar: CropCalendarTypes;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  updateListOrder: Function;
  isEdit: boolean;
  actionType: string;
  isPrimaryCrop: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
  farmingPracticeList: PracticeAndDurationList[];
  cropDurationList: PracticeAndDurationList[];
  onEnrollmentOptionSubmit: (
    data: EnrollmentOptionPayload,
    type: string
  ) => any;
};

export const CropCalenderForm: React.FC<CropCalendarFormProps> = (
  props: CropCalendarFormProps
) => {
  const classes = useStyles();
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [isPracticeDurationEnabled, setIsPracticeDurationEnabled] = useState<
    boolean | null
  >();

  const handleModalSubmit = async (data: ModalFormData) => {
    const success = props.onEnrollmentOptionSubmit(data, modalType);
    if (success) {
      setIsModalOpen(false);
    }
  };

  const renderCropCalendarFormStages = () => {
    return (
      <SortableComponent
        name={"Crop Calendar"}
        items={props.ListOfCropCalendar}
        updateCropStageOrder={(newList: Array<CropCalendarTypes>) => {
          props.updateListOrder(newList);
        }}
        key={props.ListOfCropCalendar.length}
        listProps={props}
        displayKey={"calendarName"}
        initialState={initialCropCalendar}
        selectedRecord={props.selectedCalendar}
        isDisabled={
          !props.isPrimaryCrop && props.actionType !== ActionType.CREATE
        }
      />
    );
  };
  const checkPracticeDurationEnable = async () => {
    try {
      const response: any = await getPracticeDurationEnabled();
      setIsPracticeDurationEnabled(response.data === "true");
    } catch (error) {
      console.error("Failed to fetch practice duration feature status:", error);
      setIsPracticeDurationEnabled(false);
    }
  };

  useEffect(() => {
    checkPracticeDurationEnable();
  }, []);

  /** Getting latest unique key to see latest
   * selected record for edit
   */

  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedCalendar.calendarName]);

  return (
    <>
      <Box>
        <Box className={classes.formContainer}>
          <div className={classes.formContainerChildDivHelper}>
            <Stack direction="row" spacing={2}>
              <Formik
                validationSchema={CropCalendarSchemaValidation}
                enableReinitialize={true}
                initialValues={{
                  generic: props.selectedCalendar.generic,
                  calendarName: props.selectedCalendar.calendarName,
                  practice: props.selectedCalendar.practice,
                  duration: props.selectedCalendar.duration,
                  isDosApplicable: props.selectedCalendar.isDosApplicable,
                  isDotApplicable: props.selectedCalendar.isDotApplicable,
                }}
                onSubmit={(values: any, _onSubmitProps: any) => {
                  if (
                    checkForDuplicates(
                      props.ListOfCropCalendar,
                      { calendarName: values!.calendarName },
                      values!.calendarName,
                      props.selectedCalendar.calendarName
                    )
                  ) {
                    _onSubmitProps.setFieldError(
                      "calendarName",
                      "Duplicate system name"
                    );
                    _onSubmitProps.setSubmitting(false);
                    return;
                  } else {
                    props.onSave(values);
                    _onSubmitProps.resetForm();
                    props.updateUniqueKey();
                    setIsRuleErrorFlag(false);
                  }
                }}
              >
                {({ values }) => (
                  <Form>
                    <Stack
                      flex={1}
                      justifyContent="flex-end"
                      borderRight="1px solid #C2C7D0"
                      paddingRight={2}
                      minWidth={350}
                    >
                      <Field name="generic" id="generic">
                        {(_props: any) => {
                          const { form } = _props;
                          const { setFieldValue } = form;
                          return (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="subtitle2"
                                id="demo-row-radio-buttons-group-label"
                              >
                                is Generic? *
                              </Typography>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="generic-radio-buttons"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "generic",
                                      e.target.value === "true"
                                    );
                                  }}
                                  key={props.uniqueKey}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio size="small" value={true} />
                                    }
                                    label="Yes"
                                    checked={values?.generic === true}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio size="small" value={false} />
                                    }
                                    label="No"
                                    checked={values?.generic === false}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="generic" />
                      </FormHelperText>
                      <Field
                        name="calendarName"
                        id="calendarName"
                        validate={(value: string) =>
                          genericFormFieldValidation(
                            value,
                            ValidationType.SYS_NM,
                            Boolean(props.selectedCalendar.calendarName)
                          )
                        }
                      >
                        {(_props: any) => {
                          const { form } = _props;
                          const { setFieldValue } = form;
                          return (
                            <FormControl fullWidth>
                              <Typography variant="subtitle2">
                                Calendar System Name *
                              </Typography>
                              <TextField
                                fullWidth
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue(
                                    "calendarName",
                                    event.target.value.trim().toUpperCase()
                                  );
                                }}
                                defaultValue={values?.calendarName}
                                value={values?.calendarName}
                                name={"calendarName"}
                                key={props.uniqueKey}
                                disabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedCalendar.calendarName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="calendarName" />
                      </FormHelperText>

                      <Field name="practice" id="practice">
                        {(_props: any) => {
                          const { form } = _props;
                          const { setFieldValue } = form;
                          return (
                            <FormControl fullWidth>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ marginTop: 1, marginBottom: 0 }}
                                >
                                  Farming Practice *
                                </Typography>
                                {isPracticeDurationEnabled && (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      color: "#0668d9",
                                      borderColor: "#fff",
                                    }}
                                    onClick={() => {
                                      setModalType("Practice");
                                      setIsModalOpen(true);
                                    }}
                                  >
                                    Add Practice
                                  </Button>
                                )}
                              </Stack>
                              <RenderField
                                name="code"
                                label=""
                                labelKey="code"
                                value={
                                  values.practice ? `${values.practice}` : ""
                                }
                                options={props.farmingPracticeList}
                                onChange={(name: string, selectedType: any) => {
                                  setFieldValue("practice", selectedType.label);
                                }}
                                key={props.uniqueKey}
                                renderItems={(option: any) => (
                                  <MenuItem value={option}>
                                    <Typography
                                      sx={{ lineHeight: "unset" }}
                                      variant="body1"
                                      fontSize={14}
                                    >
                                      {option.code} ({option.label})
                                    </Typography>
                                  </MenuItem>
                                )}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>

                      <FormHelperText error={true}>
                        <ErrorMessage name="practice" />
                      </FormHelperText>

                      <Field name="duration" id="duration">
                        {(_props: any) => {
                          const { form } = _props;
                          const { setFieldValue } = form;
                          return (
                            <FormControl fullWidth>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ marginTop: 1, marginBottom: 0 }}
                                >
                                  Crop Duration *
                                </Typography>
                                {isPracticeDurationEnabled && (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      color: "#0668d9",
                                      borderColor: "#fff",
                                    }}
                                    onClick={() => {
                                      setModalType("Duration");
                                      setIsModalOpen(true);
                                    }}
                                  >
                                    Add Duration
                                  </Button>
                                )}
                              </Stack>
                              <RenderField
                                name="code"
                                label=""
                                labelKey="code"
                                value={
                                  values.duration ? `${values.duration}` : ""
                                }
                                options={props.cropDurationList}
                                onChange={(name: string, selectedType: any) => {
                                  setFieldValue("duration", selectedType.label);
                                }}
                                key={props.uniqueKey}
                                renderItems={(option: any) => (
                                  <MenuItem value={option}>
                                    <Typography
                                      sx={{ lineHeight: "unset" }}
                                      variant="body1"
                                      fontSize={14}
                                    >
                                      {option.code} ({option.label})
                                    </Typography>
                                  </MenuItem>
                                )}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>

                      <FormHelperText error={true}>
                        <ErrorMessage name="duration" />
                      </FormHelperText>
                      <Field name="isDosApplicable" id="isDosApplicable">
                        {(_props: any) => {
                          const { form } = _props;
                          const { setFieldValue } = form;
                          return (
                            <Stack>
                              <Typography
                                variant="subtitle2"
                                id="demo-row-radio-buttons-group-label"
                              >
                                Date Of Sowing *
                              </Typography>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="isDosApplicable-radio-buttons-group"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "isDosApplicable",
                                      e.target.value === "true"
                                    );
                                  }}
                                  key={props.uniqueKey}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio size="small" value={true} />
                                    }
                                    label="Applicable"
                                    checked={values?.isDosApplicable === true}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio size="small" value={false} />
                                    }
                                    label="Not Applicable"
                                    checked={values?.isDosApplicable === false}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          );
                        }}
                      </Field>
                      {/* <FormHelperText error={true} >
                        <ErrorMessage name='isDosApplicable' />
                      </FormHelperText> */}
                      <Field name="isDotApplicable" id="isDotApplicable">
                        {(_props: any) => {
                          const { form } = _props;
                          const { setFieldValue } = form;
                          return (
                            <Stack>
                              <Typography
                                variant="subtitle2"
                                id="demo-row-radio-buttons-group-label"
                              >
                                Date Of Transplant *
                              </Typography>
                              <FormControl>
                                <RadioGroup
                                  id="radio-button-crop-calendar"
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "isDotApplicable",
                                      e.target.value === "true"
                                    );
                                  }}
                                  key={props.uniqueKey}
                                >
                                  <FormControlLabel
                                    value={true}
                                    control={
                                      <Radio size="small" value={true} />
                                    }
                                    label="Applicable"
                                    checked={values?.isDotApplicable === true}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    control={
                                      <Radio size="small" value={false} />
                                    }
                                    label="Not Applicable"
                                    checked={values?.isDotApplicable === false}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="isDotApplicable" />
                      </FormHelperText>
                      <Stack
                        alignSelf="self-end"
                        direction="row"
                        spacing={2}
                        my={2}
                      >
                        <Button
                          disabled={
                            !(props.selectedCalendar.calendarName
                              ? props.canUpdateExistingRecord
                              : props.canAddNewRecord)
                          }
                          type="submit"
                          variant="contained"
                          color="success"
                        >
                          {(props.selectedCalendar?.calendarName &&
                            "Update Calender") ||
                            "Add Calender"}
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                )}
              </Formik>
              <AddModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleModalSubmit}
                type={modalType}
              />
              <Stack flex={1}>
                <Box>
                  <Typography variant="subtitle2">
                    Added Crop Calendar(s):
                  </Typography>
                  <Typography variant="caption" component="span">
                    You may reorder the stages by dragging each panel up or down
                  </Typography>
                  {renderCropCalendarFormStages()}
                  <FormHelperText error={true}>
                    {isRuleErrorFlag && (
                      <>Create at least one record to proceed</>
                    )}
                  </FormHelperText>
                </Box>
              </Stack>
            </Stack>
          </div>
        </Box>
        <>
          <Divider />
          <div className={classes.modalFooterActionBtnsHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CANCEL_TYPE}
                label="Previous"
                onClick={() => {
                  props.onPrevious(STEPS.STEP3);
                }}
              />
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                label="Next"
                onClick={() => {
                  props.onNext(STEPS.STEP5);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
