import { useState, useEffect } from "react"
import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import { useStyles } from "../style";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { IRequestBody } from "../../../Constants/interfaces";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { api_service } from "../../../Api/api_service";

const BaseLabelPreview = [
    {
        fieldName: "cropName",
        fieldLabel: "Crop",
    },
    {
        fieldName: "diseaseName",
        fieldLabel: "Disease Name",
    },
    {
        fieldLabel: "Eppo Code",
        fieldName: "eppoCode",
    },
    {
        fieldLabel: "Peat ID",
        fieldName: "peatId",
    },
    {
        fieldName: "productSystemName",
        fieldLabel: "CP System Name",
    },
];

const AdditionalLabelPreview = [
    {
        fieldName: "childDiseaseName",
        fieldLabel: "Child Disease Name",
    },
    {
        fieldName: "childDiseaseDisplayOrder",
        fieldLabel: "Child Disease Display Order",
    },
    {
        fieldName: "childDiseaseImageUrl",
        fieldLabel: "Child Disease Image URL",
        isImage: true
    },
    {
        fieldName: "languageName",
        fieldLabel: "Language",
    },
];

const ViewPlantixMapProduct: React.FC<any> = (props) => {
    const classes = useStyles();
    const [data, setData] = useState<any>({});
    const LabelPreview = [
        ...BaseLabelPreview,
        ...(props.showAdditionalColumns ? AdditionalLabelPreview : [])
    ];

    useEffect(() => {
        if (props.selectedId) {
            const apiData = {} as IRequestBody;
            apiData.domain = base_url;

            apiData.payLoad = {
                application: "cropwise"
            }

            if (props.isOnPublish) {
                apiData.payLoad = {
                    application: "admin-portal"
                }
            }

            apiData.endPoint = api_endpoints.plantix_cp_mapping_detail.replace("__systemName__", props.selectedId);

            api_service.get(apiData)?.then((res) => {
                setData({
                    ...res.data.data.payload
                })
            }).catch((err) => console.log(err))
        }
    }, [props.selectedId])

    const renderFieldValue = (item: any) => {
        if (item.isImage && data[item.fieldName]) {
            return (
                <img
                    alt={'child-disease'}
                    src={data[item.fieldName]}
                    width="35px"
                />
            )
        }
        return (
            <Typography
                fontSize={14}
                className={classes.testWrapControl}
            >
                {data[item.fieldName]}
            </Typography>
        );
    };

    return (
        <Modal
            open={true}
            disableEscapeKeyDown={true}
            className={classes.modalStylesHelper}
        >
            <Paper className={classes.formPaper}>
                <Paper elevation={3} square>
                    <ModalHeader
                        header={`${data.cropName} - Mapped Products`}
                        showDelete={false}
                        onClose={() => { props.onClose() }}
                    />
                </Paper>

                <Box className={classes.formContainer}>
                    <Box className={classes.formFieldsHelper}>
                        {LabelPreview.map((item, index) => (
                            <div key={index}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ paddingTop: "6px" }}
                                >
                                    {item.fieldLabel}
                                </Typography>
                                {renderFieldValue(item)}
                                <Divider />
                            </div>
                        ))}
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
}

export default ViewPlantixMapProduct;