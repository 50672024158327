import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { ContentCopy, Check } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { useStyles } from '../style';
import { ModuleImageUpload } from '../../../../Common/Constants';
import { TriggerToastMessage } from '../../../../Utils/toastTrigger';
import { getFileUploadPayload } from '../../../../Api/generic_apicalls';
import { UploadFile } from '../../../../Common/UploadFile';

interface ImageUploadModalProps {
    open: boolean;
    onClose: () => void;
}

interface FormValues {
    imagePath: string;
}

const ImageUploadModal: React.FC<ImageUploadModalProps> = ({ open, onClose }) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [submittedPath, setSubmittedPath] = useState<string>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const classes = useStyles();

    const handleUploadSuccess = (response: any) => {
        if (response.statusCode === 201) {
            setSubmittedPath(response.data.imageUrl);
            setIsSubmitted(true);
            setIsUploading(false);
            TriggerToastMessage('Image uploaded successfully!', 'success');
        }
    };

    const handleUploadError = (error: any) => {
        setIsUploading(false);
        TriggerToastMessage('Failed to upload file. Please try again.', 'error');
    };

    const handleSubmit = (values: FormValues) => {
        if (!values.imagePath) {
            TriggerToastMessage('Please select an image to upload', 'error');
            return;
        }

        setIsUploading(true);

        getFileUploadPayload(
            values.imagePath,
            '',
            ModuleImageUpload.PLANTIX_SUB_DISEASE,
            handleUploadSuccess,
            handleUploadError
        );
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(submittedPath);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
            TriggerToastMessage('Failed to copy to clipboard', 'error');
        }
    };

    const handleClose = () => {
        onClose();
        setIsSubmitted(false);
        setSubmittedPath('');
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.modalImageStyle}>
                <Typography variant="h6" gutterBottom>
                    Upload Image
                </Typography>

                <Box className={classes.modalImageContent}>
                    {!isSubmitted ? (
                        <Formik
                            initialValues={{ imagePath: '' }}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form className={classes.modalImageFormContainer}>
                                    <UploadFile
                                        data-testid="upload-input"
                                        isRequired
                                        name="imagePath"
                                        acceptedFileTypes={[
                                            "image/png",
                                            "image/jpg",
                                            "image/jpeg"
                                        ]}
                                        maxFileSize={5}
                                        imageUrl={values.imagePath}
                                        onChange={(data: string) => setFieldValue("imagePath", data)}
                                        canRemoveOrReplace={true}
                                    />

                                    {values.imagePath && (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            disabled={isUploading}
                                        >
                                            {isUploading ? 'Uploading...' : 'Submit'}
                                        </Button>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <Box>
                            <Typography variant="body2" gutterBottom>
                                Image uploaded successfully!
                            </Typography>
                            <Box>
                                <Typography variant="body2">
                                    {submittedPath}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={copyToClipboard}
                                    startIcon={isCopied ? <Check /> : <ContentCopy />}
                                >
                                    {isCopied ? 'Copied!' : 'Copy'}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box className={classes.modalImageFooter}>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ImageUploadModal;