import { Box, Modal, Paper } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { StateListType } from "../../../Common/Constants/types";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import StepperComponent from "../../../Common/Stepper";
import { IRequestBody } from "../../../Constants/interfaces";
import { RootState } from "../../../redux/reducers";
import { LanguageRecordType } from "../../../redux/reducers/generic_reducer";
import { logger } from "../../../Utils/logger";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { GetCropsDataList } from "../../Crop/CropsAPICalls";
import {
  GetFilters,
  GetStates,
  SaveUpdateHybrid,
  ViewHybridCrop,
} from "../ApiCalls/HybridsAPICalls";
import { StagesToCreateAHybrid } from "../HybridCreationStages";
import { useStyles } from "../useStyle";
import { GeneralDetailsForm } from "./GeneralDetailsForm";
import {
  ActionType,
  MEDIA_TYPE,
  ModuleImageUpload,
  STEPS,
} from "../../../Common/Constants";
import { AgronomyPracticesForm } from "./AgronomyPracticesForm";
import { ApplicableFilterForm } from "./ApplicableFilterForm";
import { GrowerTestimonialsForm } from "./GrowerTestimonialsForm";
import HybridFeaturesForm from "./FeaturesForm";
import { PrecautionsForm } from "./PrecautionsForm";
import { RemoveImageList } from "../../../Api/generic_apicalls";
import getRandomizeValue from "../../../Utils/randomizer";
import { removeSpacesImagePath } from "../../../Utils/removeSpaceImagePath";
import { Media } from "../../../Common/UploadFile";
import combineUnionArray from "../../../Utils/combineUnionArray";

export type GeneralDetails = {
  media: (Media | undefined)[];
  language: string;
  hybridTitle: string;
  imageUrl: string;
  systemName: string;
  crop: string;
  agronomyText_maturity: string;
  sowingPeriod: string;
  spacing_ExpectedYield: string;
  seedRate: string;
  plantPopulation: string;
  rating: number;
  keyBenefitOne: string;
  keyBenefitTwo: string;
  keyBenefitThree: string;
  languageId: number | null;
  languageCode?: string;
  states: Array<string>;
  displayOrder: number;
};

export const InitialGeneralDetails = {
  language: "",
  hybridTitle: "",
  imageUrl: "",
  systemName: "",
  crop: "",
  agronomyText_maturity: "",
  sowingPeriod: "",
  spacing_ExpectedYield: "",
  seedRate: "",
  plantPopulation: "",
  keyBenefitOne: "",
  keyBenefitTwo: "",
  keyBenefitThree: "",
  languageId: 0,
  states: "",
};

export type HybridFeaturesKeys = {
  displayOrder?: number;
  heading: string | undefined;
  id: number | null;
  media: Array<any> | undefined;
  systemName: string | undefined;
  text: string | undefined;
  imagePath: string;
};

export const InitialHybridFeatures: HybridFeaturesKeys = {
  displayOrder: undefined,
  heading: "",
  id: null,
  media: [],
  systemName: "",
  text: "",
  imagePath: "",
};

export type PrecautionFormKeys = {
  displayOrder?: number;
  heading?: string;
  id: number | null;
  media: Array<any> | undefined;
  systemName: string;
  text: string | undefined;
};

export const InitialPrecautions: PrecautionFormKeys = {
  displayOrder: undefined,
  heading: "",
  id: null,
  media: [],
  systemName: "",
  text: "",
};

export type AgronomyKeys = {
  displayOrder?: number;
  heading: string | undefined;
  id: number | null;
  media: Array<any> | undefined;
  systemName: string | undefined;
  text: string | undefined;
  imagePath: string | undefined;
  // imageFile?: any,
};

export const InitialPractices: AgronomyKeys = {
  displayOrder: undefined,
  heading: "",
  id: null,
  media: [],
  systemName: "",
  text: "",
  imagePath: "",
};

export type TestimonialKeys = {
  displayOrder?: number;
  id: number | null;
  mediaType: string | undefined;
  mediaUrl: string | undefined;
  subtext: string | undefined;
  systemName: string | undefined;
  text: string | undefined;
};

export const InitialTestimonials: TestimonialKeys = {
  displayOrder: undefined,
  id: null,
  mediaType: "",
  mediaUrl: "",
  subtext: "",
  systemName: "",
  text: "",
};

export type FilterKeys = {
  filterValues: Array<any> | undefined;
  keySystemName: string | undefined;
  states: Array<any> | undefined;
  valueSystemName: string | undefined;
};
export const InitialFilters = {
  keySystemName: "",
  valueSystemName: "",
  filterValues: undefined,
  states: undefined,
};

export const SELECT_ALL = "Select all";

export const CreateOrModifyHybrid = (props: any) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      primaryLangCode: state.auth.languageCode,
    };
  });
  const classes = useStyles();
  const [activeState, setActiveState] = useState<number>(1);
  const [stateList, setStateList] = useState<Array<StateListType>>([]);
  // const [stateListFilter, setStateListFilter] = useState<Array<StateListType>>([]);
  const [latestImgsList, setLatestImgsList] = useState<Array<string>>([]);
  const [filters, setFilters] = useState<Array<any>>([]);
  // const generalDetailsFormRef = useRef<HTMLFormElement>();
  const [uniqueKey, setUniqueKey] = useState<number>(getRandomizeValue());

  /** HYBRID payload related state variables: START */

  const [generalDetails, setGeneralDetails] = useState<any>({});

  const [hybridFeatures, setHybridFeatures] = useState<HybridFeaturesKeys[]>(
    []
  );
  const [selectedHybridFeatures, setSelectedHybridFeatures] =
    useState<HybridFeaturesKeys>(InitialHybridFeatures);

  const [precautions, setPrecautions] = useState<PrecautionFormKeys[]>([]);
  const [selectedPrecautions, setSelectedPrecautions] =
    useState<PrecautionFormKeys>(InitialPrecautions);

  const [agronomyPractices, setAgronomyPractices] = useState<AgronomyKeys[]>(
    []
  );
  const [selectedAgronomyPractices, setSelectedAgronomyPractices] =
    useState<AgronomyKeys>(InitialPractices);

  const [growerTestimonials, setGrowerTestimonials] = useState<
    TestimonialKeys[]
  >([]);
  const [selectedGrowerTestimonials, setSelectedGrowerTestimonials] =
    useState<TestimonialKeys>(InitialTestimonials);

  const [applicableFilters, setApplicableFilters] = useState<FilterKeys[]>([]);
  const [selectedApplicableFilters, setSelectedApplicableFilters] =
    useState<FilterKeys>(InitialFilters);

  /** HYBRID payload related state variables: END */

  // const [smartGrowerCropId, setSmartGrowerCropId] = useState<number | undefined>(undefined);
  const [isPrimaryProduct, setIsPrimaryProduct] = useState<boolean>(false);
  // const [hybridDetails, setHybridDetails] = useState<any>({});
  const [cropsList, setCropsList] = useState<any[]>([]);

  const [isSystemNameEditable, canEditSystemName] = useState<boolean>(true);
  const [canAddNewRecord, toggleAddNewRecord] = useState<boolean>(true);
  const [canUpdateExistingRecord, toggleUpdateExistingRecord] =
    useState<boolean>(true);
  const [recordBaseLanguage, setBaseLanguageOfRecord] = useState<string>("");

  const getUniqueKey = () => {
    setUniqueKey(getRandomizeValue());
  };

  const successCallBack = (
    response: any,
    type: string,
    imgType?: string,
    optionalData?: any
  ) => {
    switch (type) {
      case "save_update_hybrid":
        if (response.statusCode === 200 || response.statusCode === 201) {
          setLatestImgsList([]);
          props.onClose();
        } else {
          TriggerToastMessage(
            response.message ||
              `Hybrid ${!props.isEdit ? "save" : "updation"} failed`,
            "success"
          );
        }
        break;
      case "s3_file_url":
        if (response.data) {
          if (imgType === ModuleImageUpload.CROP) {
            const imgUrl = response.data.imageUrl;
            setGeneralDetails((prevState: any) => ({
              ...prevState,
              imageUrl: imgUrl,
            }));
            setLatestImgsList([...latestImgsList, imgUrl]);
          }
        } else {
          errorCallback(response, "s3_file_url", "");
        }
        break;
      case "states": {
        const formattedData = response.data.map((state: any) => ({
          ...state,
          name: state.stateName,
          checked: false,
        }));
        formattedData.unshift({ name: SELECT_ALL, checked: false });
        setStateList(formattedData);
        break;
      }
      case "remove_img":
        if (response.statusCode === 200) {
          TriggerToastMessage(
            response.message || "Uploaded images removed successfully.",
            "success"
          );
        }
        break;
      case "filters":
        if (response.statusCode === 200 || response.statusCode === 201) {
          setFilters(response.data);
        }
        break;
      default:
        break;
    }
  };
  const errorCallback = (_error: any, type: string, imgType: string) => {
    switch (type) {
      case "save_crop":
      case "update_crop": {
        /** Closing the popup, passing false to display failed creation */
        props.onClose(false);
        break;
      }
      default: {
        /**Generic alert to display API fail */
        TriggerToastMessage(
          "Unable to process your request!!! " + type,
          "error"
        );
      }
    }
  };

  /**STEP-2 methods start*/
  /**
   * @description Method to update hybrid features
   * @param data Hybridfeatures
   */
  const updateFeatureHybrids = (data: HybridFeaturesKeys) => {
    if (selectedHybridFeatures?.systemName) {
      /**updating existing record */
      updateRecord(hybridFeatures, selectedHybridFeatures, data, "systemName");
      setSelectedHybridFeatures(InitialHybridFeatures);
    } else {
      /**creating new record */
      setHybridFeatures([...hybridFeatures, data]);
    }
  };
  /**
   * @description Method to remove selected hybrid feature
   * @param systemName string
   */
  const removeSelectedHybridFeature = (systemName: string) => {
    // if systemName matches with selected filter
    if (systemName === selectedHybridFeatures.systemName) {
      setSelectedHybridFeatures(InitialHybridFeatures);
    }
    // remove the entry from filterKeys array
    const precautionsAfterRemoval = _.reject(hybridFeatures, {
      systemName: systemName,
    });
    setHybridFeatures([...precautionsAfterRemoval]);
  };
  /**STEP-2 methods end*/

  /**STEP-3 methods start*/
  /**
   * @description Method to update precautions
   * @param data precautions
   */
  const updatePrecautions = (data: PrecautionFormKeys) => {
    if (selectedPrecautions?.systemName) {
      /**updating existing record */
      updateRecord(precautions, selectedPrecautions, data, "systemName");
      setSelectedPrecautions(InitialPrecautions);
    } else {
      /**creating new record */
      setPrecautions([...precautions, data]);
    }
  };

  const removeSelectedPrecaution = (systemName: string) => {
    // if systemName matches with selected filter
    if (systemName === selectedPrecautions.systemName) {
      setSelectedPrecautions(InitialPrecautions);
    }
    // remove the entry from filterKeys array
    const precautionsAfterRemoval = _.reject(precautions, {
      systemName: systemName,
    });
    setPrecautions([...precautionsAfterRemoval]);
  };

  /**STEP-3 methods end */

  /**STEP-4 methods start*/
  /**
   * @description Method to update practices
   * @param data practices
   */
  const updatePractices = (data: AgronomyKeys) => {
    if (selectedAgronomyPractices?.systemName) {
      /**updating existing record */
      updateRecord(
        agronomyPractices,
        selectedAgronomyPractices,
        data,
        "systemName"
      );
      setSelectedAgronomyPractices(InitialPractices);
    } else {
      /**creating new record */
      setAgronomyPractices([...agronomyPractices, data]);
    }
  };

  const removeSelectedPractice = (systemName: string) => {
    // if systemName matches with selected filter
    if (systemName === selectedAgronomyPractices.systemName) {
      setSelectedAgronomyPractices(InitialPractices);
    }
    // remove the entry from filterKeys array
    const practicesAfterRemoval = _.reject(agronomyPractices, {
      systemName: systemName,
    });
    setAgronomyPractices([...practicesAfterRemoval]);
  };

  /**STEP-4 methods end */

  /**STEP-5 methods start*/
  /**
   * @description Method to update testimonials
   * @param data testimonials
   */
  const updateGrowerTestimonials = (data: TestimonialKeys) => {
    if (selectedGrowerTestimonials?.systemName) {
      /**updating existing record */
      updateRecord(
        growerTestimonials,
        selectedGrowerTestimonials,
        data,
        "systemName"
      );
      setSelectedGrowerTestimonials(InitialTestimonials);
    } else {
      /**creating new record */
      setGrowerTestimonials([...growerTestimonials, data]);
    }
  };

  const removeSelectedGrowerTestimonial = (systemName: string) => {
    // if systemName matches with selected filter
    if (systemName === selectedGrowerTestimonials.systemName) {
      setSelectedGrowerTestimonials(InitialTestimonials);
    }
    // remove the entry from filterKeys array
    const growerTestimonialAfterRemoval = _.reject(growerTestimonials, {
      systemName: systemName,
    });
    setGrowerTestimonials([...growerTestimonialAfterRemoval]);
  };

  /**STEP-5 methods end */

  /**STEP-6 methods start*/
  /**
   * @description Method to update filters
   * @param data filters
   */
  const updateApplicableFilters = (data: FilterKeys) => {
    if (selectedApplicableFilters?.keySystemName) {
      /**updating existing record */
      updateRecord(
        applicableFilters,
        selectedApplicableFilters,
        data,
        "keySystemName",
        "valueSystemName"
      );
      setSelectedApplicableFilters(InitialFilters);
    } else {
      /**creating new record */
      setApplicableFilters([...applicableFilters, data]);
    }
  };

  const removeSelectedApplicableFilter = (systemName: string) => {
    // resetting selected record to initial state, If any
    if (
      systemName.split(",")[0] === selectedApplicableFilters.keySystemName &&
      systemName.split(",")[1] === selectedApplicableFilters.valueSystemName
    ) {
      setSelectedApplicableFilters(InitialFilters);
    }
    // remove the entry from targetThreatList array
    const applicableFiltersAfterRemoval = _.reject(applicableFilters, {
      keySystemName: systemName.split(",")[0],
      valueSystemName: systemName.split(",")[1],
    });
    setApplicableFilters([...applicableFiltersAfterRemoval]);
  };

  /**STEP-6 methods end */

  /*** fetching state list*/
  const getAllStateList = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.country_wise_states;
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": storeData.primaryLangCode,
    };
    return GetStates(apiData, successCallBack, errorCallback);
  };

  /** Generic logic to update existing record */
  const updateRecord = (
    mainList: any,
    prevRecord: any,
    newRecord: any,
    matchKey1: string,
    matchKey2?: string
  ) => {
    mainList.map((eachItem: any, index: number) => {
      if (!matchKey2) {
        if (eachItem[matchKey1] === prevRecord[matchKey1]) {
          mainList.splice(index, 1, newRecord);
        }
      } else {
        if (
          eachItem[matchKey1] === prevRecord[matchKey1] &&
          eachItem[matchKey2] === prevRecord[matchKey2]
        ) {
          mainList.splice(index, 1, newRecord);
        }
      }
    });
  };
  const processHybridData = (response: any) => {
    const data: any = response.payload;
    const selectedLanguage = storeData.languages.filter(
      (language: LanguageRecordType) =>
        language.languageCode === data.languageCode
    );
    const isPrimaryProduct = !(
      props.actionType === ActionType.ADD_TRANSLATION && selectedLanguage.length
    )
      ? selectedLanguage[0] &&
        selectedLanguage[0].languageCode === storeData.primaryLangCode
      : false;
    const generalDetails: any = {
      languageCode: selectedLanguage[0]?.languageCode,
      language: selectedLanguage[0]?.languageName,
      languageId: selectedLanguage[0]?.languageId,
      hybridTitle: data.name || "",
      systemName: data.systemName,
      crop: data.crop,
      agronomyText_maturity: data.maturity,
      sowingPeriod: data.month,
      spacing_ExpectedYield: data.expectedYield || "",
      seedRate: data.rate,
      plantPopulation: data.plantPopulation,
      rating: data.rating,
      keyBenefitOne: data.benefit1,
      keyBenefitTwo: data.benefit2,
      keyBenefitThree: data.benefit3,
      imageUrl: data.media?.[0]?.path,
      productId: response.id,
      smartGrowerHybridtId: response.smartGrowerHybridtId,
      smartGrowerCropId: response.smartGrowerCropId,
      media: data.media,
      displayOrder: data.displayOrder,
    };
    if (data?.states?.length) {
      data.states.map((eachState: any) => {
        eachState.stateId = eachState.id;
        eachState.enStateName = eachState.name;
        eachState.stateShortCode = eachState.shortCode;
      });
    }
    generalDetails.states = data.states;
    if (data.features) {
      data.features.map((feature: any) => {
        feature.imagePath = feature?.media?.length
          ? feature.media[0].path
          : null;
      });
    }
    if (data.agronomyPractices) {
      data.agronomyPractices.map((practice: any) => {
        practice.imagePath = practice?.media?.length
          ? practice.media[0].path
          : null;
      });
    }
    const hybridInfoObj: any = {
      generalDetails,
      hybridFeatures: [...data.features].sort(
        (a, b) => a.displayOrder - b.displayOrder
      ),
      precautions: [...data.precautions].sort(
        (a, b) => a.displayOrder - b.displayOrder
      ),
      agronomyPractices: [...data.agronomyPractices].sort(
        (a, b) => a.displayOrder - b.displayOrder
      ),
      growerTestimonials: [...data.growerTestimonials].sort(
        (a, b) => a.displayOrder - b.displayOrder
      ),
    };
    data.filters.map((filter: any) => {
      filter.states = filter.filterStates;
      if (filter?.states?.length) {
        filter.states.map((eachState: any) => {
          eachState.stateId = eachState.id;
          eachState.enStateName = eachState.name;
          eachState.stateShortCode = eachState.shortCode;
        });
      }
    });
    hybridInfoObj.filters = [...data.filters];
    setGeneralDetails(hybridInfoObj.generalDetails);
    setBaseLanguageOfRecord(generalDetails.language);
    setHybridFeatures(hybridInfoObj.hybridFeatures);
    setPrecautions(hybridInfoObj.precautions);
    setAgronomyPractices(hybridInfoObj.agronomyPractices);
    setGrowerTestimonials(hybridInfoObj.growerTestimonials);
    setApplicableFilters(hybridInfoObj.filters);
    setIsPrimaryProduct(isPrimaryProduct);
  };

  const compareWithPrimaryLanguage = (response: any) => {
    const data = response.payload;

    const combinedFeatures = combineUnionArray(
      hybridFeatures,
      data.features,
      (a, b) => a.systemName === b.systemName
    ).sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));

    const combinedPrecautions = combineUnionArray(
      precautions,
      data.precautions,
      (a, b) => a.systemName === b.systemName
    ).sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));

    const combinedAgroPractices = combineUnionArray(
      agronomyPractices,
      data.agronomyPractices,
      (a, b) => a.systemName === b.systemName
    ).sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));

    const combinedGrowerTestimonials = combineUnionArray(
      growerTestimonials,
      data.growerTestimonials,
      (a, b) => a.systemName === b.systemName
    ).sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));

    setHybridFeatures(combinedFeatures);
    setPrecautions(combinedPrecautions);
    setAgronomyPractices(combinedAgroPractices);
    setGrowerTestimonials(combinedGrowerTestimonials);
  };

  const fetchHybridDetails = (syncWithPrimary = false) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    // apiData.endPoint = api_endpoints.view_hybrid_details.replace('__CROP_SYSTEM_NAME__', props.cropSystemName).replace('__HYBRID_SYS_NM__', props.systemName);
    if (!props.hybridViewID) {
      /** for published draft Hybrid */
      apiData.endPoint = api_endpoints.view_hybrid_details
        .replace("__CROP_SYSTEM_NAME__", props.cropSystemName)
        .replace("__HYBRID_SYS_NM__", props.systemName);
      if (!props.lngCode) {
        /** Language code is mandatory for fetching published Records */
        const cropLanguageObject: LanguageRecordType[] =
          storeData.languages.filter((lngRcd: LanguageRecordType) => {
            return lngRcd.languageId === props.lngId;
          });
        if (cropLanguageObject.length > 0) {
          apiData.customHeaders = {
            "language-code": cropLanguageObject[0].languageCode,
          };
        }
      } else {
        apiData.customHeaders = {
          "language-code": props.lngCode,
        };
      }
    } else {
      /** this api call is for un-published HYBRID */
      apiData.endPoint = api_endpoints.hybrid_by_draft_id.replace(
        "__HYBRID__DRAFT__ID__",
        props.hybridViewID
      );
    }

    if (syncWithPrimary) {
      apiData.customHeaders = {
        "language-code": storeData.primaryLangCode,
      };
    }

    ViewHybridCrop(apiData)
      .then((response: any) => {
        if (response.statusCode === 200) {
          if (syncWithPrimary) {
            compareWithPrimaryLanguage(response.data);
          } else {
            processHybridData(response.data);
          }
          return;
        }
        throw new Error(response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCropsList = () => {
    const apiData = {} as IRequestBody;
    apiData.endPoint = api_endpoints.crops;
    apiData.showLoader = true;
    apiData.payLoad = {
      pageNo: 0,
      pageSize: 100,
    };
    apiData.customHeaders = {
      "language-code": storeData.primaryLangCode,
    };
    GetCropsDataList(
      apiData,
      (response: any) => {
        let cropsData: any[];
        cropsData = [...response.data.cropsList].map((eachCrop) => {
          eachCrop.label = eachCrop.name;
          return eachCrop;
        });
        setCropsList(cropsData);
      },
      (_error: any, type: string) => {}
    );
  };

  const fetchFiltersData = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.hybrid_filters;
    apiData.showLoader = true;
    return GetFilters(apiData, successCallBack, errorCallback);
  };

  const renderHybridStages = (nextStep: number) => {
    switch (nextStep) {
      case 1:
        return (
          <GeneralDetailsForm
            generalDetails={generalDetails}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            isEdit={props.isEdit}
            isPrimaryProduct={isPrimaryProduct}
            actionType={props.actionType}
            stateList={_.cloneDeep(stateList)}
            cropsList={cropsList}
            key={getRandomizeValue()}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList((prev) => [...prev, imgURL])
            }
            baseLanguage={recordBaseLanguage}
          />
        );
      case 2:
        return (
          <HybridFeaturesForm
            ListOfStages={hybridFeatures}
            selectedStage={selectedHybridFeatures}
            onSave={(data: HybridFeaturesKeys) => {
              updateFeatureHybrids(data);
            }}
            onRemove={(FeatureSystemName: string) => {
              removeSelectedHybridFeature(FeatureSystemName);
            }}
            onSelect={(data: HybridFeaturesKeys) => {
              setSelectedHybridFeatures({ ...data });
            }}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            hybridSystemName={generalDetails.systemName}
            cropSystemName={generalDetails.crop}
            updateListOrder={(newList: Array<HybridFeaturesKeys>) => {
              setHybridFeatures(newList);
            }}
            isEdit={props.isEdit}
            isPrimaryProduct={isPrimaryProduct}
            actionType={props.actionType}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList([...latestImgsList, imgURL])
            }
          />
        );
      case 3:
        return (
          <PrecautionsForm
            ListOfStages={precautions}
            selectedStage={selectedPrecautions}
            onSelect={(data: PrecautionFormKeys) => {
              setSelectedPrecautions({ ...data });
            }}
            onSave={(data: PrecautionFormKeys) => {
              updatePrecautions(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedPrecaution(systemName);
            }}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            updateListOrder={(newList: Array<PrecautionFormKeys>) => {
              setPrecautions(newList);
            }}
            isEdit={props.isEdit}
            isPrimaryProduct={isPrimaryProduct}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
            actionType={props.actionType}
          />
        );
      case 4:
        return (
          <AgronomyPracticesForm
            ListOfStages={agronomyPractices}
            selectedStage={selectedAgronomyPractices}
            onSelect={(data: AgronomyKeys) => {
              setSelectedAgronomyPractices({ ...data });
            }}
            onSave={(data: AgronomyKeys) => {
              updatePractices(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedPractice(systemName);
            }}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            isUpdateField={
              props.actionType === ActionType.UPDATE && !isPrimaryProduct
            }
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            systemName={generalDetails?.systemName}
            updateListOrder={(newList: Array<AgronomyKeys>) => {
              setAgronomyPractices(newList);
            }}
            hybridSystemName={generalDetails.systemName}
            cropSystemName={generalDetails.crop}
            isEdit={props.isEdit}
            isPrimaryProduct={isPrimaryProduct}
            actionType={props.actionType}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList([...latestImgsList, imgURL])
            }
          />
        );
      case 5:
        return (
          <GrowerTestimonialsForm
            ListOfStages={growerTestimonials}
            selectedStage={selectedGrowerTestimonials}
            onSelect={(data: TestimonialKeys) => {
              setSelectedGrowerTestimonials({ ...data });
            }}
            onSave={(data: TestimonialKeys) => {
              updateGrowerTestimonials(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedGrowerTestimonial(systemName);
            }}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            systemName={generalDetails?.systemName}
            updateListOrder={(newList: Array<TestimonialKeys>) => {
              setGrowerTestimonials(newList);
            }}
            hybridSystemName={generalDetails.systemName}
            cropSystemName={generalDetails.crop}
            isEdit={props.isEdit}
            isPrimaryProduct={isPrimaryProduct}
            actionType={props.actionType}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            isUpdateField={
              props.actionType === ActionType.UPDATE && !isPrimaryProduct
            }
            canUpdateExistingRecord={canUpdateExistingRecord}
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList([...latestImgsList, imgURL])
            }
          />
        );
      case 6:
        return (
          <ApplicableFilterForm
            ListOfStages={_.cloneDeep(applicableFilters)}
            selectedStage={selectedApplicableFilters}
            onSelect={(data: FilterKeys) => {
              let value = _.filter(filters, {
                systemName: data.keySystemName,
              });
              const returnedTarget = Object.assign(data, value[0]);
              setSelectedApplicableFilters({ ...returnedTarget });
            }}
            onSave={(data: FilterKeys) => {
              updateApplicableFilters(data);
            }}
            onRemove={(keySystemName: string) => {
              removeSelectedApplicableFilter(keySystemName);
            }}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            updateListOrder={(newList: Array<FilterKeys>) => {
              setApplicableFilters(newList);
            }}
            isEdit={props.isEdit}
            stateList={_.cloneDeep(stateList)}
            onClose={props.onClose}
            hybridGeneralDetails={generalDetails}
            hybridFeatures={hybridFeatures}
            hybridPrecautions={precautions}
            hybridPractice={agronomyPractices}
            hybridTestimonials={growerTestimonials}
            filterList={filters}
            actionType={props.actionType}
            isPrimaryProduct={isPrimaryProduct}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
          />
        );
      default:
        break;
    }
  };

  const gotoNextStage = (nextStep: number, values?: any) => {
    if (nextStep !== STEPS.STEP7) {
      setActiveState(nextStep);
      renderHybridStages(nextStep);
    } else {
      saveUpdateHybrid();
    }
    switch (nextStep - 1) {
      case 1:
      case 0:
        if (values) {
          setGeneralDetails((prevState: any) => ({
            ...prevState,
            systemName: values.systemName,
            hybridTitle: values.hybridTitle,
            imageUrl: values.imageUrl,
            crop: values.crop,
            agronomyText_maturity: values.agronomyText_maturity,
            sowingPeriod: values.sowingPeriod,
            spacing_ExpectedYield: values.spacing_ExpectedYield,
            seedRate: values.seedRate,
            plantPopulation: values.plantPopulation,
            rating: values.rating,
            keyBenefitOne: values.keyBenefitOne,
            keyBenefitTwo: values.keyBenefitTwo,
            keyBenefitThree: values.keyBenefitThree,
            language: values.language,
            languageId: values.languageId,
            languageCode: values.languageCode,
            states: values.states,
            media: values.media,
            displayOrder: values.displayOrder,
          }));
        }
        break;
      case 2:
        logger.info("Step-2 data:", hybridFeatures);
        break;
      case 3:
        logger.info("Step-3 data:", precautions);
        break;
      case 4:
        logger.info("Step-4 data:", agronomyPractices);
        break;
      case 5:
        logger.info("Step-5 data:", growerTestimonials);
        break;
      case 6:
        logger.info("Step-6 data:", applicableFilters);
        break;
      default:
        return;
    }
  };
  type StateType = {
    id: number;
    name: string;
    shortCode: string;
  };
  const getSaveUpdateHybridPayload = () => {
    let attributes: any = {};
    let payload: any = {};
    /** To level payload before step-1 */
    if (props.isEdit && props.actionType !== ActionType.ADD_TRANSLATION) {
      attributes.smartGrowerHybridtId = generalDetails.smartGrowerHybridtId;
      attributes.smartGrowerCropId = generalDetails.smartGrowerCropId;
      attributes.id = generalDetails.productId;
    } else if (
      props.actionType === ActionType.ADD_TRANSLATION ||
      props.actionType === ActionType.CREATE
    ) {
      attributes.smartGrowerHybridtId = null;
      attributes.smartGrowerCropId = null;
      attributes.id = null;
    }
    attributes.hybridSystemName = generalDetails.systemName;
    attributes.cropSystemName = generalDetails.crop;
    attributes.languageId = generalDetails.languageId;
    attributes.languageName = generalDetails.language;
    attributes.languageCode = generalDetails.languageCode;

    /** step-1 payload */
    // payload.languageId = generalDetails.languageId;
    payload.languageName = generalDetails.language;
    payload.languageCode = generalDetails.languageCode;
    payload.name = generalDetails.hybridTitle;
    payload.systemName = generalDetails.systemName;
    payload.crop = generalDetails.crop;

    payload.maturity = generalDetails.agronomyText_maturity;
    payload.month = generalDetails.sowingPeriod;
    payload.expectedYield = generalDetails.spacing_ExpectedYield;
    payload.rate = generalDetails.seedRate;
    payload.plantPopulation = generalDetails.plantPopulation;
    payload.rating = generalDetails.rating;
    payload.benefit1 = generalDetails.keyBenefitOne;
    payload.benefit2 = generalDetails.keyBenefitTwo;
    payload.benefit3 = generalDetails.keyBenefitThree;

    payload.displayOrder = generalDetails.displayOrder;
    /** Image payload as per BE requirement */
    // payload.media = generalDetails.imageUrl
    //     ? [
    //             {
    //         type: MEDIA_TYPE[0],
    //                 path: removeSpacesImagePath(
    //                     generalDetails.systemName,
    //                     generalDetails.imageUrl
    //                 ),
    //               displayOrder: 1,
    //       },
    //         ]
    //   : [];
    payload.media = generalDetails.media;
    // payload.states = generalDetails.states;
    /** step-1 states payload*/
    let selectedState: Array<StateType> = [];
    if (generalDetails?.states?.length) {
      [...generalDetails.states].forEach((state: any) => {
        selectedState.push({
          id: state.stateId,
          name: state.enStateName ?? state.stateName,
          shortCode: state.stateShortCode,
        });
      });
    }
    payload.states = selectedState;

    /** step-2 payload */
    let stpet2Data: any = [];
    [...hybridFeatures].forEach((item: any, index: number) => {
      let mediaObj: any = item.imagePath
        ? [
            {
              type: MEDIA_TYPE[0],
              path: removeSpacesImagePath(
                generalDetails.systemName,
                item.imagePath
              ),
            },
          ]
        : [];
      stpet2Data.push({
        systemName: item.systemName,
        heading: item.heading,
        text: item.text,
        media: mediaObj,
        displayOrder: index + 1,
        id: item.id ? item.id : null,
      });
    });
    payload.features = stpet2Data;
    /** step-3 payload */
    let stpet3Data: any = [];
    [...precautions].forEach((item: any, index: number) => {
      item.displayOrder = index + 1;
      // item.media = [{ type: MEDIA_TYPE[0], path: item.imagePath ? item.imagePath : null }];
      stpet3Data.push(item);
    });
    payload.precautions = stpet3Data;
    /** step-4 payload */
    let stpet4Data: any = [];
    [...agronomyPractices].forEach((item: any, index: number) => {
      let mediaObj: any = item.imagePath
        ? [
            {
              type: MEDIA_TYPE[0],
              path: removeSpacesImagePath(
                generalDetails.systemName,
                item.imagePath
              ),
            },
          ]
        : [];
      stpet4Data.push({
        systemName: item.systemName,
        heading: item.heading,
        text: item.text,
        media: mediaObj,
        displayOrder: index + 1,
        id: item.id ? item.id : null,
      });
    });
    payload.agronomyPractices = stpet4Data;
    /** step-5 payload */
    let stpet5Data: any = [];
    [...growerTestimonials].forEach((item: any, index: number) => {
      item.displayOrder = index + 1;
      stpet5Data.push(item);
    });
    payload.growerTestimonials = stpet5Data;
    /** step-6 payload */
    let stpet6Data: any = [];
    [...applicableFilters].forEach((item: any) => {
      let filterStates: Array<any> = [];
      item.states.map((selectedState: any) => {
        filterStates.push({
          id: selectedState.stateId,
          name: selectedState.enStateName ?? selectedState.stateName,
          shortCode: selectedState.stateShortCode,
        });
      });
      stpet6Data.push({
        keySystemName: item.keySystemName,
        valueSystemName: item.valueSystemName,
        value: item.valueSystemName,
        filterStates: filterStates,
        // displayOrder: item.displayOrder ? item.displayOrder : index + 1,
        // id: item.id ? item.id : null,
      });
    });
    payload.filters = stpet6Data;
    attributes.payload = payload;
    return attributes;
  };
  const saveUpdateHybrid = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.save_update_hybrid;
    apiData.showLoader = true;
    apiData.payLoad = getSaveUpdateHybridPayload();

    return SaveUpdateHybrid(apiData, successCallBack, errorCallback);
  };

  /** handle close Create/Edit CP modal */
  const handleClosePopup = () => {
    // props.setShowCreateModal(false);
    props.onClose();
    /** API call to remove images */
    if (latestImgsList?.length) {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      apiData.endPoint = api_endpoints.remove_img_list;
      apiData.showLoader = true;
      apiData.payLoad = { fileUrlsList: latestImgsList };
      return RemoveImageList(apiData, successCallBack, errorCallback);
    }
  };

  /** Getting Hybrid Data by System Name */
  useEffect(() => {
    if (props.isEdit) {
      fetchHybridDetails();
    }
  }, [props.isEdit]);

  useEffect(() => {
    getAllStateList();
    fetchCropsList();
    fetchFiltersData();
  }, []);

  useEffect(() => {
    if (
      props.isEdit &&
      generalDetails?.languageCode !== undefined &&
      generalDetails.languageCode !== storeData.primaryLangCode
    ) {
      fetchHybridDetails(true);
    }
  }, [props.isEdit, generalDetails?.languageCode, storeData.primaryLangCode]);
  return (
    <div>
      <Modal open={true} disableEscapeKeyDown={true} onClose={() => {}}>
        <Paper className={classes.formPaper}>
          <Paper elevation={3} square>
            <ModalHeader
              // header={`New Hybrid`}
              header={
                props.actionType === ActionType.ADD_TRANSLATION &&
                !isPrimaryProduct
                  ? `Add Translation`
                  : (props.actionType === ActionType.UPDATE ||
                      props.actionType === ActionType.ADD_TRANSLATION) &&
                    isPrimaryProduct
                  ? `Update Primary Hybrid`
                  : props.actionType === ActionType.UPDATE && !isPrimaryProduct
                  ? `Update Hybrid`
                  : `New Hybrid`
              }
              showDelete={false}
              showEdit={false}
              onClose={() => {
                if (confirm("You may lose your data.?")) {
                  handleClosePopup();
                }
              }}
            ></ModalHeader>
            {/** stepper component */}
            <StepperComponent
              steps={StagesToCreateAHybrid}
              activeStep={activeState}
              stepChangehandler={(step: number) => {
                if (props.isEdit && activeState !== 1) setActiveState(step);
              }}
            />
          </Paper>
          <Box>{renderHybridStages(activeState)}</Box>
        </Paper>
      </Modal>
    </div>
  );
};
