import { Box, Modal, Paper } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api_service } from "../../../Api/api_service";
import api_endpoints, { base_url } from "../../../Api/end_points";
import {
  S3ImageUploadType,
  StateListType,
} from "../../../Common/Constants/types";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import StepperComponent from "../../../Common/Stepper";
import { IRequestBody } from "../../../Constants/interfaces";
import {
  canAddRecord,
  canUpdateRecord,
  checkIfSystemNameIsEditable,
} from "../../../Utils/genericUtils";
import { logger } from "../../../Utils/logger";
import getRandomizeValue from "../../../Utils/randomizer";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { RootState } from "../../../redux/reducers";
import { LanguageRecordType } from "../../../redux/reducers/generic_reducer";
import { store } from "../../../redux/store";
import {
  GetCropDetails,
  getFiltersForCrop,
  GetStates,
  postPracticeDuration,
  RemoveImageList,
  SaveCropData,
  UpdateCropData,
} from "../CropsAPICalls";
import { useStyles } from "../useStyle";
import { GetFileS3BucketURL } from "../../../Api/generic_apicalls";
import {
  ActionType,
  ModuleImageUpload,
  STEPS,
} from "../../../Common/Constants";
import { StagesToCreateACrop } from "../stepperStages";
import { CropCalenderForm } from "./CropCalenderForm";
import { CropGrowthStagesForm } from "./CropGrowthStagesForm";
import { CropStageCalenderForm } from "./CropStageCalenderForm";
import { FilterKeyForm } from "./FilterKeyForm";
import { GeneralDetailsForm } from "./GeneralDetailsForm";
import combineUnionArray from "../../../Utils/combineUnionArray";

export type CropGrowthStageKeys = {
  displayOrder?: number;
  title: string | undefined;
  systemName: string | undefined;
  description: string | undefined;
  imagePath: string | undefined;
  imageFile?: any;
  id?: number;
};
export type FilterKeys = {
  displayOrder?: number;
  systemName: string | undefined;
  filterName: string | undefined;
  id?: number;
  filterValues: FilterValues[];
};

export type FilterValues = {
  id?: number | null;
  valueSystemName?: number;
  displayLabel: string;
  displayOrder: number;
};

export type CropCalendarTypes = {
  generic: boolean | string | undefined;
  calendarName: string | undefined;
  practice: string | undefined;
  duration: string | number | undefined;
  isDosApplicable: string | boolean | undefined;
  isDotApplicable: string | boolean | undefined;
  calendarId?: number;
  cropStagesCalendar?: CropStageCalendarTypes[];
};
export type CropStageCalendarTypes = {
  calendarName: string | undefined;
  stageSystemName: string | undefined;
  applicable: boolean | string | undefined;
  startDay: string | undefined;
  endDay: string | undefined;
  reference: string | boolean | undefined;
  id?: number;
};
export type PracticeAndDurationList = {
  label: string;
  code: string;
};
export type ResponseDataCropEnrollments = {
  displayOrder: number;
  enrollmentOptionTranslation: string;
  systemName: string;
};
export const initialCropGrowthStage = {
  title: "",
  systemName: "",
  description: "",
  imagePath: "",
  imageFile: undefined,
};
export const initialFilter = {
  systemName: "",
  filterName: "",
  filterValues: [],
};

export const initialCropCalendar = {
  generic: undefined,
  calendarName: "",
  practice: "",
  duration: "",
  isDosApplicable: "",
  isDotApplicable: "",
};
export const initialCropStageCalendar = {
  calendarName: "",
  stageSystemName: "",
  applicable: "",
  startDay: "",
  endDay: "",
  reference: "",
};
export const SELECT_ALL = "Select all";
export interface EnrollmentOptionPayload {
  displayOrder: number;
  systemName: string;
  translationText: string;
}

export interface EnrollmentOptionResponse {
  statusCode: number;
  message: string;
  data?: {
    displayOrder: number;
    systemName: string;
    enrollmentOptionTranslation: string;
  };
}

export const CreateOrModifyCrop = (props: any) => {
  const appState: RootState = store.getState();
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      languageCode: state.auth.languageCode,
    };
  });
  const [generalDetails, setGeneralDetails] = useState<any>();

  const [cropGrowthStages, setCropGrowthStages] = useState<
    CropGrowthStageKeys[]
  >([]);
  const [selectedCropGrowthStage, setSelectedCropGrowthStage] =
    useState<CropGrowthStageKeys>(initialCropGrowthStage);

  const [filterKeys, setFilterKeys] = useState<FilterKeys[]>([]);
  const [selectedFilter, setSelectedFilter] =
    useState<FilterKeys>(initialFilter);

  const [cropCalender, setCropCalender] = useState<CropCalendarTypes[]>([]);
  const [selectedCropCalendar, setSelectedCropCalendar] =
    useState<CropCalendarTypes>(initialCropCalendar);

  const [cropCalendarStages, setCropCalendarStages] = useState<
    CropStageCalendarTypes[]
  >([]);
  const [selectedCropCalendarStage, setSelectedCropCalendarStage] =
    useState<CropStageCalendarTypes>(initialCropStageCalendar);
  // const [cropStageCalender, setCropStateCalender] = useState({});

  const [activeState, setActiveState] = useState<number>(1);
  const [uniqueKey, setUniqueKey] = useState<number>(getRandomizeValue());
  const [stateList, setStateList] = useState<Array<StateListType>>([]);
  const [smartGrowerCropId, setSmartGrowerCropId] = useState<
    number | undefined
  >(undefined);
  const [isPrimaryCrop, setIsPrimaryCrop] = useState<boolean>(false);
  const [latestImgsList, setLatestImgsList] = useState<Array<string>>([]);
  const [isSystemNameEditable, canEditSystemName] = useState<boolean>(true);
  const [canAddNewRecord, toggleAddNewRecord] = useState<boolean>(true);
  const [canUpdateExistingRecord, toggleUpdateExistingRecord] =
    useState<boolean>(true);
  const [recordBaseLanguage, setBaseLanguageOfRecord] = useState<string>("");
  const [farmingPracticeList, setFarmingPracticeList] = useState<
    PracticeAndDurationList[]
  >([]);
  const [cropDurationList, setCropDurationList] = useState<
    PracticeAndDurationList[]
  >([]);
  const [firstFarmingPracticeList, setFirstFarmingPracticeList] = useState<
    PracticeAndDurationList[]
  >([]);
  const [firstCropDurationList, setFirstCropDurationList] = useState<
    PracticeAndDurationList[]
  >([]);
  /**STEP-2 methods start*/
  /**
   * @description Method to update crop growth stage
   * @param data CropGrowthStageKeys
   */
  const updateCropGrowthStages = (data: CropGrowthStageKeys) => {
    if (selectedCropGrowthStage?.systemName) {
      /**updating existing record */
      updateRecord(
        cropGrowthStages,
        selectedCropGrowthStage,
        data,
        "systemName"
      );
      setSelectedCropGrowthStage(initialCropGrowthStage);
    } else {
      /**creating new record */
      setCropGrowthStages([...cropGrowthStages, data]);
    }
  };
  /**
   * @description Method to remove selected crop growth stage
   * @param systemName string
   */
  const removeSelectedCropGrowthStage = (systemName: string) => {
    if (confirmRemoval(systemName)) {
      removeCropCalendarStages(systemName);
      if (systemName === selectedCropGrowthStage.systemName) {
        setSelectedCropGrowthStage(initialCropGrowthStage);
      }
      const cropStagesAfterRemoval = _.reject(cropGrowthStages, {
        systemName: systemName,
      });
      setCropGrowthStages([...cropStagesAfterRemoval]);
    }
  };

  const confirmRemoval = (systemName: string) => {
    return (
      (cropCalendarStages?.length &&
        confirm(
          `All the data asscociated with ${systemName} in step-5 will be deleted, are you sure ?`
        )) ||
      (cropCalendarStages && !cropCalendarStages.length)
    );
  };

  const removeCropCalendarStages = (systemName: string) => {
    if (cropCalendarStages?.length) {
      let remove: Array<number> = [];
      let step5Data = [...cropCalendarStages];
      step5Data.forEach((element: CropStageCalendarTypes, index: number) => {
        if (systemName === element.stageSystemName) {
          remove.push(index);
        }
      });
      for (let i = remove.length - 1; i >= 0; i--) {
        step5Data.splice(remove[i], 1);
      }
      setCropCalendarStages(step5Data);
    }
  };
  /**STEP-2 methods end*/

  /**STEP-3 methods starts*/
  /**
   * @description Method to update filter keys
   * @param data FilterKeys
   */
  const updateFiltersList = (data: FilterKeys) => {
    if (selectedFilter?.systemName) {
      /**updating existing record */
      updateRecord(filterKeys, selectedFilter, data, "systemName");
      setSelectedFilter(initialFilter);
    } else {
      /**creating new record */
      setFilterKeys([...filterKeys, data]);
    }
  };
  /**
   * @description Method to remove selected filter
   * @param systemName string
   */
  const removeSelectedFilter = (systemName: string) => {
    // if systemName matches with selected filter
    if (systemName === selectedFilter.systemName) {
      setSelectedFilter(initialFilter);
    }
    // remove the entry from filterKeys array
    const filtersAfterRemoval = _.reject(filterKeys, {
      systemName: systemName,
    });
    setFilterKeys([...filtersAfterRemoval]);
  };
  /**STEP-3 methods end*/

  /**STEP-4 methods starts*/
  /**
   * @description Method to update crop calendar
   * @param data CropCalendarTypes
   */
  const updateCropCalendar = (data: CropCalendarTypes) => {
    if (selectedCropCalendar?.calendarName) {
      /**updating existing record */
      updateRecord(cropCalender, selectedCropCalendar, data, "calendarName");
      setSelectedCropCalendar(initialCropCalendar);
    } else {
      /**creating new record */
      setCropCalender([...cropCalender, data]);
    }
  };

  /**
   * @description Method to remove selected crop calendar
   * @param systemName string
   */
  const removeSelectedCropCalendar = (systemName: string) => {
    // if systemName matches with selected crop calendar
    if (systemName === selectedCropCalendar.calendarName) {
      setSelectedCropCalendar(initialCropCalendar);
    }
    // remove the entry from cropCalender array
    const updatedCropCalendar = _.reject(cropCalender, {
      calendarName: systemName,
    });
    setCropCalender([...updatedCropCalendar]);
  };
  /**STEP-4 methods end*/

  /**STEP-5 methods starts*/
  /**
   * @description Method to update crop stage calendar
   * @param data CropGrowthStageKeys
   */
  const updateCropStageCalendar = (data: CropStageCalendarTypes) => {
    if (selectedCropCalendarStage?.calendarName) {
      /**updating existing record */
      updateRecord(
        cropCalendarStages,
        selectedCropCalendarStage,
        data,
        "calendarName",
        "stageSystemName"
      );
      setSelectedCropCalendarStage(initialCropStageCalendar);
    } else {
      /**creating new record */
      setCropCalendarStages([...cropCalendarStages, data]);
    }
  };

  /**
   * @description Method to remove selected crop stage calendar
   * @param systemName string
   */
  const removeSelectedStageCropCalendar = (systemName: string) => {
    // resetting selected record to initial state, If any
    if (
      systemName.split(",")[0] === selectedCropCalendarStage.calendarName &&
      systemName.split(",")[1] === selectedCropCalendarStage.stageSystemName
    ) {
      setSelectedCropCalendarStage(initialCropStageCalendar);
    }
    // remove the entry from cropStageCalender array
    const updatedCropStageCalendar = _.reject(cropCalendarStages, {
      calendarName: systemName.split(",")[0],
      stageSystemName: systemName.split(",")[1],
    });
    setCropCalendarStages([...updatedCropStageCalendar]);
  };
  /**STEP-5 methods end*/
  const getUniqueKey = () => {
    setUniqueKey(getRandomizeValue());
  };

  const updateRecord = (
    mainList: any,
    prevRecord: any,
    newRecord: any,
    matchKey1: string,
    matchKey2?: string
  ) => {
    mainList.forEach((eachItem: any, index: number) => {
      if (!matchKey2) {
        if (eachItem[matchKey1] === prevRecord[matchKey1]) {
          mainList.splice(index, 1, newRecord);
        }
      } else {
        if (
          eachItem[matchKey1] === prevRecord[matchKey1] &&
          eachItem[matchKey2] === prevRecord[matchKey2]
        ) {
          mainList.splice(index, 1, newRecord);
        }
      }
    });
  };

  const handleEnrollmentOptionSubmit = (
    data: EnrollmentOptionPayload,
    type: string
  ) => {
    const languageCode = storeData.languages.filter(
      (language: LanguageRecordType) =>
        language.languageId === generalDetails.languageId
    )[0].languageCode;

    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.crop_enrollments_options}/${type
      .trim()
      .toUpperCase()}`;
    apiData.payLoad = data;
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": languageCode,
    };
    return postPracticeDuration(apiData, successCallBack, errorCallback, type);
  };

  // const checkPracticeDurationEnable = async()=>{

  // }

  const renderCropStages = (nextStep: number) => {
    const commonProps = {
      onNext: (nextStep: number) => gotoNextStage(nextStep),
      onPrevious: (nextStep: number) => gotoNextStage(nextStep),
      updateUniqueKey: getUniqueKey,
      uniqueKey: uniqueKey,
      isEdit: props.isEdit,
      isPrimaryCrop: isPrimaryCrop,
      actionType: props.actionType,
      isSystemNameEditable: isSystemNameEditable,
      canAddNewRecord: canAddNewRecord,
      canUpdateExistingRecord: canUpdateExistingRecord,
    };

    switch (nextStep) {
      case 1:
        return (
          <GeneralDetailsForm
            {...commonProps}
            language={generalDetails?.language || undefined}
            imageUrl={generalDetails?.imageUrl || undefined}
            cropTitle={generalDetails?.cropName || undefined}
            systemName={generalDetails?.systemName || undefined}
            selectedStates={generalDetails?.selectedStates || null}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            getFileURL={(file: any, data: S3ImageUploadType) =>
              getFileURL(file, data)
            }
            languageId={generalDetails?.languageId || undefined}
            languageCode={generalDetails?.languageCode || undefined}
            stateList={[...stateList]}
            key={getRandomizeValue()}
            baseLanguage={recordBaseLanguage}
          />
        );
      case 2:
        return (
          <CropGrowthStagesForm
            {...commonProps}
            ListOfStages={cropGrowthStages}
            selectedStage={selectedCropGrowthStage}
            onSave={(data: CropGrowthStageKeys) => {
              updateCropGrowthStages(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedCropGrowthStage(systemName);
            }}
            onSelect={(data: CropGrowthStageKeys) => {
              setSelectedCropGrowthStage({ ...data });
            }}
            cropSystemName={generalDetails?.systemName}
            updateListOrder={(newList: Array<CropGrowthStageKeys>) =>
              setCropGrowthStages(newList)
            }
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList([...latestImgsList, imgURL])
            }
          />
        );
      case 3:
        return (
          <FilterKeyForm
            {...commonProps}
            listOfFilters={filterKeys}
            selectedFilter={selectedFilter}
            onSave={(data: FilterKeys) => {
              updateFiltersList(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedFilter(systemName);
            }}
            onSelect={(data: FilterKeys) => {
              setSelectedFilter({ ...data });
            }}
            updateListOrder={(newList: Array<FilterKeys>) =>
              setFilterKeys(newList)
            }
          />
        );
      case 4:
        return (
          <CropCalenderForm
            {...commonProps}
            farmingPracticeList={farmingPracticeList}
            cropDurationList={cropDurationList}
            ListOfCropCalendar={[...cropCalender]}
            selectedCalendar={selectedCropCalendar}
            onSelect={(data: CropCalendarTypes) => {
              setSelectedCropCalendar(data);
            }}
            onSave={(data: CropCalendarTypes) => {
              updateCropCalendar(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedCropCalendar(systemName);
            }}
            updateListOrder={(newList: Array<CropCalendarTypes>) =>
              setCropCalender(newList)
            }
            onEnrollmentOptionSubmit={handleEnrollmentOptionSubmit}
          />
        );
      case 5:
        return (
          <CropStageCalenderForm
            {...commonProps}
            cropStageList={[...cropCalendarStages]}
            selectedCropCalendarStage={selectedCropCalendarStage}
            onSelect={(data: CropStageCalendarTypes) => {
              setSelectedCropCalendarStage(data);
            }}
            onSave={(data: CropStageCalendarTypes) => {
              updateCropStageCalendar(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedStageCropCalendar(systemName);
            }}
            corpGrowthStagesList={cropGrowthStages}
            corpCalendarList={cropCalender}
            updateListOrder={(newList: Array<CropStageCalendarTypes>) =>
              setCropCalendarStages(newList)
            }
          />
        );
    }
  };

  const gotoNextStage = (nextStep: number, values?: any) => {
    if (nextStep !== STEPS.STEP6) {
      setActiveState(nextStep);
      renderCropStages(nextStep);
    } else {
      saveUpdateCrop();
    }
    handleStepData(nextStep - 1, values);
  };

  const handleStepData = (step: number, values?: any) => {
    switch (step) {
      case 1:
      case 0:
        if (values) {
          setGeneralDetails((prevState: any) => ({
            ...prevState,
            cropName: values.cropName,
            cropStates: values.cropStates,
            imageFile: values.imageFile,
            imageUrl: values.imageUrl,
            language: values.language,
            languageCode: values.languageCode,
            languageId: values.languageId,
            selectedStates: values.selectedStates,
            systemName: values.systemName,
          }));
        }
        break;
      case 2:
        logger.info("Step-2 data:", cropGrowthStages);
        break;
      case 3:
        logger.info("Step-3 data:", filterKeys);
        break;
      case 4:
        logger.info("Step-4 data:", cropCalender);
        break;
      case 5:
        // logger.info('Step-5 data:', cropCalendarStages);
        break;
      default:
        return;
    }
  };

  const getCropPayload = () => {
    let attributes: any = {};
    let payload: any = {};
    /** To level payload before step-1 */
    attributes.systemName = generalDetails.systemName;
    attributes.languageId = generalDetails.languageId;
    attributes.languageName = generalDetails.language;
    attributes.languageCode = generalDetails.languageCode;
    /**only edit related payload */
    if (props.isEdit && props.actionType !== ActionType.ADD_TRANSLATION) {
      attributes.id = generalDetails.id;
      attributes.smartGrowerCropId = smartGrowerCropId;
      payload.cropId = generalDetails.cropId;
    } else if (props.actionType === ActionType.ADD_TRANSLATION) {
      attributes.id = null;
      attributes.smartGrowerCropId = null;
      payload.cropId = null;
    }
    /** STEP-1 payload */
    payload.systemName = generalDetails.systemName;
    payload.cropName = generalDetails.cropName;
    payload.imageUrl = generalDetails.imageUrl;
    payload.languageId = generalDetails.languageId;
    payload.languageName = generalDetails.language;
    /**preparing state object payload */
    let selectedState: any = [];
    if (generalDetails.cropStates) {
      generalDetails.cropStates.map((state: StateListType) => {
        if (state.name !== SELECT_ALL) {
          selectedState.push({
            id: state.stateId,
            name: state.name,
            shortCode: state.stateShortCode,
          });
        }
      });
    }
    payload.cropStates = selectedState;
    /** STEP-2 payload */
    if (!props.isEdit) {
      let ste2Data = [...cropGrowthStages];
      ste2Data.forEach((element: any, index: number) => {
        element.id = null;
        element.displayOrder = index + 1;
      });
      payload.cropStages = ste2Data;
    } else {
      let cropGrowthStagesData: any = [...cropGrowthStages];
      cropGrowthStagesData.map((element: any, index: number) => {
        element.displayOrder = index + 1;
      });
      payload.cropStages = cropGrowthStagesData;
    }

    /** STEP-3 payload */
    if (!props.isEdit) {
      let ste3Data = [...filterKeys];
      ste3Data.forEach((element: any, index: number) => {
        element.id = null;
        element.displayOrder = index + 1;
      });
      payload.filterKeys = ste3Data;
    } else {
      let filterData: any = [...filterKeys];
      filterData.map((element: any, index: number) => {
        element.displayOrder = index + 1;
      });
      payload.filterKeys = filterData;
    }
    /** STEP-4 and STEP-5 payload */
    let cropCalendarsData = [...cropCalender];
    let cropCalendarStagesData: Array<CropStageCalendarTypes> = [
      ...cropCalendarStages,
    ];
    /**looping the step-5 record before merging it with step-4 */
    cropCalendarStagesData.forEach((step5Data: any, index: number) => {
      step5Data.displayOrder = index + 1;
    });
    cropCalendarsData.forEach((step4Data: any) => {
      step4Data.cropStagesCalendar = [];
      cropCalendarStagesData.forEach((step5Data: any) => {
        if (step4Data.calendarName === step5Data.calendarName) {
          /** adding ID to step-4 and step-5 payload */
          if (!props.isEdit) {
            step4Data.calendarId = null;
            step5Data.id = null;
          }
          /** deleting calendarName from step5Data internal object
           *  as per BE payload requirement
           */
          let cropStageData = { ...step5Data };
          delete cropStageData.calendarName;
          step4Data.cropStagesCalendar.push(cropStageData);
        }
      });
    });
    payload.cropCalendars = cropCalendarsData;
    attributes.payload = payload;
    return attributes;
  };
  const classes = useStyles();
  const successCallBack = (response: any, type: string, imgType?: string) => {
    if (type === "save_crop" || type === "update_crop") {
      handleSaveOrUpdateCropResponse(response);
    } else if (type === "s3_file_url") {
      handleS3FileUrlResponse(response, imgType);
    } else if (type === "states") {
      handleStatesResponse(response);
    } else if (type === "remove_img") {
      handleRemoveImgResponse(response);
    } else if (type === "filter_list") {
      handleFilterListResponse(response);
    } else if (type === "Practice" || type === "Duration") {
      handlePostPracticeDuration(response, type);
    }
  };

  const handlePostPracticeDuration = (response: any, type: any) => {
    try {
      if (response.statusCode === 200 || response.statusCode === 201) {
        fetchEnrolmentList(type, false);
        TriggerToastMessage(
          `Successfully added new enrollment options`,
          "success"
        );
        return true;
      } else {
        console.error("Error message from API:", response.message);
        TriggerToastMessage(
          response.message || `Failed to add ${type.toLowerCase()} option`,
          "error"
        );
        return false;
      }
    } catch (error) {
      console.error("Error submitting enrollment option:", error);
      TriggerToastMessage(
        `Failed to add ${type.toLowerCase()} option`,
        "error"
      );
      return false;
    }
  };

  const handleSaveOrUpdateCropResponse = (response: any) => {
    if (response.statusCode === 200 || response.statusCode === 201) {
      setLatestImgsList([]);
      props.onClose(true, props.isEdit);
    } else {
      TriggerToastMessage(
        response.message
          ? response.message
          : `Crop ${!props.isEdit ? "save" : `updation`} failed`,
        "success"
      );
    }
  };

  const handleS3FileUrlResponse = (response: any, imgType?: string) => {
    if (response.data && imgType === ModuleImageUpload.CROP) {
      const imgUrl: string = response.data.imageUrl;
      setGeneralDetails((prevState: any) => ({
        ...prevState,
        imageUrl: imgUrl,
      }));
      setLatestImgsList([...latestImgsList, imgUrl]);
    } else {
      errorCallback(response, "s3_file_url");
    }
  };

  const handleStatesResponse = (response: any) => {
    const selectedStateIDs: Array<number> =
      generalDetails?.selectedStates?.map((eachState: any) => eachState.id) ||
      [];
    const formattedData: Array<StateListType> = [...response.data];
    formattedData.forEach((state: any) => {
      state.name = state.stateName;
      state.checked = selectedStateIDs.includes(state.stateId);
    });
    const allStateOption: any = {
      name: SELECT_ALL,
      checked: selectedStateIDs.length === formattedData.length,
    };
    formattedData.unshift(allStateOption);
    setStateList([...formattedData]);
  };

  const handleRemoveImgResponse = (response: any) => {
    if (response.statusCode === 200) {
      TriggerToastMessage(
        response.message
          ? response.message
          : "Uploaded images removed successfully.",
        "success"
      );
    }
  };

  const handleFilterListResponse = (response: any) => {
    if (response.statusCode === 200) {
      const filterKeys: any[] = response.data;
      const filterKeyArray: FilterKeys[] = [];
      filterKeys.forEach((filterKeyObj) => {
        const filterValuesObj = filterKeyObj.filterValues;
        const filterValuesArray: FilterValues[] = filterValuesObj.map(
          (filterValuesObj: any) => ({
            valueSystemName: filterValuesObj.valueSystemName,
            displayLabel: filterValuesObj.displayLabel,
            displayOrder: filterValuesObj.displayOrder,
          })
        );
        const obj: FilterKeys = {
          systemName: filterKeyObj.systemName,
          filterName: filterKeyObj.filterName,
          filterValues: filterValuesArray,
        };
        filterKeyArray.push(obj);
      });
      setFilterKeys([...filterKeyArray]);
    }
  };
  const errorCallback = (_error: any, type: string) => {
    switch (type) {
      case "save_crop":
      case "update_crop": {
        /** Closing the popup, passing false to display failed creation */
        props.onClose(false);
        break;
      }
      default: {
        /**Generic alert to display API fail */
        TriggerToastMessage(
          "Unable to process your request!!! " + type,
          "error"
        );
      }
    }
  };
  const getFileUploadPayload = (file: any, data: S3ImageUploadType) => {
    let fileNameArr: any = file.name.split(".");
    let s3Data: any = {
      cropSystemName: data.name, //cropSystemName should be common for all images related to same crop
      type: data.type,
      fileNameWithExtension: `${data.name}.${
        fileNameArr[fileNameArr.length - 1]
      }`,
    };
    return s3Data;
  };
  /**
   * @description performing saving and updation of Crop
   * @returns success or error call back response object
   */
  const saveUpdateCrop = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.save_crop;
    apiData.showLoader = true;
    apiData.payLoad = getCropPayload();
    if (!props.isEdit || props.actionType === ActionType.ADD_TRANSLATION) {
      return SaveCropData(apiData, successCallBack, errorCallback);
    } else {
      return UpdateCropData(apiData, successCallBack, errorCallback);
    }
  };
  /**
   * @description performing saving and updation of Crop
   * @returns success or error call back response object
   */
  const getFileURL = (file: any, data: S3ImageUploadType): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.fileupload_S3bucket;
    apiData.showLoader = true;
    if (typeof file !== "string") {
      let payload = getFileUploadPayload(file, data);
      let formData: any = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify(payload));
      apiData.payLoad = formData;
      return GetFileS3BucketURL(
        apiData,
        successCallBack,
        errorCallback,
        data.type
      );
    }
  };

  /**
   * fetching state list
   */
  const getAllStateList = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.country_wise_states;
    apiData.showLoader = true;
    // apiData.customHeaders = {
    //   'language-code': 'mr'
    // }
    return GetStates(apiData, successCallBack, errorCallback);
  };
  /**processing get by id data for crop */
  const processCropStageCalendarSystemInfo = (response: any) => {
    let data: any = response.payload;

    let isSysNameEditable = false;
    let checkIfNewRecordToAdd = false;
    let checkIfRecordCanUpdate = true;

    setSmartGrowerCropId(response.smartGrowerCropId);
    let cropCalendarStagesArray: string | any[] = [];
    data.cropCalendars.map((cropCalendarObj: any) => {
      if (
        cropCalendarObj.cropStagesCalendar &&
        cropCalendarObj.cropStagesCalendar.length > 0
      ) {
        [...cropCalendarObj.cropStagesCalendar].forEach(
          (cropCalendarStageObj) => {
            cropCalendarStageObj["calendarName"] =
              cropCalendarObj["calendarName"];
          }
        );
        cropCalendarStagesArray = cropCalendarStagesArray.concat(
          cropCalendarObj.cropStagesCalendar
        );
      }
    });
    /**need to sort the step-5 data as per the display order */
    _.sortBy(cropCalendarStagesArray, ["displayOrder", "asc"]);
    let selectedLanguage: any[];
    selectedLanguage = storeData.languages.filter(
      (language: LanguageRecordType) => language.languageId === data.languageId
    );
    /**If adding product from Add Translation Button */
    if (
      props.actionType === ActionType.ADD_TRANSLATION &&
      selectedLanguage[0].languageCode === storeData.languageCode
    ) {
      selectedLanguage = [];
    }
    let GeneralDetails: any = {
      systemName: data.systemName,
      cropName: data.cropName,
      imageUrl: data.imageUrl,
      languageId: data.languageId,
      // selectedStates: props.actionType !== ActionType.ADD_TRANSLATION && data.cropStates.length ? data.cropStates : null,
      //selectedStates: data.cropStates,
      cropId: data.cropId,
      language: selectedLanguage?.[0]?.languageName,
      languageCode: selectedLanguage?.[0]?.languageCode,
      id: response.id,
    };
    let selectedStateObj: any = [...data.cropStates];
    selectedStateObj.map((eachState: any) => {
      eachState.stateId = eachState.id;
      eachState.stateShortCode = eachState.shortCode;
    });
    GeneralDetails.selectedStates = selectedStateObj;

    const cropStagesSort = _.sortBy(data.cropStages, ["displayOrder", "asc"]);
    const filterKeysSort = _.sortBy(data.filterKeys, ["displayOrder", "asc"]);

    const CropInfoObj = {
      generalDetails: GeneralDetails,
      cropStages: [...cropStagesSort],
      filterKeys: [...filterKeysSort],
      cropCalendar: [...data.cropCalendars],
      cropCalendarStages: [...cropCalendarStagesArray],
    };
    /**step-1 data */
    setGeneralDetails({ ...GeneralDetails });
    setBaseLanguageOfRecord(GeneralDetails.language);
    /**API call: calling get state list api as per language code */
    // if (props.actionType !== ActionType.ADD_TRANSLATION) {
    //   getAllStateList(selectedLanguage[0].languageCode, data.cropStates);
    // }
    /**step-2 data */
    setCropGrowthStages(CropInfoObj.cropStages);
    /**step-3 data */
    setFilterKeys(CropInfoObj.filterKeys);
    /**step-4 data */
    setCropCalender(CropInfoObj.cropCalendar);
    /**step-5 data */
    setCropCalendarStages(CropInfoObj.cropCalendarStages);
    /** Here, we need to check whether,
     * the primary language code is same as current crop language or not
     */
    const defaultLangRecord = !(
      props.actionType === ActionType.ADD_TRANSLATION && selectedLanguage.length
    )
      ? selectedLanguage[0] &&
        selectedLanguage[0].languageCode === storeData.languageCode
      : false;
    if (defaultLangRecord) {
      setIsPrimaryCrop(true);
    } else {
      setIsPrimaryCrop(false);
    }
    if (defaultLangRecord) {
      isSysNameEditable = checkIfSystemNameIsEditable(
        response,
        storeData.languageCode,
        "smartGrowerCropId"
      );
      checkIfNewRecordToAdd = canAddRecord(
        response,
        storeData.languageCode,
        "smartGrowerCropId"
      );
      checkIfRecordCanUpdate = canUpdateRecord(response, "smartGrowerCropId");
    }
    canEditSystemName(isSysNameEditable);
    toggleAddNewRecord(checkIfNewRecordToAdd);
    toggleUpdateExistingRecord(checkIfRecordCanUpdate);
  };

  const compareWithPrimaryLanguage = (response: any) => {
    const data = response.payload;

    const combinedCropGrowthStages = combineUnionArray(
      cropGrowthStages,
      data.cropStages,
      (a, b) => a.systemName === b.systemName
    ).sort((a, b) => (a?.displayOrder ?? 0) - (b?.displayOrder ?? 0));

    const combinedFilterKeys = combineUnionArray(
      filterKeys,
      data.filterKeys,
      (a, b) => a.systemName === b.systemName
    ).sort((a, b) => (a?.displayOrder ?? 0) - (b?.displayOrder ?? 0));
    combinedFilterKeys.forEach((filter) => {
      const primaryFilterValues =
        (data.filterKeys as FilterKeys[]).find(
          (primaryFilter) => primaryFilter.systemName === filter.systemName
        )?.filterValues ?? [];
      filter.filterValues = combineUnionArray(
        filter.filterValues,
        primaryFilterValues,
        (a, b) => a.valueSystemName === b.valueSystemName
      );
    });

    const combinedCropCalendar = combineUnionArray(
      cropCalender,
      data.cropCalendars,
      (a, b) => a.calendarName == b.calendarName
    );
    const combinedCropCalendarStages: CropStageCalendarTypes[] = [];
    combinedCropCalendar.forEach((calendar) => {
      const primaryCropCalendarStages =
        (data.cropCalendars as CropCalendarTypes[]).find(
          (primaryCalendar) =>
            primaryCalendar.calendarName === calendar.calendarName
        )?.cropStagesCalendar ?? [];
      const tempCombined = combineUnionArray(
        calendar?.cropStagesCalendar ?? [],
        primaryCropCalendarStages,
        (a, b) => a.stageSystemName === b.stageSystemName
      ).map((temp) => {
        return { ...temp, calendarName: calendar.calendarName ?? "" };
      });

      combinedCropCalendarStages.push(...tempCombined);
    });

    setCropGrowthStages(combinedCropGrowthStages);
    setFilterKeys(combinedFilterKeys);
    setCropCalender(combinedCropCalendar);
    setCropCalendarStages(combinedCropCalendarStages);
  };
  /**
   * get crop detail by Crop ID and language
   */
  const fetchCropDetails = (syncWithPrimary = false) => {
    const cropLanguageObject: LanguageRecordType[] = storeData.languages.filter(
      (lngRcd: LanguageRecordType) => {
        return lngRcd.languageId === props.lngId;
      }
    );
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    if (!props.cropViewID) {
      /** for published draft crop */
      apiData.endPoint = api_endpoints.crop_details.replace(
        "__SYSTEM__NAME__",
        props.sysNm
      );
    } else {
      /**for newly created or unpublished draft crop */
      apiData.endPoint = `crops/crop-drafts/${props.cropViewID}`;
    }
    if (cropLanguageObject.length > 0) {
      apiData.customHeaders = {
        "language-code": cropLanguageObject[0].languageCode,
      };
    }

    if (syncWithPrimary) {
      apiData.customHeaders = {
        "language-code": storeData.languageCode,
      };
    }

    GetCropDetails(apiData)
      .then((response: any) => {
        if (response.statusCode === 200) {
          if (syncWithPrimary) {
            compareWithPrimaryLanguage(response.data);
          } else {
            processCropStageCalendarSystemInfo(response.data);
          }
          return;
        }
        throw new Error(response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCropFilters = () => {
    const apiData = {} as IRequestBody;
    apiData.endPoint = api_endpoints.crops_filters_list;
    getFiltersForCrop(apiData, successCallBack, errorCallback);
  };
  /** Delete S3 bucket uploaded imgs,
   * if not saved with the crop data
   */
  const handleClosePopup = () => {
    props.onClose();
    /** API call to remove images */
    if (latestImgsList?.length) {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      apiData.endPoint = api_endpoints.remove_img_list;
      apiData.showLoader = true;
      apiData.payLoad = { fileUrlsList: latestImgsList };
      return RemoveImageList(apiData, successCallBack, errorCallback);
    }
  };
  /** getting primary language of the country */
  const setPrimaryLanguage = () => {
    let selectedLanguage: any = storeData.languages.filter(
      (language: LanguageRecordType) =>
        language.languageCode === storeData.languageCode
    );
    /**get state list API call as per primary language of the country*/
    getAllStateList();
    setGeneralDetails((prevState: any) => ({
      ...prevState,
      language: selectedLanguage[0].languageName,
      languageId: selectedLanguage[0].languageId,
      languageCode: selectedLanguage[0].languageCode,
    }));
  };
  /**
   * Crop Calendar enrollment Practice and Duration
   */
  const fetchEnrolmentList = (type: string = "", isParent: boolean = false) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.crop_enrollments_options;
    apiData.showLoader = true;
    if (!isParent) {
      apiData.customHeaders = {
        "language-code": generalDetails?.languageCode,
      };
    }
    apiData.payLoad = {
      type,
    };
    api_service
      .get(apiData)
      ?.then((response) => {
        let res = response.data;
        if (res && res.statusCode == 200) {
          let dataPractice = res.data.map(
            (item: ResponseDataCropEnrollments) => {
              return {
                label: item.enrollmentOptionTranslation,
                code: item.systemName,
              };
            }
          );
          switch (type) {
            case "Practice":
              isParent && setFirstFarmingPracticeList(dataPractice);
              !isParent && setFarmingPracticeList(dataPractice);
              !isParent &&
                setCropCalender((prev: CropCalendarTypes[]) => {
                  return prev.map((item) => {
                    return {
                      ...item,
                      practice: translateEnrollment(
                        item.practice,
                        "Practice",
                        dataPractice
                      ),
                    };
                  });
                });
              break;
            case "Duration":
              isParent && setFirstCropDurationList(dataPractice);
              !isParent && setCropDurationList(dataPractice);
              !isParent &&
                setCropCalender((prev: CropCalendarTypes[]) => {
                  return prev.map((item) => {
                    return {
                      ...item,
                      duration: translateEnrollment(
                        item.duration,
                        "Duration",
                        dataPractice
                      ),
                    };
                  });
                });
              break;
            default:
              break;
          }
        } else {
          TriggerToastMessage(res.message, "info");
        }
      })
      .catch(() => {
        TriggerToastMessage(
          "Unable to load " + type + ", Please try later",
          "error"
        );
      });
  };

  const translateEnrollment: any = (value = "", type = "", dataList = []) => {
    if (appState.auth.languageCode === generalDetails?.languageCode) {
      return value;
    }
    let initialPracticeAndDurationList = { label: value, code: "" };
    let enrollItemBase: PracticeAndDurationList =
      initialPracticeAndDurationList;
    let enrollItemTranslated: PracticeAndDurationList =
      initialPracticeAndDurationList;
    switch (type) {
      case "Practice": {
        let dataTranslatedPracticeList =
          farmingPracticeList.length > 0 ? farmingPracticeList : dataList;
        enrollItemBase =
          firstFarmingPracticeList.find(
            (item: PracticeAndDurationList) => item.label === value
          ) || initialPracticeAndDurationList;
        enrollItemTranslated =
          dataTranslatedPracticeList.find(
            (item: PracticeAndDurationList) => item.code === enrollItemBase.code
          ) || initialPracticeAndDurationList;
        break;
      }
      case "Duration": {
        let dataTranslatedDurationList =
          cropDurationList.length > 0 ? cropDurationList : dataList;
        enrollItemBase =
          firstCropDurationList.find(
            (item: PracticeAndDurationList) => item.label === value
          ) || initialPracticeAndDurationList;
        enrollItemTranslated =
          dataTranslatedDurationList.find(
            (item: PracticeAndDurationList) => item.code === enrollItemBase.code
          ) || initialPracticeAndDurationList;
        break;
      }
      default:
        break;
    }
    return enrollItemTranslated.label;
  };

  useEffect(() => {
    if (props.isEdit) {
      fetchCropDetails();
    } else {
      setPrimaryLanguage();
      fetchCropFilters();
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (
      props.isEdit &&
      generalDetails?.languageCode !== undefined &&
      generalDetails.languageCode !== storeData.languageCode
    ) {
      fetchCropDetails(true);
    }
  }, [props.isEdit, generalDetails?.languageCode, storeData.languageCode]);

  useEffect(() => {
    if (props.isEdit) {
      getAllStateList();
    }
  }, [generalDetails]);

  useEffect(() => {
    if (generalDetails?.languageCode) {
      fetchEnrolmentList("Practice");
      fetchEnrolmentList("Duration");
    }
  }, [generalDetails?.languageCode]);

  useEffect(() => {
    fetchEnrolmentList("Practice", true);
    fetchEnrolmentList("Duration", true);
  }, []);

  return (
    <div>
      <Modal
        open={true}
        disableEscapeKeyDown={true}
        onClose={() => {}}
        className={classes.modalStylesHelper}
      >
        <Paper className={classes.formPaper}>
          <Paper elevation={3} square>
            <ModalHeader
              header={
                props.actionType === ActionType.ADD_TRANSLATION &&
                !isPrimaryCrop
                  ? `Add Translation`
                  : (props.actionType === ActionType.UPDATE && isPrimaryCrop) ||
                    (props.actionType === ActionType.ADD_TRANSLATION &&
                      isPrimaryCrop)
                  ? `Update Primary Crop`
                  : props.actionType === ActionType.UPDATE && !isPrimaryCrop
                  ? `Update Crop`
                  : `New Crop`
              }
              showDelete={false}
              showEdit={false}
              onClose={() => {
                if (confirm("You may lose your data.")) {
                  handleClosePopup();
                }
              }}
            ></ModalHeader>
            {/** stepper component */}
            <StepperComponent
              steps={StagesToCreateACrop}
              activeStep={activeState}
              stepChangehandler={(step: number) => {
                props.isEdit && activeState !== 1 && setActiveState(step);
              }}
            />
          </Paper>
          <Box>{renderCropStages(activeState)}</Box>
        </Paper>
      </Modal>
    </div>
  );
};
